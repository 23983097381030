import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image,
  FormGroup,
  Accordion
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar from "./Sidebar";
//import Sidebar2 from "./Sidebar2";
//import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal, MAX_QTY_LENGTH, MAX_AMOUNT_LENGTH} from "../config/Config";
import axios from "axios";

//import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import spinner_logo from '../assets/images/spinner_logo.png';
import InputGroup from 'react-bootstrap/InputGroup';

import { convertNumberToWords, formatTime } from "./utils";

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsCardText
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
//import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSellAll } from "./utils";

const TBL_CHECKBOX_WIDTH = 4;
const TBL_SNO_WIDTH = 4;
const TBL_DCDATE_WIDTH = 8;
const TBL_DCNO_WIDTH = 12;
const TBL_PRODUCT_NAME_WIDTH = 18;
const TBL_BAL_TO_TOT_WIDTH = 6;
const TBL_BILLQTY_WIDTH = 8;
const TBL_UNIT_PRICE_WIDTH = 8;
const TBL_PRODUCT_AMT_WIDTH = 8;
const TBL_CGST_AMT_WIDTH = 8;
const TBL_SGST_AMT_WIDTH = 8;
const TBL_IGST_AMT_WIDTH = 8;
const TBL_TOTAL_AMT_WIDTH = 8;

                          
const AddUpdateCustBillMfgT1 = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('BILL Saved Successfully');
  const [dateIdSelect, setDateIdSelect] = useState({});
  const [isSearched, setIsSearched] = useState(false);

  var expected_bill_number_ref = useRef(0);
  const total_items_ref = useRef(0);
  const sum_product_cost_ref = useRef(0.00);
  const sum_product_cgst_ref = useRef(0.00);
  const sum_product_sgst_ref = useRef(0.00);
  const sum_product_igst_ref = useRef(0.00);
  const sum_product_cess_ref = useRef(0.00);
  const sum_product_total_ref = useRef(0.00);


  const initialValuesAddl = {
    delivery_note_no: "",
    delivery_note_date: "",
    pay_terms: "45 Days",
    ref_no: "",
    ref_date: "",
    ref_others: "",
    buyer_order_no: "",
    buyer_order_date: "",
    dispatch_doc_no: "",
    dispatch_through: "",
    destination: "",
    bol_no: "",
    bol_date: "",
    vehicle_no: "",
    delivery_terms: "",
    issue_date: null,
    issue_time: "",
  };

  const [formValuesAddl, setFormValuesAddl] = useState(initialValuesAddl);
  const [formErrorsAddl, setFormErrorsAddl] = useState({});
  
  const [optionsStateBill, setOptionsStateBill] = useState([]);
  const [optionsStateShip, setOptionsStateShip] = useState([]);

  const [optionsDistrictBill, setOptionsDistrictBill] = useState([]);
  const [optionsDistrictShip, setOptionsDistrictShip] = useState([]);


  const [stateIdSelectBill, setStateIdSelectBill] = useState({});
  const [stateIdSelectShip, setStateIdSelectShip] = useState({});

  const [districtIdSelectBill, setDistrictIdSelectBill] = useState({});
  const [districtIdSelectShip, setDistrictIdSelectShip] = useState({});

  const [optionsSupply, setOptionsSupply] = useState([]);
  const [supplyIdSelect, setSupplyIdSelect] = useState({});

  const [bankAccountIdSelect, setBankAccountIdSelect] = useState({});
  const [optBankAccount, setOptBankAccount] = useState([]);

  const customerArrRef = useRef([]);
  const itemArrRef = useRef([]);
  const unitArrRef = useRef([]);
  const bankAccArrRef = useRef([]);
  const countryArrRef = useRef([]);
  const stateArrRef = useRef([]);
  const districtArrRef = useRef([]);
  const docNumberSettingRef = useRef([]);
  const billItemsRef = useRef([]);
  const defaultTextRef = useRef([]);

  const bill_info_ref = useRef(0.00);
  const [billAddress, setBillAddress] = useState({});

  
  /*
  let initialItems = [
    {id:1, product: {value:3, label:"M15"}, price: "4900", qty: 5, "product_cost": "24500", "gst": "4410", "total_cost":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
    {id:1, product: {value:6, label:"M25"}, price: "4800", qty: 7, "product_cost": "215001", "gst": "38701", "total_cost":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
  ]
  */
  let initialItems = [];

  var sub_total = 0.00;

  /*
  const optionsDate = [
    { value: 1, label: "12-12-2023" },
    { value: 2, label: "01-04-2023" },
    { value: 3, label: "05-09-2023" },
    { value: 4, label: "07-08-2023" },
    { value: 5, label: "23-05-2023" },
  ];

  let stateArr = [
    { value: 1, label: "Andhra Pradesh" },
    { value: 2, label: "Assam" },
    { value: 3, label: "Tamil Nadu" },
    { value: 4, label: "Telangana" },
  ];

  let bankAccountArr = [
    { value: 1, label: "City Union Bank" },
    { value: 2, label: "Indian Bank" },
    { value: 3, label: "State Bank" },
    { value: 4, label: "Axis Bank" },
  ];
  */
  const [optionsState, setOptionsState] = useState([]);
  //const [bankAccountIdSelect, setBankAccountIdSelect] = useState({});
  //const [optBankAccount, setOptBankAccount] = useState(bankAccountArr);

  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);

  const handleCustomerSelect = async(e) => {
    console.log("handleCustomerSelect called : ", e);

    setCustomerIdSelect(e);

    //setFormValues({ ...formValues, gst_number : e.gstin}); 
  };
  
  const handleBankAccountSelect = async (e) => {
    console.log("handleBankAccountSelect called...", e);
    setBankAccountIdSelect(e);
  };

  const calculateTaxAndTotal = (selectedStateGstCode) => {
    var itemsModNew =[];
    items.map((itemSelected) => {
    if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
      itemSelected.net_amt = 0.00;
      itemSelected.net_amt = itemSelected.qty * itemSelected.unit_price;

      const currentProductIndex = productMaster.findIndex((productInd) => productInd.value === itemSelected.item_id);

      /*
      var businessList = localStorage.getItem("business");
      var businessObj = JSON.parse(businessList);
      console.log("BUSINESS LIST OBJECT : ", businessObj);
      var selBusiness = localStorage.getItem("selected_business");
      var selBusinessObj = JSON.parse(selBusiness);
      console.log("SELECTED BUSINESS : ", selBusinessObj);
      var findBusiness = businessObj.find(element => element.value === parseInt(selBusinessObj.value));
      console.log("**************** findBUSINESS value ++++++++++++ : ", findBusiness);
      if (findBusiness !== undefined) {
        console.log("SELECTED BUSINESS INFO 10001 : ", findBusiness);
      }
      */
      //if (parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
      console.log("SELECTED BUSINESS GST STATE CODE : ", JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2));
      console.log("SELECTED STATE CODE WHILE DOING COMPARISON : ", selectedStateGstCode);
      if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectedStateGstCode) ) {
        itemSelected.cgst_per = productMaster[currentProductIndex].cgst_per;
        if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
          itemSelected.cgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cgst_per / 100);
        }
        else {
          itemSelected.cgst_amt = 0.00;
        }
        itemSelected.sgst_per = productMaster[currentProductIndex].sgst_per;
        if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
          itemSelected.sgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.sgst_per / 100);
        }
        else {
          itemSelected.sgst_amt = 0.00;
        }
        itemSelected.igst_amt = 0.00;

        console.log("CALCULTED CGST AMOUNT at intra state SELECTION: ", itemSelected.cgst_amt);
        console.log("CALCULTED SGST AMOUNT at intra state SELECTION: ", itemSelected.sgst_amt);
        console.log("CALCULTED IGST AMOUNT at intra state SELECTION: ", itemSelected.igst_amt);
      }
      else {
        itemSelected.igst_per = productMaster[currentProductIndex].igst_per;
        if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
          itemSelected.igst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.igst_per / 100);
          console.log("IGST CALCULATED WHEN QTY AND UNIT PRICE VALID ...", itemSelected.igst_amt);
        }
        else {
          console.log("IGST NOT CALCULATED SO 0.00 ...", itemSelected.igst_amt);
          itemSelected.igst_amt = 0.00;
        }
        itemSelected.cgst_amt = 0.00;
        itemSelected.sgst_amt = 0.00;

        console.log("CALCULTED CGST AMOUNT at inter state SELECTION : ", itemSelected.cgst_amt);
        console.log("CALCULTED SGST AMOUNT at inter state SELECTION : ", itemSelected.sgst_amt);
        console.log("CALCULTED IGST AMOUNT at inter state SELECTION: ", itemSelected.igst_amt);
      }
      //igst_amt: 0.00,
      itemSelected.cess_per = productMaster[currentProductIndex].cess_per;
      if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
        itemSelected.cess_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cess_per / 100);
      }
      else {
        itemSelected.cess_amt = 0.00;
      }
      //cess_amt: 0.00,
      //if ( parseInt(stateIdSelectShip.gst_code) === parseInt(currSelBusinessAddlRef.current.slice(0,2)) ) {
      //if (findBusiness && parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
        if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectedStateGstCode) ) {
        itemSelected.total_amt = itemSelected.net_amt + (itemSelected.cgst_amt + itemSelected.sgst_amt + itemSelected.igst_amt + itemSelected.cess_amt);
      }
      else {
        itemSelected.total_amt = itemSelected.net_amt + (itemSelected.igst_amt + itemSelected.cess_amt);
      }
      //itemSelected.cgst_amt = itemSelected.net_amt * (itemSelected.cgst_per / 100 );
      //itemSelected.total_amt = itemSelected[nameOfElement] * itemSelected.unit_price;
      console.log("NET AMOUNT OF SELECTED ROW : ", itemSelected.net_amt);
    }
    else {
      console.log("ANY ONE OF THE VALUE IS NULL OR UNDEFINED ...");
    }

    itemsModNew.push(itemSelected);
  });

  setItems(itemsModNew);
  }

  const handleStateSelect = async (e) => {
    console.log("handleStateSelectB called ...", e);
    setStateIdSelectBill(e);

    calculateTaxAndTotal(e.gst_code);

    sumBill(items, e);
  };

 




  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    customer_name: "",
    customer_disp_name: "",
	  bill_date: formatDate(new Date()), 
    due_date: formatDate(new Date()), 
    bill_number: "", 
    notes: "",
    tc: "",
    declaration: "",
    gst_number: "",
	  address1: "",
    address2: "",
    pincode: "", 
    state: 1,

    bill_details_id: 0,
  };

  const initialValuesOrder = {
    expected_bill_number: 0,
    order_date: formatDate(new Date()),
  };

  
  /*
  let productArr = [
    { value: 1, label: "TMT Rods" },
    { value: 2, label: "Amman Steels" },
   
  ];

  let itemsArr = [
    {
    dn_no : "CB001",
    items: "Iron Rod",
    quantity: "5"
    },
    {
      dn_no : "CB002",
      items: "Steel Table",
      quantity: "2"
      },
      
  ]
  */


  //const [formValuesOrder, setFormValuesOrder] = useState(initialValuesOrder);
   
  
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  //const [items, setItems] = useState(initialItems); // original
  const [items, setItems] = useState([]);
  const [productMaster, setProductMaster] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const [toRender, setToRender] = useState(false);

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [billDistrictIdSelect, setBillDistrictIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [customerSearchText, setCustomerSearchText] = useState("");

  const [searchedCustomer, setSearchedCustomer] = useState({});

  const [orderAddress, setOrderAddress] = useState({});
  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  const [productList, setProductList] = useState();
  const [editAction, setEditAction] = useState(false);

  //const [optDate, setOptDate] = useState(optionsDate);
  const [optionsProd, setOptionsProd] = useState([]);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  const [checkState, setCheckState] =  useState(true);
  //const [disableState, setDisableState] = useState(true);

  var validateErr = false;

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [prodList, setProdList] = useState(prodListLoad);

  const [gstBillStatus, setGstBillStatus] = useState(true);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [uomIdSelect, setUomIdSelect] = useState({});
  const [productCompositionList, setProductCompositionList] = useState([]);

  const [compositionUpdate, setCompositionUpdate] = useState(false);

  const [selectedCompositionId, setSelectedCompositionId] = useState(-1);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  //const [searchText, setSearchText] = useState('');

  const handleDistrictSelectBill = async (e) => {
    console.log("handleStateSelectBill called ...", e);
    setDistrictIdSelectBill(e);
  };

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);

    if (
      name === "phone" ||
      name === "contact_number" ||
      name === "shipContactNumber" ||
      name === "billContactNumber" ||
      name === "pincode" ||
      name === "shipPincode" ||
      name === "billPincode"
    ) {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } else {
      if (name === 'bill_no') {    
        var currentId = e.target.value.substring(e.target.value.lastIndexOf("/") + 1);
        var nameOfElement = e.target.value.substring(0, e.target.value.lastIndexOf("/"));
        console.log("Prefix NAME : ", nameOfElement);
        console.log("BILL SERIAL NUMBER : ", currentId);

        expected_bill_number_ref.current = parseInt(currentId);
        setFormValues({ ...formValues, bill_sno: parseInt(currentId), bill_no: e.target.value});
      }
      else {
        setFormValues({ ...formValues, [name]: e.target.value });
      }
      /*
      if ( name === 'contact_person' && addrCopyCheck) {
        setFormValues({ ...formValues, ["shipContactName"]: value });
      }
      */
    }
    var quantity_tmp = 0.00;
    var unit_price_tmp = 0.00;
    /*
    if ( !isNaN(parseFloat(formValues["quantity"])) ) {
      quantity_tmp = parseFloat(formValues["quantity"]);
    }
    if ( !isNaN(parseFloat(formValues["unit_price"])) ) {
      unit_price_tmp = parseFloat(formValues["unit_price"]);
    }
    */
    sub_total = quantity_tmp + unit_price_tmp;
    console.log("SUB TOTAL at handleValueChange : ", sub_total);
  };

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
  };

  const handleBillDistrictSelect = async (e) => {
    console.log("handle bill district : ", e);
    setBillDistrictIdSelect(e);
  };

  const onChangeDueDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, due_date: e.target.value });
  };


  const sumBill = (items_pass, selectStateIdInfo) => {
    console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items_pass.length);
    setToRender(false);
    sum_product_cost_ref.current = 0.00;
    sum_product_cgst_ref.current = 0.00;
    sum_product_sgst_ref.current = 0.00;
    sum_product_igst_ref.current = 0.00;
    sum_product_cess_ref.current = 0.00;
    sum_product_total_ref.current = 0.00;
    items_pass.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.net_amt);
    console.log("SELECTEd STATE ID VALUE at sumDelivery: ", selectStateIdInfo);

    if ( item.dc_item_check === true ) {

    sum_product_cost_ref.current = sum_product_cost_ref.current + parseFloat(item.net_amt);
    if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectStateIdInfo.gst_code) ) {
      console.log("SAME STATE MOVE SO CALCUALTE CGST AND SGST AMOUNT");
      sum_product_cgst_ref.current = sum_product_cgst_ref.current + item.cgst_amt;
      sum_product_sgst_ref.current = sum_product_sgst_ref.current + item.sgst_amt;
    }
    else {
      console.log("INTER STATE MOVE SO CALCUALTE ONLY IGST AMOUNT");
      sum_product_igst_ref.current = sum_product_igst_ref.current + item.igst_amt;
    }
    sum_product_cess_ref.current = sum_product_cess_ref.current + item.cess_amt;
    sum_product_total_ref.current = sum_product_total_ref.current + item.total_amt;
    total_items_ref.current = total_items_ref.current + 1;

  }
  });

  console.log("PRODUCT TOTAL FOR DELIVERY : ", sum_product_cost_ref.current);
  console.log("SELECTED BUSINESS GST CODE : ", JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2));
  console.log("SELECTED SATTE CODE AT SUM OF DELIVERY : ", selectStateIdInfo.gst_code);
  if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectStateIdInfo.gst_code) ) {
    console.log("(CGST + SGST) TOTAL FOR DELIVERY : ", (sum_product_cgst_ref.current + sum_product_sgst_ref.current));
  }
  else {
    console.log("(IGST) TOTAL FOR DELIVERY : ", sum_product_igst_ref.current);
  }
  console.log("FINAL TOTAL FOR DELIVERY : ", sum_product_total_ref.current);

  setToRender(true);
  }

  const handleChange = (e, data) => {
    //const min = 0;
    //const max = 10000;
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    console.log("Rate : ", itemSelected.rate);
    console.log("Unit price : ", itemSelected.unit_price);
    console.log("CGST : ", parseFloat(itemSelected.cgst));
    console.log("SGST : ", parseFloat(itemSelected.sgst));
    console.log("Total Amount : ", itemSelected.total_price);
    console.log("Area : ", itemSelected.area);
    console.log("Billable Quantity : ", itemSelected.billable_quantity);
  

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    /*
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }
    */

    //console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    //console.log("%%%%%%%%%%%%%% GST INFO ++++++++++++++ : ", productMaster[currentProductIndex]["gst"].cgst);

    /*
    itemSelected.product = {
      value: productMaster[currentProductIndex].product_id,
      label: productMaster[currentProductIndex].product_name,
      stock_id: productMaster[currentProductIndex].stock_id,
      cgst: productMaster[currentProductIndex]["gst"].cgst,
      sgst: productMaster[currentProductIndex]["gst"].sgst,
      igst: productMaster[currentProductIndex]["gst"].igst,
    };
    */

    //itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    //itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    //itemSelected.igst_perc = productMaster[currentProductIndex].igst;
    //itemSelected.addl_notes = productMaster[currentProductIndex].addl_notes;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "billable_quantity" || nameOfElement === "jw_unit_price") {
      console.log("Product Quantity item edited ...");
      /*
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      */
      //var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //var valueTmp = e.target.value.replace(/^-?\d+\.?\d*$/g, "");
      //var numericRegExp = new RegExp("^[0-9]+$");
      //const numericRegExp = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
      //const numericRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      //const numericRegExp = new RegExp(/^-?\d*[\.]?\d+$/);

      // const numericRegExp = /^\d*\.?\d*$/;//original
      ////^([0-9]+-)*([0-9]+)$///.replace(/(\..*)\./g, "$1");
      //console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      //if (numericRegExp.match(e.target.value)) {
      if (e.target.value.match(appGlobal.numericRegExp)) {
        console.log("PATTERN MATCHED ...: ", e.target.value);
        //if ( parseFloat(e.target.value.toString()) !== NaN ) {
          //console.log("INSIDE IF CONDITION ...");
          //itemSelected[nameOfElement] = parseFloat(e.target.value.toString());
          /*
          if ( e.target.value < 0.01 ) {
            console.log("INSIDE IF CONDITION ...");
            itemSelected[nameOfElement] = 0;
          }
          else {
            console.log("INSIDE ELSE CONDITION ...");
            itemSelected[nameOfElement] = e.target.value.toString();
          }
          */
        /*
        }
        else {
          console.log("INSIDE ELSE CONDITION ...");
          itemSelected[nameOfElement] = "";
        }
        */
        itemSelected[nameOfElement] = e.target.value;
        if (itemSelected.dc_item_check ) {
        if ( nameOfElement === 'billable_quantity' ) {
          itemSelected.net_amt = e.target.value * itemSelected.jw_unit_price;
          itemSelected.cgst_amt = (e.target.value * itemSelected.jw_unit_price) * (itemSelected.jw_cgst_per / 100);
          itemSelected.sgst_amt = (e.target.value * itemSelected.jw_unit_price) * (itemSelected.jw_sgst_per / 100);
          if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ) {
            itemSelected.total_amt = (e.target.value * itemSelected.jw_unit_price) +  (e.target.value * itemSelected.jw_unit_price) * (itemSelected.jw_cgst_per / 100) + (e.target.value * itemSelected.jw_unit_price) * (itemSelected.jw_sgst_per / 100);
          }
          else {
            itemSelected.total_amt = (e.target.value * itemSelected.jw_unit_price) +  (e.target.value * itemSelected.jw_unit_price) * (itemSelected.jw_igst_per / 100);
          }
        }
        else {
          itemSelected.net_amt = e.target.value * itemSelected.billable_quantity;
          itemSelected.cgst_amt = (e.target.value * itemSelected.billable_quantity) * (itemSelected.jw_cgst_per / 100);
          itemSelected.sgst_amt = (e.target.value * itemSelected.billable_quantity) * (itemSelected.jw_sgst_per / 100);
          if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ) {
            itemSelected.total_amt = (e.target.value * itemSelected.billable_quantity) +  (e.target.value * itemSelected.billable_quantity) * (itemSelected.jw_cgst_per / 100) + (e.target.value * itemSelected.billable_quantity) * (itemSelected.jw_sgst_per / 100);
          }
          else {
            itemSelected.total_amt = (e.target.value * itemSelected.billable_quantity) +  (e.target.value * itemSelected.billable_quantity) * (itemSelected.jw_igst_per / 100);
          }
        }
      }
      }
      else{
        console.log("PATTERN DOESN'T MATCH ...");
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    /*
    if(itemSelected.product_type === 'PRODUCT') {
    if ( (itemSelected.quantity && itemSelected.unit_price) && parseFloat(itemSelected.quantity) !== isNaN && parseFloat(itemSelected.unit_price) !== isNaN) {
      itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    }
    

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;

    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst;
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    console.log("CGST STATE 501 : ", itemSelected.cgst);
    console.log("SGST STATE 501 : ", itemSelected.sgst);
    console.log("IGST STATE 501 : ", itemSelected.igst);

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);
    console.log("Modified Additional Notes : ", itemSelected.addl_notes);
*/

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    sumBill(newitems, stateIdSelect);
  };
 
 /*
  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
  /*
  const getOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderById?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server getOrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server getOrderDetails order info : ", res.data.detail.res_data.order_info);
        console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        setOrderInfoDet(res.data.detail.res_data.order_info);
        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        formValues["order_date"] = formatDate(dd);
        console.log("Date of order_date : ", dd.getMonth());
        setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

       
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
 /*
  const getOrderItemsByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
        console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.order_item_list.length > 0) {
          //setProdList(res.data.detail.res_data.order_item_list);
          setItems(res.data.detail.res_data.order_item_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        setOrderItemFetched(true);
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
 /*
  const getDCListByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
        console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.dc_list.length > 0) {
          setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        setOrderDcFetched(true);
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    setOrderDcFetched(true);
  };
  */
  
  /*
  const getProductApiCall = async () => {
    console.log("getTradeType method called ...");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: ` ${aIVal}`
      }
      var res = await axios.get(
        client.domain + "/ims/getProductsTTSell",
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server getProducts : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProducts : ", res.data.detail.data);

        var newSellProdArr = [];
        res.data.detail.data.map((prodItem) => {
          var newSellProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "product_id": prodItem.id,
            "product_name": prodItem.name,
            "stock_id": 1,
            "uom": prodItem.uom_value,
            "unit_cost": prodItem.unit_price,
            "gst": { "cgst": prodItem["cgst"], "sgst": prodItem["sgst"], "igst": prodItem["igst"] }

          }
          newSellProdArr.push(newSellProd);
        })
        setProductMaster(newSellProdArr);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.data);

        }
      }
    }
  };
  */

  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  /*
  const getOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderById?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server getOrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server getOrderDetails order info : ", res.data.detail.res_data.order_info);
        console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        setOrderInfoDet(res.data.detail.res_data.order_info);
        setFormValues({ ...formValues, notes: res.data.detail.res_data.order_info.notes});
        setFormValuesOrder({ ...formValuesOrder, expected_inv_number: parseInt(res.data.detail.res_data.order_info.order_no), order_date: formatDate(dd)});
        
        setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

        / *
        if ( res.data.detail.res_data.order_list.length > 0 ) {
          setOrderList(res.data.detail.res_data.order_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        * /
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
/*
  const getOrderItemsByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
        console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.order_item_list.length > 0) {
          //setProdList(res.data.detail.res_data.order_item_list);
          setItems(res.data.detail.res_data.order_item_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        setOrderItemFetched(true);
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
      //setOrderItemFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
 /*
  const getDCListByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
        console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.dc_list.length > 0) {
          setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        setOrderDcFetched(true);
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
      //setOrderDcFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    setOrderDcFetched(true);
  };
  */

  const getInitValues = async (business_id) => {
    console.log("getInitValues called ...");

    var retState = false;
    var initLoadBill = {};
    var reqDCInitLoad = {
      bid: business_id
    };
    var aIVal = localStorage.getItem("_aT");
    var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
    console.log("payload (BUSINESS ID) :", business_id);
    console.log("HEADERS : ", headers);

    try {
      var res = await axios.post(client.domain + `/bms/getCustBillAddUpdPreLoadData?bid=${business_id}`, reqDCInitLoad, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.data);
        initLoadBill = res.data.detail.data;
        retState = true;
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setAlertTitle("FAILED!");
          setAlertBody("DC initial data loading failed...");
          setShow(true);
        }
      }
    }

    return [retState, initLoadBill]
  }

  const getBillItemsByBillId = async (bill_id_pass) => {
    var retState = false;
    var billInfoRet = {};
    var billItemsRet = [];
    var customerInfoRet = {};
    var billDetailsRet = {};
    console.log("getBillItemsByBillId called ...");

    var aIVal = localStorage.getItem("_aT");
    var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
    console.log("payload (BUSINESS ID) :", bill_id_pass);
    console.log("HEADERS : ", headers);

    try {
      var res = await axios.get(client.domain + `/bms/getBillItemsByBillId?bill_id=${bill_id_pass}`, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.data);
        billItemsRet = res.data.detail.data.bill_items;
        billInfoRet = res.data.detail.data.bill_info;
        customerInfoRet = res.data.detail.data.customer_info;
        billDetailsRet = res.data.detail.data.bill_details;
        retState = true;
        /*
        invItemsRef.current = res.data.detail.data.inv_items;
        setItems(invItemsRef.current);
        sumInvoice(invItemsRef.current);
        */
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setAlertTitle("FAILED!");
          setAlertBody("Bill initial data loading failed...");
          setShow(true);
        }
      }
    }

    return [retState, billItemsRet, billInfoRet, customerInfoRet, billDetailsRet];
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      //setToRender(true);
      //setStateIdSelect(optionsState[0]);
     
      //setBankAccountIdSelect(optBankAccount[0]);
      //console.log(formErrors);
      
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }
      
      if (state === null) {
        navigate("/listCustomerBills");
        
      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);

        var businessInfo = localStorage.getItem("selected_business");
        var businessObj = JSON.parse(businessInfo);
        
        (async () => {
          var [retState, initLoadBill] = await getInitValues(businessObj.value);
          if ( retState ) {
            console.log("FULL DC INIT LOAD VALUE : ", initLoadBill);
  
          customerArrRef.current = initLoadBill.customers;
          itemArrRef.current = initLoadBill.items;
          unitArrRef.current = initLoadBill.units;
          bankAccArrRef.current = initLoadBill.bank_accounts;
          countryArrRef.current = initLoadBill.countries;
          stateArrRef.current = initLoadBill.states;
          districtArrRef.current = initLoadBill.districts;
          docNumberSettingRef.current = initLoadBill.doc_number_settings;
  
          setOptCustomer(customerArrRef.current);
          setCustomerIdSelect(customerArrRef.current[0]);
  
          /*
          setOptionsStateShip(stateArrRef.current);
          setStateIdSelectShip(stateArrRef.current[0]);
  
          setOptionsDistrictShip(districtArrRef.current);
          setDistrictIdSelectShip(districtArrRef.current[0]);
          */
          setOptionsStateBill(stateArrRef.current);
          setStateIdSelectBill(stateArrRef.current[0]);
  
          setOptionsDistrictBill(districtArrRef.current);
          setDistrictIdSelectBill(districtArrRef.current[0]);
          
          setOptionsState(stateArrRef.current);
          setStateIdSelect(stateArrRef.current[0]);

          setOptBankAccount(bankAccArrRef.current);
          setBankAccountIdSelect(bankAccArrRef.current[0]);
  
          console.log("<<<<<<<<<<<<<<<< EXPECTED BILL NUMBER : ", docNumberSettingRef.current[0].sno_generated + 1);
          var suffix_tmp = "";
          if ( docNumberSettingRef.current[0].doc_suffix ) {
            suffix_tmp = docNumberSettingRef.current[0].doc_suffix;
          }
          setFormValues({ ...formValues, 
            gstin: customerArrRef.current[0].gstin,
            bill_no: docNumberSettingRef.current[0].doc_prefix + parseInt(docNumberSettingRef.current[0].sno_generated + 1) + suffix_tmp, 
            bill_sno: docNumberSettingRef.current[0].sno_generated + 1 });
            
          expected_bill_number_ref.current = docNumberSettingRef.current[0].sno_generated + 1;
          console.log("expected BILL number : ", expected_bill_number_ref.current);

          setProductMaster(itemArrRef.current);
          }

          if ( state.action === 'edit' || state.action === 'view' ) {
            var [retStateBillItem, billItemsRet, billInfoRet, customerInfoRet, billDetailsRet] = await getBillItemsByBillId(state.passed_element.id);
              if ( retStateBillItem ) {
                console.log("RETURNED BILL INFO at useEffect : ", billInfoRet);
                console.log("RETURNED BILL ITEM LIST at useEffect : ", billItemsRet);
                console.log("RETURNED CUSTOMER INFO at useEffect : ", customerInfoRet);
                console.log("RETURNED BILL DETAILS at useEffect : ", billDetailsRet);
    
                bill_info_ref.current = billInfoRet;
    
                var findCustomer = customerArrRef.current.find(element => element.value === parseInt(billInfoRet.customer_id));
                if ( findCustomer !== undefined ) {
                  setCustomerIdSelect(findCustomer);
                }
    
                var districtsObj = JSON.parse(localStorage.getItem("districts"));
                var findDistrictShip = districtsObj.find(element => element.value === parseInt(billInfoRet.district_id_ship));
                console.log("**************** findBUSINESS value ++++++++++++ : ", findDistrictShip);
                if (findDistrictShip !== undefined) {
                  setDistrictIdSelectShip(findDistrictShip);
                }
    
                var findDistrictBill = districtsObj.find(element => element.value === parseInt(billInfoRet.district_id_bill));
                console.log("**************** findBUSINESS value ++++++++++++ : ", findDistrictBill);
                if (findDistrictBill !== undefined) {
                  setDistrictIdSelectBill(findDistrictBill);
                }
    
                var statesObj = JSON.parse(localStorage.getItem("states"));
                var findStateShip = statesObj.find(element => element.value === parseInt(billInfoRet.state_id_ship));
                console.log("**************** findBUSINESS value ++++++++++++ : ", findStateShip);
                if (findStateShip !== undefined) {
                  setStateIdSelectShip(findStateShip);
                }
    
                var findStateBill = statesObj.find(element => element.value === parseInt(billInfoRet.state_id_bill));
                console.log("**************** findBUSINESS value ++++++++++++ : ", findStateBill);
                if (findStateBill !== undefined) {
                  setStateIdSelectBill(findStateBill);
                }
    
                expected_bill_number_ref.current = billInfoRet.bill_sno;
                setFormValues({ ...formValues,
                  id: billInfoRet.id,
                  bid : billInfoRet.bid,
                  brid : billInfoRet.brid,
                  gst_number: customerInfoRet.gstin,
                  bill_no : billInfoRet.bill_no,
                  bill_sno : billInfoRet.bill_sno,
                  bill_date : formatDate(new Date(billInfoRet.bill_date)),
                  due_date : formatDate(new Date(billInfoRet.due_date)),
                  customer_id : billInfoRet.customer_id,
                  shipto_id : billInfoRet.shipto_id,
                  address1_ship : billInfoRet.address_line1_ship,
                  address2_ship : billInfoRet.address_line2_ship,
                  state_ship : billInfoRet.state_id_ship,
                  district_ship : billInfoRet.district_id_ship,
                  country_id_ship: billInfoRet.country_id_ship,
                  pincode_ship: billInfoRet.pincode_ship,
                  billto_id : billInfoRet.billto_id,
                  address1_bill : billInfoRet.address_line1_bill,
                  address2_bill : billInfoRet.address_line2_bill,
                  state_bill : billInfoRet.state_id_bill,
                  district_bill : billInfoRet.district_id_bill,
                  country_id_bill: billInfoRet.country_id_bill,
                  pincode_bill: billInfoRet.pincode_bill, 
                  pos_state_id : billInfoRet.pos_state_id,
                  sub_total_amt : billInfoRet.sub_total_amt,
                  cgst_amt : billInfoRet.cgst_amt,
                  sgst_amt : billInfoRet.sgst_amt,
                  igst_amt : billInfoRet.igst_amt,
                  cess_amt : billInfoRet.cess_amt,
                  total_amt : billInfoRet.total_amt,
                  roundoff_amt : billInfoRet.roundoff_amt,
                  bill_total_amt : billInfoRet.bill_total_amt,
                  reference : billInfoRet.reference,
                  bank_acc_id : billInfoRet.bank_acc_id,
                  bank_acc_details : billInfoRet.bank_acc_details,
                  notes : billInfoRet.notes,
                  tc : billInfoRet.tc, 
                  declaration : billInfoRet.declaration,
    
                  /*
                  delivery_no: formValuesAddl.delivery_no,
                  delivery_date: formValuesAddl.delivery_date,
                  mode_payment: formValuesAddl.mode_payment,
                  ref_no: formValuesAddl.ref_no,
                  ref_date: formValuesAddl.ref_date,
                  addl_reference: formValuesAddl.reference,
                  other_reference: formValuesAddl.other_reference,
                  buyer_no: formValuesAddl.buyer_no,
                  buyer_date: formValuesAddl.buyer_date,
                  dispatch_no: formValuesAddl.dispatch_no,
                  dispatch_through: formValuesAddl.dispatch_through,
                  destination: formValuesAddl.destination,
                  rr_no: formValuesAddl.rr_no,
                  rr_date: formValuesAddl.rr_date,
                  vehicle_no: formValuesAddl.vehicle_no,
                  terms_delivery: formValuesAddl.terms_delivery,
                  */
                 bill_details_id: billDetailsRet.id
                }
                );
    
                var findBank = bankAccArrRef.current.find(element => element.value === billInfoRet.bank_acc_id);
                if ( findBank !== undefined ) {
                  setBankAccountIdSelect(findBank);
                } 
    
                billItemsRef.current = billItemsRet;
                var billItemsRetTmp = [];
                billItemsRet.forEach(function(billItem){
                  var billItemNew = billItem;
                  billItemNew.dc_item_check = true;
                  billItemsRetTmp.push(billItemNew);                
                })
                console.log("MODIFIED DCs FOR LABOUR BILL : ", billItemsRetTmp);       
                setItems(billItemsRetTmp);
                var findState = JSON.parse(localStorage.getItem("states")).find(element => element.value === parseInt(billInfoRet.state_id_ship));
                console.log("**************** findBUSINESS value ++++++++++++ : ", findState);
                if (findState !== undefined) {
                  sumBill(billItemsRetTmp, findState);
                }
    
                setFormValuesAddl({ ...formValuesAddl,
                  //delivery_note_no: billDetailsRet.delivery_note_no,
                  //delivery_note_date: formatDate(new Date(billDetailsRet.delivery_note_date)),
                  pay_terms: billDetailsRet.pay_terms,
                  ref_no: billDetailsRet.ref_no,
                  ref_date: billDetailsRet.ref_date !== null ? formatDate(new Date(billDetailsRet.ref_date)) : "",
                  ref_others: billDetailsRet.ref_others,
                  buyer_order_no: billDetailsRet.buyer_order_no,
                  buyer_order_date: billDetailsRet.buyer_order_date !== null ? formatDate(new Date(billDetailsRet.buyer_order_date)) : "",
                  //dispatch_doc_no: billDetailsRet.dispatch_doc_no,
                  //dispatch_through: billDetailsRet.dispatch_through,
                  //destination: billDetailsRet.destination,
                  //bol_no: billDetailsRet.bol_no,
                  //bol_date: formatDate(new Date(billDetailsRet.bol_date)),
                  //vehicle_no: billDetailsRet.vehicle_no,
                  //delivery_terms: billDetailsRet.delivery_terms
                })
              setIsSearched(true);
              }
           }
           else {
            //await getPrevAddr(customerArrRef.current[0].value);
           }

        })();
        
        //setProductMaster(product_list);
        //getProductApiCall();
        
        /*
        var stateIdInput = 1;
        var stateNameInput = "TAMIL NADU";
        const getDistrictListWrapper = async () => {
          await getDistrictList(stateIdInput, stateNameInput);
        }
        getDistrictListWrapper();
        
        var country_id = appGlobal.COUNTRY_ID;
        var retStateStList = false;
        var stateArrRet = [];
        const getStateListWrapper =  async() => {
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if ( retStateStList) {
            console.log("RETURNED STATE LIST : ", stateArrRet);
            setOptionsState(stateArrRet);
            if ( state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
            //stateListRef.current =  stateArrRet;
            var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
            if (findState ) {
              setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
            }
            }
          }
        }
        */

        //getStateListWrapper();
        /*
        (async () => {
          var stateIdInput = 1;
          var retStateCustomer = false;
          var customerInfo = {};
         var customerId = passedValue.customer_id;
          //var [retStateSellProd, newSellProdArr] = await getProductsSellAll();
          {/ *
          if ( retStateSellProd ) {
            console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr);
            setProductMaster(newSellProdArr);
          
          }
        * /}
          
          if (state.action === "edit" || state.action === "view") {
          if ( parseInt( customerId) > 0 ) {
            //[retStateCustomer, customerInfo] = await getCustomerDetails(customerId);

            //await getOrderAddress();
            //await getOrderDetails();
            //await getOrderItemsByOrderId();
            //await getDCListByOrderId();
          }
            //await getOrderAddress();
           //await getOrderDetails();
            ////await getOrderItemsByOrderId();
            //await getDCListByOrderId();
            setIsReadOnly(true);
            //setDisableState(true);
          }
          else {
            setIsReadOnly(false);
            //setDisableState(false);
          }
          
          if (state.action === 'view' || state.action === 'edit') {
            if ( parseInt( customerId) > 0 ) {  
              stateIdInput = customerInfo.state_id;
            }
            else {
              stateIdInput = 1
            }
          }
          else {
            stateIdInput = appGlobal.DEFAULT_STATE;
          }
         /*
          var retStateDist = false;
          var distArrRet = [];
          [retStateDist, distArrRet] = await getDistrictList(stateIdInput);

          if (retStateDist) {
            setOptDist(distArrRet);
            if (state.action === 'add') {
              //setDistrictIdSelect(distArrRet[0]);
              var findDist = distArrRet.find(element => element.label === appGlobal.DEFAULT_DISTRICT);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect(findDist);
              }
            }
            else {
              var findDist = distArrRet.find(element => element.value === customerInfo.district_id);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                //setDistrictIdSelect({ value: customerInfo.district_id, label: findDist.label });
                setDistrictIdSelect(findDist);
              }
            }
          }
          
         
          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if (retStateStList) {
            setOptionsState(stateArrRet);
            if (state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === customerInfo.state_id);
              if (findState) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({ value: customerInfo.state_id, label: findState.label });
              }
            }
          }
          * /
          
          setToRender(true);
          /*
          if ( state.action === 'add' ) {
            var [retStateInvNumber, retExpectedInvNumber] = await getExpectedInvNumber();
            if ( retStateInvNumber ) {
              console.log("<<<<<<<<<<<<<<<<  EXPECTED INVOICE NUMBER : ", retExpectedInvNumber);
              expected_inv_number_ref.current = retExpectedInvNumber;
              setFormValuesOrder({ ...formValuesOrder, ["expected_inv_number"]: retExpectedInvNumber});
              //formValues["expected_inv_number"] = retExpectedInvNumber;
            }
          }
         * /
        })();
        */
       
         setToRender(true);
       
        //setDateIdSelect(optionsDate[0]);
       
        initialized.current = true;
      }
    }
  }, []);

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");

    validate(formValues)

    validateErr = false;
    setFormErrors(validate(formValues));
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
    if (validateErr ) {
      console.log("FORM VLIDATION FAILED ...");
      return -1;
    }


    console.log("FORM VALUES at handleSubmit: ", formValues);
    console.log("LINE ITEMS : ", items);
    
    var selBusiness = localStorage.getItem("selected_business");
    var selBusinessObj = JSON.parse(selBusiness);
    var bidFromLocal = selBusinessObj.value;

    var selBranch = localStorage.getItem("selected_branch");
    var selBranchObj = JSON.parse(selBranch);
    var bridFromLocal = selBranchObj.value;
         
     console.log("expected BILL number (2) : ", expected_bill_number_ref.current);

     var selectedItems = [];
     items.forEach(function(dcItem){
      if ( dcItem.dc_item_check === true ) {
        selectedItems.push(dcItem);
      }
     })
     console.log("Extracted SELECTED ITEMS ONLY : ", selectedItems);

     let reqBillAddUpdate = {
      id: formValues["id"] || 0,
      bid : bidFromLocal,
      brid : bridFromLocal || 0,
      bill_no : formValues.bill_no,
      bill_sno : expected_bill_number_ref.current,
      bill_date : formValues["bill_date"],
      due_date : formValues["due_date"],
      customer_id : customerIdSelect.value,

      shipto_id : formValues["ship_id"] || 0,
      address1_ship : formValues["address1_bill"],
      address2_ship : formValues["address2_bill"],
      state_ship : stateIdSelectBill.value,
      district_ship : districtIdSelectBill.value,
      pincode_ship: formValues["pincode_bill"],

      billto_id : formValues["bill_id"] || 0,
      address1_bill : formValues["address1_bill"],
      address2_bill : formValues["address2_bill"],
      state_bill : stateIdSelectBill.value,
      district_bill : districtIdSelectBill.value,
      pincode_bill: formValues["pincode_bill"], 
      
      pos_state_id : stateIdSelectBill.value,
      sub_total_amt : sum_product_cost_ref.current.toFixed(2),
      cgst_amt : sum_product_cgst_ref.current.toFixed(2),
      sgst_amt : sum_product_sgst_ref.current.toFixed(2),
      igst_amt : sum_product_igst_ref.current.toFixed(2),
      cess_amt : sum_product_cess_ref.current.toFixed(2),
      total_amt : sum_product_total_ref.current.toFixed(2),
      roundoff_amt : (Math.round(sum_product_total_ref.current) - sum_product_total_ref.current).toFixed(2),
      bill_total_amt : Math.round(sum_product_total_ref.current).toFixed(2),
      reference : formValues["reference"],
      bank_acc_id : bankAccountIdSelect.value,
      bank_acc_details : JSON.stringify(bankAccountIdSelect.bank_acc_json),
      notes : formValues["notes"],
      tc : formValues["tc"], 
      declaration : formValues["declaration"],

      bill_details_id: formValues["dc_details_id"] || 0,
      //delivery_note_no: formValuesAddl.delivery_note_no  || "",
      //delivery_note_date: formValuesAddl.delivery_note_date || formatDate(new Date()),
      pay_terms: formValuesAddl.pay_terms || "",
      ref_no: formValuesAddl.ref_no || "",
      ref_date: formValuesAddl.ref_date,
      ref_others: formValuesAddl.ref_others || "",
      buyer_order_no: formValuesAddl.buyer_order_no || "",
      buyer_order_date: formValuesAddl.buyer_order_date,
      //dispatch_doc_no: formValuesAddl.dispatch_doc_no || "",
      //dispatch_through: formValuesAddl.dispatch_through || "",
      //destination: formValuesAddl.destination || "",
      //bol_no: formValuesAddl.bol_no || "",
      //bol_date: formValuesAddl.bol_date,
      //vehicle_no: formValuesAddl.vehicle_no || "",
      //delivery_terms: formValuesAddl.delivery_terms || "",
      //issue_datetime: formValuesAddl.issue_date + " " + formValuesAddl.issue_time,
      //process_duration: formValuesAddl.process_duration || "",
      //process_nature: formValuesAddl.process_nature || "",
      bill_items:  selectedItems,
     }

     console.log("payload:", reqBillAddUpdate);
    
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-print') {
         setSubmitPrintAction(true);
       }
       else {
         setSubmitAction(true);
       }
       
       var res = await axios.post(client.domain + "/bms/addUpdateBillApi", reqBillAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.res_data);
         if ( actionVal === 'save-print') {
           setSubmitPrintAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertTitle("Success");
         setAlertPurpose('success');
         if(state.action === "add"){ 
           setAlertBody("Bill Saved Successfully!");
         } 
        else {
         setAlertBody("Bill Updated Successfully!");
         }
         setShowAlert(true);
 
         if ( actionVal === 'save-print' ) {
           navigate(-1);
 
           var urlStr = window.location.href;
           var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
           var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
       
           console.log("CALLED URL : ", urlTmp);
           console.log("CALLED PAGE : ", paramsTmp);
           //var idPass = state.passed_element.id;
           //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
           var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustBillMfgT1?id=${res.data.detail.res_data.id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
           winPrint.moveTo(0,0);
           winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
         }
         else {
           setShowAlert(true);
         }  
       }
       else {
         setSubmitAction(false);
         setAlertPurpose('failed');  
         setAlertTitle("Failed");
         if(state.action === 'add'){    
           setAlertBody("Bill Save Failed!");
         }
         else{
           setAlertBody("Bill Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertPurpose("failure");
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       
      
       setSubmitAction(false);
     }

    };

  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
    /*
    if (!values.customer_name){
      console.log("Customer name is missing ...", formValues["customer_name"]);
      //errors.customer_name = "Customer name is required!";
      //validateErr = true;
    }
   
    if (!values.customer_disp_name) {
      errors.customer_disp_name = "Customer name is required!";
      validateErr = true;
    }
    */

    if (!values.address1_bill) {
      errors.address1_bill = "Place or Location is required";
      validateErr = true;
    }

    /*
    if (!values.address2_bill) {
      errors.address2_bill = "Town or City is required";
      validateErr = true;
    }
    */

    if (!values.pincode_bill) {
      errors.pincode_bill = "Pincode is required";
      validateErr = true;
    }
    
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);
    */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };
 /*
  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...", productMaster[0]);

    const newitems = items.slice();
    //const newitems = [];
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      //id: 0,
     // product_id: productMaster[0].id,
      //product_name: productMaster[0].name,
      //product_type:productMaster[0].product_type,
      rate: 0.00,
      quantity: "",
      unit_price: "",
      area: 1,
      //total_rate: 0.00,
      total_price: "",
      //cgst: 0.00,
      //sgst: 0.00,
      //igst: 0.00,
      //total_item_price: 0.00,
      addl_notes: "",
      
      editAction: false,
      enableState: false,
      composition: [],
      is_product_cc: false,
    };
    
    newitems.push(itemNew);
    setItems(newitems);
    
  };
  */

  const handleProductSelect = (e, data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected.product);
    console.log("********************** itemSelected: ", itemSelected);
    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === e.label);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.enableState = true;
    //itemSelected.isEditable = true;
    itemSelected.editAction = false;
    //itemSelected.product.label = e.label;
    
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.product_id,
      product_name: e.product_name,
      stock_id: e.stock_id,
      unit_cost: e.unit_cost,
      checkState: e.checkState,
      //cgst: e.cgst,
      //sgst: e.sgst,
      //igst: e.igst,
    };
    itemSelected.product = productNew;
    
    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    /*
    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.igst_perc = productMaster[currentProductIndex].igst;
    itemSelected.product_type = productMaster[currentProductIndex].product_type;
    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    if ( (itemSelected.quantity && productMaster[currentProductIndex].unit_price) && parseFloat(productMaster[currentProductIndex].unit_price) !== isNaN && itemSelected.quantity) {
      itemSelected.total_item_price = productMaster[currentProductIndex].unit_price * itemSelected.quantity;
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    itemSelected.total_price =
      parseFloat(itemSelected.total_item_price) +
      parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);
    console.log("Modified Additional Notes : ", itemSelected.addl_notes);
   */

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
    setIsEditable(false);
  };

  /*
  const searchActionFunc = (json) => {
    setOptionsSearch(json.detail.res_data.district_list);
    setIsLoading(false)
    console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
  }
  */

  const customerSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);
      
      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(
        customer_info.detail.res_data.customer_info
      )) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues(customer_info.detail.res_data.customer_info);
      if ( gstBillStatus ) {
        if ( !customer_info.detail.res_data.customer_info.gst_number || customer_info.detail.res_data.customer_info.gst_number === '0') {
          setAlertPurpose("gst_validate");
          setAlertTitle("Warning");
          setAlertBody("Customer GST not available, needs to update customer detail. \nPress Ok to update GST.\nPress Cancel to continue BILL without GST");
          setShowAlert(true);
        }
    }
      //setIsLoading(true);

      console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
      setStateIdSelect({
        value: customer_info.detail.res_data.customer_info["state_id"],
        label: customer_info.detail.res_data.customer_info["state_name"],
      });
      console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
      setDistrictIdSelect({
        value: customer_info.detail.res_data.customer_info["dist_id"],
        label: customer_info.detail.res_data.customer_info["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      console.log("FORMVALUES 2 : ", formValues);
    }
  };
  
  /*
  const getCustomerDetails = async (customerId) => {
    var retState = false;
    //console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    //if (selected.length > 0) {
    //setSearchedCompany(selected[0])
    //var customerId = passedValue.customer_id;

    const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${customerId}`);
    const customer_info = await response.json();
    retState = true;
    console.log("CuSTOMER INFO : ", customer_info.detail.res_data.customer_info);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }

    //setFormValues(customer_info.detail.res_data.customer_info);

    formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
    formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
    formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
    formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
    formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
    formValues["gst_number"] = customer_info.detail.res_data.customer_info.gst_number;
    formValues["phone"] = customer_info.detail.res_data.customer_info.phone;
    formValues["email"] = customer_info.detail.res_data.customer_info.email;
    formValues["website"] = customer_info.detail.res_data.customer_info.website;
    formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
    formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
    
    formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
    formValues["primary_number"] = customer_info.detail.res_data.customer_info.primary_number;
    formValues["secondary_number"] = customer_info.detail.res_data.customer_info.secondary_number;

    if (state.action === "edit" || state.action === "view") {
      console.log(
        "######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%"
      );
      setCustomerSearchText(customer_info.detail.res_data.customer_info.customer_name);
      //setIsLoading(true);
    }

    / *
    if (!customer_info.detail.res_data.customer_info.gst_number && !gstBillStatus){
      setAlertPurpose('failed');
      setShowAlert(true);
      setAlertDialogBody("Please enter GST Number");
      setAlertDialogTitle("CONFIRMATION!");
    }
    * /

    console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
    setStateIdSelect({
      value: customer_info.detail.res_data.customer_info["state_id"],
      label: customer_info.detail.res_data.customer_info["state_name"],
    });
    console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
    setDistrictIdSelect({
      value: customer_info.detail.res_data.customer_info["dist_id"],
      label: customer_info.detail.res_data.customer_info["district_name"],
    });

    //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
    //setFormValues({...formValues, ["pincode"]: "641654"});

    console.log("FORMVALUES 2 : ", formValues);
    //}
    return [retState, customer_info.detail.res_data.customer_info];
  };
  */

  /*
  const getExpectedInvNumber = async() => {
    var retState = false;
    var retExpectedInvNumber = 0;
    var retState = false;
    var prodRes = {};
    console.log("getExpectedInvNumber called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/getExpectedInvNumber", { headers }, { withCredentials: false });
      console.log("Response from server getExpectedInvNumber : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getExpectedInvNumber : ", res.data.detail.data);
        retState = true;
        retExpectedInvNumber = res.data.detail.data.expected_inv_number;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
        }
      }
    }
    return [retState, retExpectedInvNumber];
  }
  */ 

  /*
  const searchHandleFunc = async (query) => {
    setIsLoading(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.customer_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.customer_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    / *
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    * /
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  }
*/

  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;
  var sum_rounded_off = 0.00;

  console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items.length);
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    var totGst = item.cgst + item.sgst + item.igst;
    console.log("GST  : ", totGst);

    //console.log("GST  : ", item.gst.cgst);
    //console.log("GST  : ", item.gst.sgst);
    //console.log("GST  : ", item.gst.igst);

    console.log("Total Price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + parseFloat(item.total_item_price);

    //sum_product_gst = sum_product_gst + item.gst.totGst;
    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    if ( parseFloat(item.igst) !== isNaN ) {
      sum_product_igst = sum_product_igst + item.igst;
    }
    else {
      sum_product_igst = sum_product_igst + 0.00;
    }

    //sum_product_total = sum_product_total + item.total_cost;
    //sum_product_total =  sum_product_total +
  });

  console.log("************** SUM PRODUCT COST : ", sum_product_cost);

  //sum_product_cgst = Math.round((sum_product_cgst * 100)/100);
  //sum_product_sgst = Math.round((sum_product_sgst * 100)/100);
  console.log("^^^^^^^^^^^^^^^^^^^^^ IGST BEFORE ROUNDED OFF:", sum_product_igst);
  if ( sum_product_igst !== 'NaN' || sum_product_igst !== NaN ) {
    //sum_product_igst = Math.round((sum_product_igst * 100)/100);
  }
  else {
    sum_product_igst = 0.00;
  }
  console.log("************** CGST : ", sum_product_cgst);
  console.log("************** SGST : ", sum_product_sgst);
  console.log("************** IGST : ", sum_product_igst);
  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;
  console.log("************** SUM PRODUCT TOTAL 1 : ", sum_product_total);

  sum_product_total = sum_product_total - amount_discount;
  console.log("************** SUM PRODUCT TOTAL 2 : ", sum_product_total);
  //sum_product_total = Math.round((sum_product_total + Number.EPSILON)*100)/100;

  console.log("Tot number of items : ", total_items);
  console.log("Sum of Tot Product Cost : ", sum_product_cost);

  console.log("Sum of Tot CGST : ", sum_product_cgst);
  console.log("Sum of Tot SGST : ", sum_product_sgst);
  console.log("Sum of Tot iGST : ", sum_product_igst);

  console.log("Sum of Tot GST : ", sum_product_gst);

  console.log("Sum of total cost : ", sum_product_total);

  //var tmp_val2 = sum_product_total - Math.round(sum_product_total);
  var tmp_val2 = Math.round(sum_product_total) - sum_product_total;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 1 : ", tmp_val2);
  sum_rounded_off = Math.round((tmp_val2 + Number.EPSILON)*100)/100;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 2 : ", sum_rounded_off);

  

  console.log("Currently selected district : ", districtIdSelect);
  console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  console.log("Currently selected state : ", stateIdSelect);
  console.log("+++++++++++++++ Customer search text ^^^^^^^^^^^^^^^^^^^^  :", customerSearchText);
  console.log("Searched customer <<<<<<<<<<<<<<<<<<< : ", searchedCustomer);

  const [addrCopyCheck, setAddrCopyCheck] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handleCopyToShipping = async (e) => {
    console.log("handleCopyToShipping called : ", e);
    console.log("handleCopyToShipping called CHECKED : ", e.target.checked);
    
    //var contactPersontmp = formValues["contact_person"];
    //var contactPersonAssign = "";
    //var contactNumbertmp = formValues["contact_number"];
    //var contactNumberAssign = "";
    //setFormValues({...formValues, ["addrCopyCheck"]: e.currentTarget.checked});
      if (e.target.checked === true) {
        if ( !formValues.contact_person ) {
         formValues["shipContactName"] = formValues["customer_name"]; 
        }
        else {
          formValues["shipContactName"] = formValues["contact_person"];
        }

        if ( !formValues.contact_number ) {
          formValues["shipContactNumber"] = formValues["phone"];
        }
        else {
          formValues["shipContactNumber"] = formValues["contact_number"];
        }
        formValues["shipAddress1"] = formValues["address1"];
        formValues["shipAddress2"] = formValues["address2"];
        setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });
        //setFormValues(e.target.checked);
      }
      setAddrCopyCheck(e.target.checked);
      setRefresh(true);
    };
      
      /*
      var contactPersontmp = formValues["contact_person"];
      var contactPersonAssign = "";

      console.log("Customer contact name before check : ", contactPersontmp);

      console.log("Customer contact name length before check : ", contactPersontmp.length);

      if (contactPersontmp.length > 0) {
        contactPersonAssign = contactPersontmp;
      } else {
        var customer_name_tmp = "";
        var customer_id_tmp = 0;

        console.log("searched customer array : ", searchedCustomer);
        console.log("searched customer length : ", searchedCustomer.length);
        console.log("searched customer length Object : ", Object.keys(searchedCustomer).length);
        //if (!searchedCustomer || searchedCustomer.length < 1) {
        if (Object.keys(searchedCustomer).length < 1) {
          console.log("Inside IF condition");
          customer_name_tmp = customerSearchText;
          customer_id_tmp = 0;
        } else {
          console.log("Inside ELSE conditiob");
          customer_name_tmp = searchedCustomer.label;
          customer_id_tmp = searchedCustomer.value;
        }

        console.log("Customer name to submit : ", customer_name_tmp);

        contactPersonAssign = customer_name_tmp;
      }

      var contactNumbertmp = formValues["contact_number"];
      var contactNumberAssign = "";

      console.log("Contact number : ", contactNumbertmp);
      if (contactNumbertmp) {
        contactNumberAssign = contactNumbertmp;
      } else {
        contactNumberAssign = formValues["phone"];
      }

      formValues["shipContactName"] = contactPersonAssign;
      formValues["shipContactNumber"] = contactNumberAssign;
      formValues["shipAddress1"] = formValues["address1"];
      formValues["shipAddress2"] = formValues["address2"];
      //formValues["billPincode"] =  formValues["pincode"];
      //setFormValues({...formValues, ["billContactName"]: formValues["contact_person"]});
      //setFormValues({...formValues, ["billContactNumber"]: formValues["contact_number"]});
      //setFormValues({...formValues, ["billAddress1"]: formValues["address1"]});
      //setFormValues({...formValues, ["billAddress2"]: formValues["Address2"]});
      setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });

      //setBillDistrictIdSelect(districtIdSelect);
    }
  };
   */
  const [addrCopyBillCheck, setAddrCopyBillCheck] = useState(false);

  const handleCopyToBilling = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setAddrCopyBillCheck(e.target.checked);

    if (e.target.checked === true) {
      formValues["billContactName"] = formValues["shipContactName"];
      formValues["billContactNumber"] = formValues["shipContactNumber"];
      formValues["billAddress1"] = formValues["shipAddress1"];
      formValues["billAddress2"] = formValues["shipAddress2"];
      setFormValues({...formValues, ["billPincode"]: formValues["shipPincode"]});
    }
    setRefresh(true);
  };

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  /*

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };
  */

  const handleGSTBillStatus = async (e) => {
    console.log("HandleGSTBillStatus called : ", e);
    console.log("target checked : ", e.target.checked);

    var itemModNew = [];
    items.map((item) => {
      console.log("IND ITEM : ", item);

      var cgstCalculate = 0.0;
      var sgstCalculate = 0.0;
      var igstCalculate = 0.0;

      if (e.target.checked) {
        //if (item["product"] !== undefined) {
          console.log("CGST FROM EXISTING : ", item.cgst_perc);
          console.log("TOTAL ITEM PRICE FROM EXISTING : ", item.total_item_price);
          cgstCalculate = (item.total_item_price * parseFloat(item.cgst_perc)) / 100;
          sgstCalculate = (item.total_item_price * parseFloat(item.sgst_perc)) / 100;
          igstCalculate = (item.total_item_price * parseFloat(item.igst_perc)) / 100;
        //}
      }
      item.cgst = cgstCalculate;
      item.sgst = sgstCalculate;
      item.igst = igstCalculate;

      console.log("CALCULATED CGST : ", cgstCalculate);
      console.log("CALCULATED SGST : ", sgstCalculate);
      console.log("CALCULATED IGST : ", igstCalculate);
      item.total_price =
        item.total_item_price + cgstCalculate + sgstCalculate + igstCalculate;
      //item.total_price = Math.round(item.total_price+'e' + 2) + 'e-' + 2

      console.log("CALCULATED TOTAL : ", item.total_price);

      itemModNew.push(item);
    });

    setGstBillStatus(e.target.checked);
    setItems(itemModNew);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit clicked : ", e);
    console.log("Handle Edit clicked current target ID: ", e.currentTarget.id);

    setIsReadOnly(false);
    //setDisableState(false);
  };

  const getProductForLoad = async (productIdInput, prodIdIdx) => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + productIdInput, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        //setFormValues(res.data.detail.data);
        /*
        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }
        */
        //setProductCompositionList(res.data.detail.data.composite);
        console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
        console.log("SELECTED PROD DATA : ", items[prodIdIdx]);

        var selectedProdTmp2 = items[prodIdIdx];

        selectedProdTmp2.composition = res.data.detail.data.composite;

        var newItemsTmp2 = items.slice();
        newItemsTmp2[prodIdIdx] = selectedProdTmp2;
        setItems(newItemsTmp2);
        //setProductCompositionList(res.data.detail.data.composite);
        /*
        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
        */
        //setProdDetLoaded(true);
        //setToRender(true);
        //setEditAction(false);
        setShowConfigDialog(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, prodRes];
  }

  const handleCompositeEdit = async (e, rowData) => {
    setEditAction(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id , ", name : " , name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", rowData);
    
    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);
      
      if ( items[productIdInput].addl_notes) {
        setFormValues({ ...formValues, addl_notes: items[productIdInput].addl_notes});
      }
      else {
        setFormValues({ ...formValues, addl_notes: ""});
      }
      

      setSelectedCompositionId(productIdInput);
      /*
      let sliced = items.splice(productIdInput, 1);
      console.log("Edit item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }
    

    // get composition from api call
    //https://api.erp.avinasoft.in/ims/getProduct?id=6

   
    var selectedItem = items[productIdInput];
     
    //var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    //if ( retStateProdBuy ) {
      //setProductList(buyProdArr);
   // }
    var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    //if ( selectedItem.composition.length < 1 ) { 
      await getProductForLoad(productIdPass, productIdInput);
    //}
    //else {
    //  setShowConfigDialog(true);
    //}
     
    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

    if (!selectedItem.hasOwnProperty("composition") ) {
      console.log("********************** Product composition doesn't exist +++++++++++++++++ ");
      //selectedItem.composition = [];
    }
    else {
      console.log("******************* Product composition already exist in items ++++++++++++++");
    }
   

    const newitems = items.slice();
    newitems[productIdInput] = selectedItem;
    setItems(newitems);
  };


  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handle save called : ", e);
    console.log("Handle save clicked current target ID: ", e.currentTarget.id);
  };

  const handleKeyDown = async (e) => {
    console.log("handle key down called : ", e);
    return e.keyCode !== 190;
  };

  const onChangeBillDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, bill_date: e.target.value });
  };

  const onChangeDeliveryDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, delivery_date: e.target.value });
  };

  const onChangeRefDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, ref_date: e.target.value });
  };

  const onChangeBuyerOrderDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, buyer_order_date: e.target.value });
  };

  const onChangeBolDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, bol_date: e.target.value });
  };

  
 
  const handleProductSelectComposition = (e, item, idx) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element full : ", item);
    console.log("SELECTED element idx : ", idx);
    //var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
   // console.log("Product Info : ", productMaster);

    var itemSelected = items[idx];
    console.log("Passed element item : ", item);
    console.log("Item selected : ", itemSelected);


    items[selectedCompositionId].composition.push();
  };

  const handleItemDeleteComposition = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", productCompositionList[curSelId]);

      let sliced = productCompositionList.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newProductCompositionList = productCompositionList.slice();
      setProductCompositionList(newProductCompositionList);

    }
  };

  const handleCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowConfigDialog(false);
  }

  const handleSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    //selectedItem.compositionUpdate = true;
    //selectedItem.is_product_cc = true;
    console.log("SELECTED ITEMS<<<<<<<<<<<<:", selectedItem);

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  }

  const handleCloseCompositionProduct = async () => {
    console.log("handle close composition  product called ...");
    navigate(0);

  }

  const handleOnItemChangeComposition = (e) => {
    //e.preventDefault();
    console.log("handleOnItemChangeComposition called : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    itemSelected.addl_notes = value;
    setFormValues({ ...formValues, addl_notes: value});
    //console.log("selected composition for M grade products : ", itemSelected.composition);
    
    //console.log("SELECTED COMPOSITION COMPONENt element : ", itemSelected.composition[currentId]);
   
    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    //setFormValues({ ...formValues, [name]: value });
    
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name
    );
     
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      //const newProductCompositionList = productCompositionList.slice();
      //newProductCompositionList[currentId] = itemSelected;
      //setProductCompositionList(newProductCompositionList);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);
    

    /*
    itemSelected.product = {
      id: productList[currentProductIndex].id,
      name: productList[currentProductIndex].name,
      
    };
    */
    //itemSelected.addl_notes = productMaster[currentProductIndex].addl_notes;
    /*
    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");

      // const regex =  /^[0-9\b]+$/;
      /*
       const numericRegExp = /^\d*\.?\d*$/;
       //if (numericRegExp.match(e.target.value)) {
       if (e.currentTarget.value.match(numericRegExp)) {
         itemSelected[nameOfElement] = e.currentTarget.value;
       }
       * /
      //const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //itemSelected[nameOfElement] = e.currentTarget.value.replace(/^0+/, '');
        itemSelected.composition[currentId].quantity = value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    */

    //const newProductCompositionList = productCompositionList.slice();
    //newProductCompositionList[currentId] = itemSelected;
    //setProductCompositionList(newProductCompositionList);
    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    console.log("NEW ITEMS TMP<<<<<<<<<<<<< :", newItemsTmp);
    setItems(newItemsTmp);
  };

  const handleAlertOK = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listCustomerBills");
      setAlertPurpose('init');
    }
    else if( alertPurpose === 'confirmation') {
      //navigate(0);
      formValues["customer_name"] = "";
      formValues["customer_disp_name"] = "";
      formValues["gst_number"] = "";
      formValues["phone"] = "";
      formValues["primary_number"] = "";
      formValues["secondary_number"] = "";
      formValues["contact_person"] = "";
      formValues["contact_number"] = "";
      formValues["email"] = "";
      formValues["address1"] = "";
      formValues["address2"] = "";
      //formValues["distId"] = "";
      //formValues["stateId"] = "";
     
      
      setFormValues({ ...formValues, pincode: ""});
    }
    else if ( alertPurpose === 'gst_validate' ) {
      navigate('/listCustomers');
    }
  }

  const handleCloseDialog = () => {
    console.log("handleCloseDialog called ....");
    setShowAlert(false);

    if ( alertPurpose === 'success') {
      navigate("/listOrders");
      setAlertPurpose('init');
    }
    else if ( alertPurpose === 'gst_validate' ) {
      console.log("Inside Cancel for gst validate");
      setGstBillStatus(false);
    }
  }

  const handleReset = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    setDistrictIdSelect(optDist[0]);
    //setStateIdSelect(optionsState[0]);
    setAlertPurpose('confirmation');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to Reset  Customer Details and Address");
    setShowAlert(true);
      
    //navigate(0);
  };
 
  const handleDateSelect = (e, data) => {
    console.log("handleDateSelect called ...", e);

    setDateIdSelect(e);
  };

  const handlePrintFullOrder = async(e) => {
    console.log("Handle print order clicked : ", e);
    //passedValue.order_id

    e.preventDefault();
    console.log("Handle action print Clicked : ", e);

    console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

    console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustBillMfgT1?id=${state.passed_element.id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }

  
  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
  };


  const getDCItemListByCustomerId = async (customer_id_pass) => {
    console.log("getInitValues called ...");

    var retState = false;
    var dcItemListRet = {};
    /*
    var reqBillInitLoad = {
      customer_id: customer_id_pass
    };
    */
    var aIVal = localStorage.getItem("_aT");
    var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
    console.log("payload (BUSINESS ID) :", customer_id_pass);
    console.log("HEADERS : ", headers);

    try {
      var res = await axios.get(client.domain + `/bms/getCustDCItemsByCustId?customer_id=${customer_id_pass}`, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response for getDCItemListByCustomerId message from server : ", res.data.detail.data);
        dcItemListRet = res.data.detail.data.dc_list;
        retState = true;
        /*
        customerArrRef.current = res.data.detail.data.customers;
        itemArrRef.current = res.data.detail.data.items;
        unitArrRef.current = res.data.detail.data.units;
        bankAccArrRef.current = res.data.detail.data.bank_accounts;
        countryArrRef.current = res.data.detail.data.countries;
        stateArrRef.current = res.data.detail.data.states;
        districtArrRef.current = res.data.detail.data.districts;
        docNumberSettingRef.current = res.data.detail.data.doc_number_settings;

        setOptCustomer(customerArrRef.current);
        setCustomerIdSelect(customerArrRef.current[0]);

        setOptionsStateShip(stateArrRef.current);
        console.log("^^^^^^^^^^^^^^^^ BEFORE ASSIGN VALUE TO STATEIDSELECTSHIP ************** : ", stateArrRef.current[0]);
        setStateIdSelectShip(stateArrRef.current[0]);

        setOptionsDistrictShip(districtArrRef.current);
        setDistrictIdSelectShip(districtArrRef.current[0]);

        setOptionsStateBill(stateArrRef.current);
        setStateIdSelectBill(stateArrRef.current[0]);

        setOptionsDistrictBill(districtArrRef.current);
        setDistrictIdSelectBill(districtArrRef.current[0]);

        setOptBankAccount(bankAccArrRef.current);
        setBankAccountIdSelect(bankAccArrRef.current[0]);

        console.log("<<<<<<<<<<<<<<<< EXPECTED INV NUMBER : ", docNumberSettingRef.current[0].sno_generated + 1);
        //setFormValues({ ...formValues, inv_sno: docNumberSettingRef.current[0].sno_generated + 1 });
        setFormValues({ ...formValues, inv_no: docNumberSettingRef.current[0].sno_generated + 1 });
        expected_inv_number_ref.current = docNumberSettingRef.current[0].sno_generated + 1;
        console.log("expected invoice number : ", expected_inv_number_ref.current);
        setProductMaster(itemArrRef.current);
        */
        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setAlertTitle("FAILED!");
          setAlertBody("Bill initial data loading failed...");
          setShow(true);
        }
      }
    }

    return [retState, dcItemListRet];
  }

  const getPrevAddr = async(customer_id) => {
    console.log("getPrevAddr called customer id : ", customer_id);

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + `/bms/getPrevBillAddrByCustId?customer_id=${customer_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getPrevAddr : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPrevAddr : ", res.data.detail.data);
        setBillAddress(res.data.detail.data.prev_address);

        //formValues["address1_ship"] = res.data.detail.data.prev_address[0].address1_ship;
        //formValues["address2_ship"] = res.data.detail.data.prev_address[0].address2_ship;
        //formValues["pincode_ship"] = res.data.detail.data.prev_address[0].pincode_ship;
      
        var findStateBill = JSON.parse(localStorage.getItem("states")).find((element) => element.value === res.data.detail.data.prev_address[0].state_id_ship);
        if ( findStateBill !== undefined ) {
          setStateIdSelectBill(findStateBill)
        }

        var findDistrictBill = JSON.parse(localStorage.getItem("districts")).find((element) => element.value === parseInt(res.data.detail.data.prev_address[0].district_id_ship));
        if ( findDistrictBill !== undefined ) {
          console.log("FOUND DISTRICT VALUE : ", findDistrictBill);
          setDistrictIdSelectBill(findDistrictBill);
        }

        /*
        var findCountryShip = JSON.parse(localStorage.getItem("countries")).find((element) => element.value === res.data.detail.data.prev_address[0].country_id_ship);
        if ( findCountryShip !== undefined ) {
          //setcount(findCountryShip);
        }
        */

        
        //formValues["address1_bill"] = res.data.detail.data.prev_address[0].address1_bill;
        //formValues["address2_bill"] = res.data.detail.data.prev_address[0].address2_bill;
        //formValues["pincode_bill"] = res.data.detail.data.prev_address[0].pincode_bill;


        setFormValues({ ...formValues, 
          address1_bill: res.data.detail.data.prev_address[0].address1_bill,
          address2_bill: res.data.detail.data.prev_address[0].address2_bill,
          pincode_bill: res.data.detail.data.prev_address[0].pincode_bill,
          gst_number: customerIdSelect.gstin
        })

        /*
        var findStateBill = JSON.parse(localStorage.getItem("states")).find((element) => element.value === res.data.detail.data.prev_address[0].state_id_bill);
        if ( findStateBill !== undefined ) {
          setStateIdSelectBill(findStateBill);
        }

        var findDistrictBill = JSON.parse(localStorage.getItem("districts")).find((element) => element.value === res.data.detail.data.prev_address[0].district_id_bill);
        if ( findDistrictBill !== undefined ) {
          setDistrictIdSelectBill(findDistrictBill);
        }
        */

        /*
        var findCountryBill = JSON.parse(localStorage.getItem("countries")).find((element) => element.value === res.data.detail.data.prev_address[0].country_id_bill);
        if ( findCountryBill !== undefined ) {
          //setDistrictIdSelectShip(findCountryBill);
        }
        */

        //setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }

  }

  const handleView = async (e) => {
    console.log("handle edit summary called : ", e);
    console.log("handle edit summary currentTarget called : ", e.currentTarget);
    //setIsReadOnly(false);
    if(customerIdSelect.value < 1){
      setAlertPurpose('customer_status');
      setAlertTitle('Warning');
      setAlertBody("Select Valid Customer from Search!");
      //setSubmitDelAction(false);
      setShowAlert(true);
    }
    else {
      
      var [retState, dcItemListRet] = await getDCItemListByCustomerId(customerIdSelect.value);
      if ( retState ) {
        console.log("RETURNED DC ITEM LIST : ", dcItemListRet);


        if ( state.action === 'add') {
          var modDCItemList = [];
          dcItemListRet.forEach(function(dcItem){
            var dcItemTmp = dcItem;
            dcItemTmp.billable_quantity = dcItem.qty_unbilled;
            /*
            dcItemTmp.net_amt = dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price;
            dcItemTmp.cgst_amt = (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) * (dcItemTmp.jw_cgst_per / 100);
            dcItemTmp.sgst_amt = (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) * (dcItemTmp.jw_sgst_per / 100);
            if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ) {
              dcItemTmp.total_amt = (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) +  (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) * (dcItemTmp.jw_cgst_per / 100) + (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) * (dcItemTmp.jw_sgst_per / 100);
            }
            else {
              dcItemTmp.total_amt = (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) +  (dcItemTmp.billable_quantity * dcItemTmp.jw_unit_price) * (dcItemTmp.jw_igst_per / 100);
            }
            */
            dcItemTmp.net_amt = 0.00;
            dcItemTmp.total_amt = 0.00;
            modDCItemList.push(dcItemTmp);
          })
          setItems(modDCItemList);
          sumBill(modDCItemList, stateIdSelect);

          await getPrevAddr(customerIdSelect.value);

          //setFormValues({ ...formValues, gst_number: customerIdSelect.gstin});
        }
        else {
          setItems(dcItemListRet);
        }
        setIsSearched(true);
      }
      //setIsSearched(true);
      
    }

  };


  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    console.log("handle copy to billing called TARGET :", e.target);
    console.log("handle copy to billing called CURRENT TARGET :", e.currentTarget);
    console.log("handle copy to billing called CURRENT TARGET NAME:", e.currentTarget.name);
    console.log("handle copy to billing called CURRENT TARGET VALUE:", e.currentTarget.value);

    var currentId = e.currentTarget.name.substring(e.currentTarget.name.lastIndexOf("-") + 1);
    var nameOfElement = e.currentTarget.name.substring(0, e.currentTarget.name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    itemSelected.dc_item_check = e.target.checked;

    if ( itemSelected.dc_item_check ) {
    itemSelected.net_amt = itemSelected.billable_quantity * itemSelected.jw_unit_price;
    itemSelected.cgst_amt = (itemSelected.billable_quantity * itemSelected.jw_unit_price) * (itemSelected.jw_cgst_per / 100);
    itemSelected.sgst_amt = (itemSelected.billable_quantity * itemSelected.jw_unit_price) * (itemSelected.jw_sgst_per / 100);
    if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ) {
      itemSelected.total_amt = (itemSelected.billable_quantity * itemSelected.jw_unit_price) +  (itemSelected.billable_quantity * itemSelected.jw_unit_price) * (itemSelected.jw_cgst_per / 100) + (itemSelected.billable_quantity * itemSelected.jw_unit_price) * (itemSelected.jw_sgst_per / 100);
    }
    else {
      itemSelected.igst_amt = (itemSelected.billable_quantity * itemSelected.jw_unit_price) * (itemSelected.jw_igst_per / 100);
      itemSelected.total_amt = (itemSelected.billable_quantity * itemSelected.jw_unit_price) +  (e.target.value * itemSelected.jw_unit_price) * (itemSelected.jw_igst_per / 100);
    }
  }
  else {
    itemSelected.net_amt = 0.00;
    itemSelected.cgst_amt = 0.00;
    itemSelected.sgst_amt = 0.00;
    itemSelected.total_amt = 0.00;
  }
    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    sumBill(newitems, stateIdSelect);

    //setCheckState(e.target.checked);
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }

  const handleAddlValueChange = async(e) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    setFormValuesAddl({ ...formValuesAddl, [name]: e.target.value});
  }

  const onChangeIssueDatePicker = async(e) => {
    console.log("onChangeIssueDatePicker called ...", e);

    console.log("Date onChangeIssueDatePicker called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, issue_date: e.target.value});
  }

  const onChangeIssueTimePicker = async(e) => {
    console.log("onChangeIssueTimePicker called ...", e);

    console.log("Time onChangeIssueTimePicker called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, issue_time: e.target.value});
  }

  return (
    <>
      
          <Container>
            <SidebarMain sendBacktoParent={sendDataToParent}/>
            { toRender ?
        (
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listCustomerBills')).page_title_addupd : "Customer Bill"}
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{orderInfoDet.order_no}</h6>
                      </Badge>
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-order-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-order-name`}
                      id={`edit-icon-order-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                     &nbsp;&nbsp;
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.a_print === true)) ? false : true}
                >
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                  </Button>
                  
                      &nbsp;&nbsp;
                      <Button
                        variant="primary"
                        className="btn btn-primary btn-md"
                        onClick={(e) => handleEdit(e)}
                        disabled={menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.a_edit === true)) ? false : true}
                      >
                        <BsPencilSquare
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <hr align="center" />
              <Row>

              <Col xs={2} md={2} sm={2} lg={2}>
                      <Form.Group className="mb-3">
                      <Form.Label className="field required">Bill Number</Form.Label>
                      
                      <Form.Control
                        type="text"
                        name="bill_no"
                        id="bill_no"
                        placeholder="Bill Number"
                        value={formValues.bill_no}
                        onChange={handleValueChange}
                        readOnly={isReadOnly}
                        style={{
                          border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                    {/*<p className="error-msg">{formErrors.expected_inv_number}</p>*/}
                    </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Bill Date</Form.Label>
                  
                  <Form.Control
                  type="date"
                  name="bill_date"
                  //disabled="true"
                  placeholder="Bill Date"
                  value={formValues.bill_date}
                  onChange={onChangeBillDatePicker}
                  //readOnly={isReadOnly}
                  //disabled={state.action !== 'add' || isLoading }
                  disabled={ isLoading }
                />
                </Form.Group>
                <p className="error-msg">{formErrors.bill_date}</p>
              </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field">Due Date</Form.Label>

                    <Form.Control
                      type="date"
                      name="due_date"
                      //disabled="true"
                      placeholder="Due Date"
                      value={formValues.due_date}
                      onChange={onChangeDueDatePicker}
                    //readOnly={isReadOnly}
                    //disabled={state.action !== 'add' || isLoading }
                    //disabled={ isLoading }
                    />
                  </Form.Group>
                  <p className="error-msg">{formErrors.due_date}</p>
                </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label className="field">Customer Search</Form.Label>
                      {/*
                      <AsyncTypeahead
                        id="customer-search-fd-id"
                        name="customer_name"
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleFunc(query);
                        }}
                        onChange={(selected) => {
                          customerSearchSelectFunc(selected);
                        }}
                        options={optionsSearch}
                        placeholder="Search Customer ..."
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        style={{
                          borderColor: "gray",
                        }}
                        className="rounded-sm"
                      />
                      */}
                      <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                    </Form.Group>

                    <p className="error-msg">{formErrors.customer_name}</p>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} style={{paddingTop: "30px"}}>
               
                     <Button id="osView" 
                      name = "osView"  
                      onClick={(e)  => handleView(e)}
                      disabled={state.action === 'add' ? false : true}
               //disabled={deliveryChellan.length > 0 ? false: true}
               >
                      <BsCardText
                       size={18}
                       style={{ color: "white", cursor: "pointer" }}
                       name={`view-os-icon`}
                       id={`view-os-icon`}
                      />
                     &nbsp;
                     View DC's
               </Button>
               </Col> 

                </Row>
                { isSearched && 
                <>

                <h5>Bill To</h5>
            <hr align="center" />
            <Row>
            <Col xs={6} md={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="field">
                        Street / Location
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="address1_bill"
                        placeholder="Building No / Street Name"
                        value={formValues.address1_bill}
                        onChange={handleValueChange}
                        //readOnly={state.action === 'add' ? isReadOnly : true}
                        readOnly={isReadOnly}
                        //disabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.address1_bill}</p>
                  </Col>

                  <Col xs={6} md={6} sm={6} lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>City / Town</Form.Label>

                      <Form.Control
                        type="text"
                        name="address2_bill"
                        placeholder="City / Town"
                        value={formValues.address2_bill}
                        onChange={handleValueChange}
                        //readOnly={state.action === 'add' ? isReadOnly : true}
                        readOnly={isReadOnly}
                        //disabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.address2_bill}</p>
                  </Col>
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3" >
                      <Form.Label className="field">State</Form.Label>

                      <Select
                        id="state"
                        name="state"
                        value={{
                          value: stateIdSelectBill.value,
                          label: stateIdSelectBill.label,
                        }}
                        options={optionsState}
                        defaultValue={{
                          value: stateIdSelectBill.value,
                          label: stateIdSelectBill.label,
                        }}
                        onChange={handleStateSelect}
                        //readOnly={state.action === 'add' ? isReadOnly : true}
                        readOnly={isReadOnly}
                        //isDisabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.state}</p>
                  </Col>

            <Col xs={3} md={3} sm={3} lg={3}>
                      <Form.Group className="mb-3" >
                        <Form.Label className="field">District</Form.Label>

                        <Select
                          id="district_bill"
                          name="district_bill"
                          value={{
                            value: districtIdSelectBill.value,
                            label: districtIdSelectBill.label,
                          }}
                          options={optionsDistrictShip}
                          defaultValue={{
                            value: districtIdSelectBill.value,
                            label: districtIdSelectBill.label,
                          }}
                          onChange={handleDistrictSelectBill}
                          //readOnly={state.action === 'edit' ? isReadOnly : false}
                          readOnly={isReadOnly}
                          //isDisabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.district_bill}</p>
                    </Col>

                  <Col xs={2} md={2} sm={2} lg={2}>
                    <Form.Group className="mb-3">
                      <Form.Label className="field">Pincode</Form.Label>

                      <Form.Control
                        type="text"
                        name="pincode_bill"
                        placeholder="Pincode"
                        value={formValues.pincode_bill}
                        onChange={handleValueChange}
                        maxLength={6}
                        //readOnly={state.action === 'add' ? isReadOnly : true}
                        readOnly={isReadOnly}
                        //disabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.pincode_bill}</p>
                  </Col>

                  <Col xs={4} md={4} sm={4} lg={4}>
                    <Form.Group className="mb-3" >
                      <Form.Label>GST IN</Form.Label>

                      <Form.Control
                        type="text"
                        name="gst_number"
                        id="gst_number"
                        placeholder="GST IN"
                        value={formValues.gst_number}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        //disabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.gst_number}</p>
                  </Col>
            </Row>


            <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Additional Details</Accordion.Header>
                  <Accordion.Body>
                    {/*<h5>Additional Details</h5>*/}

                    <Row>
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Mode / Terms of  Payment</Form.Label>

                          <Form.Control
                            type="text"
                            name="pay_terms"
                            placeholder="Mode / Terms of  Payment"
                            value={formValuesAddl.pay_terms}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.pay_terms}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Reference No</Form.Label>

                          <Form.Control
                            type="text"
                            name="ref_no"
                            placeholder="Party DC No"
                            value={formValuesAddl.ref_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.ref_no}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Reference Date</Form.Label>

                          <Form.Control
                            type="date"
                            name="ref_date"
                            //disabled="true"
                            placeholder="Reference Date"
                            value={formValuesAddl.ref_date}
                            onChange={onChangeRefDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.ref_date}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Other Reference</Form.Label>

                          <Form.Control
                            type="text"
                            name="ref_others"
                            placeholder="Other Reference"
                            value={formValuesAddl.ref_others}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.ref_others}</p>
                      </Col>

                    </Row>

                    <Row>
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Buyer's Order No</Form.Label>

                          <Form.Control
                            type="text"
                            name="buyer_order_no"
                            placeholder="Buyer's Order No"
                            value={formValuesAddl.buyer_order_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.buyer_order_no}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Buyer Order Date</Form.Label>

                          <Form.Control
                            type="date"
                            name="buyer_order_date"
                            //disabled="true"
                            placeholder="Buyer Order Date"
                            value={formValuesAddl.buyer_order_date}
                            onChange={onChangeBuyerOrderDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.buyer_order_date}</p>
                      </Col>

                      {/*
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Dispatch Document No</Form.Label>

                          <Form.Control
                            type="text"
                            name="dispatch_doc_no"
                            placeholder="Dispatch No"
                            value={formValuesAddl.dispatch_doc_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.dispatch_doc_no}</p>
                      </Col>

                      
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Dispatch Through</Form.Label>

                          <Form.Control
                            type="text"
                            name="dispatch_through"
                            placeholder="Dispatch Through"
                            value={formValuesAddl.dispatch_through}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.dispatch_through}</p>
                      </Col>
                    */}
                    </Row>

                    {/*
                    <Row>
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Destination</Form.Label>

                          <Form.Control
                            type="text"
                            name="destination"
                            placeholder="Destination"
                            value={formValuesAddl.destination}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.destination}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Bill Of Ladly / Lr.RR No</Form.Label>
                          <Form.Control
                            type="text"
                            name="bol_no"
                            placeholder="Bill Of Ladly / Lr.RR No"
                            value={formValuesAddl.bol_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />

                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.bol_no}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Bill Of Ladly / Lr.RR Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="bol_date"
                            //disabled="true"
                            placeholder="Bill Of Ladly / Lr.RR Date"
                            value={formValuesAddl.bol_date}
                            onChange={onChangeBolDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />

                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.bol_date}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Vehicle No</Form.Label>

                          <Form.Control
                            type="text"
                            name="vehicle_no"
                            placeholder="Vehicle No"
                            value={formValuesAddl.vehicle_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.vehicle_no}</p>
                      </Col>
                      
                    </Row>
                    */}

                    {/*
                    <Row>

                      <Col xs={4} md={4} sm={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Date & Time of issue</Form.Label>
                          <Row>
                          <Col xs={6} md={6} sm={6} lg={6}>
                          <Form.Control
                            type="date"
                            name="issue_date"
                            //disabled="true"
                            placeholder="Date of issue"
                            value={formValuesAddl.issue_date}
                            onChange={onChangeIssueDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                          </Col>

                          <Col xs={6} md={6} sm={6} lg={6}>
                          <Form.Control
                            type="time"
                            name="issue_time"
                            //disabled="true"
                            placeholder="Time of issue"
                            value={formValuesAddl.issue_time}
                            onChange={onChangeIssueTimePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                          </Col>
                          </Row>
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.issue_date_time}</p>
                      </Col>

                      <Col xs={4} md={4} sm={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Duration of Process</Form.Label>

                          <Form.Control
                            type="text"
                            name="process_duration"
                            placeholder="Duration of Process"
                            value={formValuesAddl.process_duration}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.process_duration}</p>
                      </Col>

                      <Col xs={4} md={4} sm={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Nature of Processing</Form.Label>

                          <Form.Control
                            type="text"
                            name="process_nature"
                            placeholder="Nature Of Processing"
                            value={formValuesAddl.process_nature}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.process_nature}</p>
                      </Col>


                    </Row>
                    */}

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

                

              <div className="ui-form">
                
  
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div className="d-flex justify-content-start">
                      <h4>
                        <Badge pill bg="secondary">
                          Item Details
                        </Badge>
                      </h4>
                      <p
                        className="error-msg"
                        style={{
                          position: "relative",
                          //top: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {formErrors.alertMsg}
                      </p>
                    </div>
                  </Col>
                   {/*
                  <Col xs={6} md={6} sm={6} lg={6}>
                    <div className="d-flex justify-content-end">
                     
                      <Button
                        className="btn btn-warning"
                        style={{ alignItems: "self-end" }}
                        onClick={newItemHandle}
                        //disabled={isReadOnly ? true : false}
                      >
                        Add Item
                      </Button>
                    </div>
                  </Col>
                  */}
                </Row>
                <hr align="center" />
                <p></p>

                {items.length > 0 &&
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading" style={{width: `${TBL_CHECKBOX_WIDTH}%`}}></th>
                          <th className="table-row-heading" style={{width: `${TBL_SNO_WIDTH}%`}}>SNo</th>
                          <th className="table-row-heading" style={{width: `${TBL_DCDATE_WIDTH}%`}}>DC Date</th>
                          <th className="table-row-heading" style={{width: `${TBL_DCNO_WIDTH}%`}}>DC No</th>
                          <th className="table-row-heading" style={{width: `${TBL_PRODUCT_NAME_WIDTH}%`}}>Particulars</th>
                          <th className="table-row-heading" style={{width: `${TBL_BAL_TO_TOT_WIDTH}%`}}>Bal/Tot</th>
                          <th className="table-row-heading" style={{width: `${TBL_BILLQTY_WIDTH}%`}}>Bill Qty</th>
                          <th className="table-row-heading" style={{width: `${TBL_UNIT_PRICE_WIDTH}%`}}>Rate</th>
                          <th className="table-row-heading" style={{width: `${TBL_PRODUCT_AMT_WIDTH}%`}}>Prod Amt</th>
                          { parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ?
                          <>
                          <th className="table-row-heading" style={{width: `${TBL_CGST_AMT_WIDTH}%`}}>CGST</th>
                          <th className="table-row-heading" style={{width: `${TBL_SGST_AMT_WIDTH}%`}}>SGST</th>
                          </>
                          :
                          <th className="table-row-heading" style={{width: `${TBL_IGST_AMT_WIDTH}%`}}>IGST</th>
                          }
                          <th className="table-row-heading" style={{width: `${TBL_TOTAL_AMT_WIDTH}%`}}>Tot Amt</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((item, idx) => (
                          <tr>
                            <td style={{width: `${TBL_CHECKBOX_WIDTH}%`}}>
                            <Form.Check
                            style={{paddingTop: "5px"}}
                            name={`dc_item_check-${idx}`}
                            id={`dc_item_check-${idx}`}
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={item.dc_item_check}
                            onChange={handleCheckBox}
                          ></Form.Check>
                            </td>
                            <td style={{width: `${TBL_SNO_WIDTH}%`}}>{idx + 1}</td>
                            {/*
                            <td style={{width: '25%'}}>

                              <Row>
                                <div style={{width: '75%'}}>
                                  <Select
                                    id={`product-item-${idx}`}
                                    name={`product-item-${idx}`}
                                    //value={options.value}
                                    value={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    options={productMaster}
                                    defaultValue={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    //isDisabled={isReadOnly}
                                    onChange={handleProductSelect}
                                  />
                              </div>
                               
                                
                                <div style={{width: '25%'}}>
                                  <Button
                                    variant="primary"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleCompositeEdit}
                                    name={`edit-btn-order-name-${idx}`}
                                    id={`edit-btn-order-id-${idx}`}
                                    //disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}
                                  disabled={isReadOnly ? true : false}

                                  >
                                    
                                    {item.editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                                    
                                    
                                      <BsPencilSquare
                                        key={`edit-btn-order-key`}
                                        size={12}
                                        style={{ cursor: "pointer" }}
                                      />
                                  </Button> 
                                  </div>
                                   
                                                         
                                
                              </Row>
                              
                            </td>
                          */}
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "right",
                                width: `${TBL_DCDATE_WIDTH}%`
                              }}
                            >
                              {item.dc_date}
                            </td>
                            <td
                              style={{
                                width: `${TBL_DCNO_WIDTH}%`,
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.dc_no}
                            </td>

                            <td
                              style={{
                                padding: "5px",
                                width: `${TBL_PRODUCT_NAME_WIDTH}%`
                              
                              }}
                            >
                              {item.item_name}
                            </td>

                            <td
                              style={{
                                width: `${TBL_BAL_TO_TOT_WIDTH}%`,
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                               {item.qty_unbilled} / {item.qty}
                            </td>
                            <td style={{width: `${TBL_BILLQTY_WIDTH}%`}}>
                            <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Form.Control
                                type="text"
                                name={`billable_quantity-${idx}`}
                                placeholder="Bill Qty"
                                value={item.billable_quantity}
                                // max="100000"
                                onChange={handleChange}
                                //readOnly={isReadOnly}
                                //maxLength={MAX_AMOUNT_LENGTH}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                              />
                            </Col>
                          </Form.Group>
                          <p className="error-msg">{item.billable_quantity_err}</p>
                              
                            </td>
                            {/*
                            <td>
                              <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12} style={{width : "200px"}}>
                              <Form.Control
                                type="text"
                                name={`quantity-${idx}`}
                                placeholder="Quantity"
                                value={item.quantity}
                                // max="100000"
                                onChange={handleChange}
                                //readOnly={isReadOnly}
                                maxLength={MAX_QTY_LENGTH}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray",}}
                              />
                            </Col>
                            <p className="error-msg">{item.error_qty}</p>
                          </Form.Group>
                           
                            </td>
                            */}
                          
                            <td style={{width: `${TBL_UNIT_PRICE_WIDTH}%`}}>
                            
                            <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Form.Control
                                type="text"
                                name={`jw_unit_price-${idx}`}
                                placeholder="Rate"
                                value={item.jw_unit_price}
                                // max="100000"
                                onChange={handleChange}
                                //readOnly={isReadOnly}
                                maxLength={MAX_AMOUNT_LENGTH}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                              />
                            </Col>
                          </Form.Group>
                          <p className="error-msg">{item.unit_price_err}</p>
                          
                            </td>
                          
                              
                            <td
                              style={{
                                width: `${TBL_PRODUCT_AMT_WIDTH}%`,
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.net_amt).toFixed(2)))}
                            
                              
                            </td>
                            
                            { parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ?
                            <>
                            <td
                              style={{
                                width: `${TBL_CGST_AMT_WIDTH}%`,
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                          {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.cgst_amt).toFixed(2)))}
                            </td>
                            
                            <td
                              style={{
                                width: `${TBL_SGST_AMT_WIDTH}%`,
                                padding: "5px",
                                textAlign: "right",
                                
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.sgst_amt).toFixed(2)))}
                            </td>
                            </>
                            :
                            <>
                             <td
                              style={{
                                width: `${TBL_IGST_AMT_WIDTH}%`,
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.igst_amt).toFixed(2)))}
                            </td>
                            </>
                            }
                            
                            <td style={{ width: `${TBL_TOTAL_AMT_WIDTH}%`, padding: "5px", textAlign: "right"}}>
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_amt).toFixed(2)))}
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="ui-divider"></div>
                    
                <div className="ui-form"></div>

                <Row className="show-grid">
                
                  <Col xs={6} sm={6} md={6} lg={6}>
                  
                    <Row>
                    <Col xs={12} md={12} sm={12}>
                        <div style={{paddingBottom: "40px"}}>
                          <span style={{ fontWeight: "bold" }}>
                            Amount Chargeable (in Words) Indian Rupees :
                          </span>
                          <span> {sum_product_total > 0.01 ? convertNumberToWords(Math.round(sum_product_total)) : ""}</span>
                        </div>
                      </Col>        
                    </Row>
                    <p></p>
                    <Row>
                  <Col xs={5} md={5} sm={5} lg={5}>
                  <Form.Group className="mb-3">
                  <Form.Label>Bank Account</Form.Label>
                  <Select
                    inline
                    id="bank_account"
                    name="bank_account"
                    value={{
                      value: bankAccountIdSelect.value,
                      label: bankAccountIdSelect.label,
                    }}
                    options={optBankAccount}
                    defaultValue={{
                      value: bankAccountIdSelect.value,
                      label: bankAccountIdSelect.label,
                    }}
                    onChange={handleBankAccountSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Form.Group>
                  
              </Col>
                    </Row>
                    <div className="ui-divider"></div> 
                  </Col>

                  

                  <Col col-12 col-sm-6 xs={6} sm={6} md={6} lg={6} style={{alignContent: 'float-end', align: 'right',paddingTop: "35px"}} className="order-last order-sm-last justify-content-end float-end">
                  <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Sub Total :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cost_ref.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>

                    { parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectBill.gst_code) ?
                    <>
                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Total CGST :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cgst_ref.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                   
                   <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Total SGST :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_sgst_ref.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                    </>
                    :
                    <>
                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                      Total IGST :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                         {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_igst_ref.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                    </>
                  }
                    
                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Total Amount:
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                      
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_total_ref.current).toFixed(2)))}
                        
                      </Form.Label>
                       </Col>
                    
                    </Row>
                     {/*
                    <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">
                      Total Amount
                      </Form.Label>
                      <Col sm="6">
                       <Form.Control
                       type="text"
                       disabled="true"
                       placeholder="160000"
                       value={Math.round((sum_product_total)).toFixed(2)}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row>
                    */}
                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Round Off :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(Math.round(sum_product_total_ref.current) - sum_product_total_ref.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                    {/*
                    <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">
                     Round Off
                      </Form.Label>
                      <Col sm="6">
                       <Form.Control
                       type="text"
                       disabled="true"
                       placeholder="1.60"
                       value={sum_rounded_off}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row>
                    */}
                   

                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Net Amount :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        {/*{Math.round(sum_product_total).toFixed(2)}*/}
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(Math.round(sum_product_total_ref.current).toFixed(2)))}
                        
                      </Form.Label>
                       </Col>
                    
                     </Row>              
                 </Col>
                </Row>

                <Row>
              

              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes / Remarks</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="notes"
                    id="notes"
                    placeholder="Notes / Remarks"
                    value={formValues.notes}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>T & C / Declaration</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="declaration"
                    id="declaration"
                    placeholder="T & C / Declaration"
                    value={formValues.declaration}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
                </Row>
                    
                  </>
                }
                

                {!isReadOnly && (
                <Row>
                   <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-receipt-id"
                    name="btn-clear-receipt-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn btn-primary"
                    id="save-print"
                    name="save-print"
                    onClick={(e) => handleSubmit(e, "save-print")}
                  >
                    {submitPrintAction &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    Save & Print</Button>
                  &nbsp;
                  &nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={(e) => handleSubmit(e, 'save-only')}>
                  { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                    
                    Save</button>
                </div>
              </Col>        
            </Row>
                )}
               
              </div>
              </>
              }
              </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
          </Container>
        
      {/* 
      <Modal show={showConfigDialog}>
        <Modal.Header>
          <Modal.Title>Additional Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            
                
                <Col xs={12} md={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  
                  <Form.Control
                    //type="text"
                    as="textarea" rows={3}
                    name="addl_notes"
                    id="addl_notes"
                    placeholder=" Notes"
                    value={formValues.addl_notes}
                    maxLength={75}
                    onChange={handleOnItemChangeComposition}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
              
              
            {/*
            <Table
              striped bordered hover
              size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">S.No</th>
                  <th className="table-row-heading">Product</th>
                  <th className="table-row-heading">Qty</th>
                  <th className="table-row-heading">Uom</th>
                  {/ *<th className="table-row-heading">...</th>* /}
                </tr>
              </thead>

              <tbody>
                {/ *{productCompositionList.map((item, idx) => (* /}
                {(selectedCompositionId > -1 && 'composition' in items[selectedCompositionId]) &&
                items[selectedCompositionId].composition.map((item, idx) => (
                  <tr key={`prod-composition-table-${idx}`}>
                    <td className="w-25">{idx + 1}</td>
                    <td className="w-50">
                      <Select
                        id={`composite-product-item-${idx}`}
                        name={`composite-product-item-${idx}`}
                        //value={options.value}
                        value={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        options={productList}
                        defaultValue={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        //isDisabled={(disableComposite || isReadOnly)? true: false}
                        isDisabled={true}
                        onChange={(e, item) => handleProductSelectComposition(e, item, idx)}
                      />
                      <p className="error-msg">{item.error}</p>
                    </td>
                    <td className="w-25">
                      <Form.Group className="mb-3">
                        <Form.Control
                          //pattern="^[0-9]*\.[0-9]{2}$"
                          //step=".01"
                          type="text"
                          size="sm"
                          id={`quantity-${idx}`}
                          name={`quantity-${idx}`}
                          placeholder="Quantity"
                          onChange={(e) => handleOnItemChangeComposition(e)}
                          readOnly={isReadOnly}
                          value={item.quantity}
                          //disabled={(disableComposite || isReadOnly)? true: false}
                          //defaultValue={item.quantity}

                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}

                        />
                      </Form.Group>
                    </td>
                    <td>{item.uom}</td>
                    {/ *
                    <td>
                      <BsFillXCircleFill
                        size={28}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(e) => handleItemDeleteComposition(e)}
                        name={`item-delete-icon-${idx}`}
                        id={`item-delete-icon-${idx}`}
                      />
                    </td>
                    * /}
                  </tr>
                ))
                }
              </tbody>
            </Table>
              * /}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseComposition}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveComposition}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
              */}

      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
        { alertPurpose !== 'success' &&
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
        }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateCustBillMfgT1;
