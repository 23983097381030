import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image
} from "react-bootstrap";
//import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config";
import spinner_logo from '../assets/images/spinner_logo.png';


function QuickBoard() {
  const initialized = useRef(false);
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  var navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
  /*
  let bankArr = [
    {
      bank_name: "Canara Bank",
      account_number: "900087654321",
      branch: "Avinashi",
      ifsc_code: "CNRB0001244",
      active: false,
      
    },
    {
      bank_name: "Indian Overseas Bank",
      account_number: "8900765432126",
      branch: "Tiruppur",
      ifsc_code: "IOBA0000095",
      active: false,
    },
    {
      bank_name: "Axis Bank",
      account_number: "7899006543212",
      branch: "Coimbatore",
      ifsc_code: "UTIB0000090",
      active: false,
    },
  ];
  */
 

  const [checked, setChecked] = useState(false);

  const [bankList, setBankList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [selectedId, setSelectedId] = useState(-1);

  const [showDialog, setShowDialog] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [placeIdSelect, setPlaceIdSelect] = useState({});
  const [optPlace, setOptPlace] = useState([]);
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };




 
  

  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::ListBankAccounts called ##########################");
      setToRender(true);
      //getBankAccountList();
      /*
      getCustomerList();
      initialized.current = true;
      ( async() => {
        var [retState, customerArr, placeListArrRet] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);

          setOptPlace(placeListArrRet);
          setPlaceIdSelect(placeListArrRet[0]);
        }
      })();
      */
    }
  }, []);

  
  

 

  

  

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
    //getBranchList();
  }

  const handleInvoice = async (e) => {
    e.preventDefault();
    console.log("handle Invoice called : ", e);
    console.log("Handle Invoice clicked current target ID: ", e.currentTarget.id);
    navigate("/listInvoices");
  };

  const handleDeliveryNote = async (e) => {
    e.preventDefault();
    console.log("handle Delivery Note called : ", e);
    console.log("Handle Delivery Note clicked current target ID: ", e.currentTarget.id);
    navigate("/listDeliveryNotes");
  };

  const handleBill = async (e) => {
    e.preventDefault();
    console.log("handle Bill called : ", e);
    console.log("Handle Bill clicked current target ID: ", e.currentTarget.id);
    navigate("/listCustomerBills");
  };

  const handleReceipt = async (e) => {
    e.preventDefault();
    console.log("handle Receipt called : ", e);
    console.log("Handle Receipt clicked current target ID: ", e.currentTarget.id);
    navigate("/listCustReceipts");
  };

  const handleSuppInvoice = async (e) => {
    e.preventDefault();
    console.log("handle SuppInvoice called : ", e);
    console.log("Handle SuppInvoice clicked current target ID: ", e.currentTarget.id);
    navigate("/listSuppInvoices");
  };

  const handleSuppPayment = async (e) => {
    e.preventDefault();
    console.log("handle Supplier Payment called : ", e);
    console.log("Handle Supplier Payment clicked current target ID: ", e.currentTarget.id);
    navigate("/listSuppPayments");
  };

  const handleInwardDC = async (e) => {
    e.preventDefault();
    console.log("handle InwardDC called : ", e);
    console.log("Handle InwardDC clicked current target ID: ", e.currentTarget.id);
    navigate("/listInwardDC");
  };

  const handleCustOS = async (e) => {
    e.preventDefault();
    console.log("handle CustOS called : ", e);
    console.log("Handle CustOS clicked current target ID: ", e.currentTarget.id);
    navigate("/viewCustomerOS");
  };

  const handleSuppOS = async (e) => {
    e.preventDefault();
    console.log("handle SuppOS called : ", e);
    console.log("Handle SuppOS clicked current target ID: ", e.currentTarget.id);
    navigate("/viewSupplierOS");
  };
  const handleCustomerLedger = async (e) => {
    e.preventDefault();
    console.log("handle CustomerLedger called : ", e);
    console.log("Handle CustomerLedger clicked current target ID: ", e.currentTarget.id);
    navigate("/viewCustomerLedger");
  };
  const handleSupplierLedger = async (e) => {
    e.preventDefault();
    console.log("handle SupplierLedger called : ", e);
    console.log("Handle SupplierLedger clicked current target ID: ", e.currentTarget.id);
    navigate("/viewSupplierLedger");
  };
  const handleInOutDC = async (e) => {
    e.preventDefault();
    console.log("handle InoutDC called : ", e);
    console.log("Handle InoutDC clicked current target ID: ", e.currentTarget.id);
    navigate("/viewCustomerINOUTDC");
  };
  const handleItem = async (e) => {
    e.preventDefault();
    console.log("handle Item called : ", e);
    console.log("Handle Item clicked current target ID: ", e.currentTarget.id);
    navigate("/listItems");
  };

  const handleSupplier = async (e) => {
    e.preventDefault();
    console.log("handle Supplier called : ", e);
    console.log("Handle Supplier clicked current target ID: ", e.currentTarget.id);
    navigate("/listSuppliers");
  };

  const handleCustomer = async (e) => {
    e.preventDefault();
    console.log("handle Customer called : ", e);
    console.log("Handle Customer clicked current target ID: ", e.currentTarget.id);
    navigate("/listCustomers");
  };

  const handleBankAccount = async (e) => {
    e.preventDefault();
    console.log("handle Bank Account called : ", e);
    console.log("Handle Bank Account clicked current target ID: ", e.currentTarget.id);
    navigate("/listBankAccounts");
  };
  
 
  return (
    <Container>
      <SidebarMain sendBacktoParent={sendDataToParent} />
        { toRender ?
        <form>
          <Row>
              <Col xs={9} md={9} sm={9} lg={9} > 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Quick Board</span>
           
             </Col>
             
          </Row>
          <hr align="center" />
          <Col style={{paddingBottom: "25px"}}></Col>
          <Row>
                <Col  xs={6} md={6} sm={6} lg={6} style={{paddingBottom: "15px"}} >
                <div style={{paddingLeft: "15px"}}>
                  <Row style={{border:"0.5px solid", borderRight: "0.5px solid", borderTop: "0.5px solid", borderBottom: "0.5px solid", paddingBottom: "10px", borderColor: "#D3D3D3"}}>
                    <Col xs={12} md={12} sm={12} lg={12}>
                    <h5 >Sales</h5>
                    </Col>
                    {menuSettings.permissions.find(element => (element.code === 'listInvoices' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="invoice-btn-name"
                      id="invoice-btn-id"
                      onClick={(e) => handleInvoice(e)}
                      style={{width: "105%",height: "100%"}}
                      //disabled={submitAction ? true: false}
                    >  
                     Invoices
                    </Button>
                    </Col>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="dn-btn-name"
                      id="dn-btn-id"
                      onClick={(e) => handleDeliveryNote(e)}
                      style={{width: "105%",height: "100%"}}
                    >  
                     Delivery Notes
                    </Button>
                    </Col>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'listCustomerBills' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="bill-btn-name"
                      id="bill-btn-id"
                      onClick={(e) => handleBill(e)}
                      style={{width: "105%",height: "100%"}}
                    >  
                     Bills
                    </Button>
                    </Col>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'listCustReceipts' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3} style={{paddingLeft: "20px"}}>
                    <Button
                      name="receipt-btn-name"
                      id="receipt-btn-id"
                      style={{width: "105%",height: "100%"}}
                      onClick={(e) => handleReceipt(e)}
                    >  
                     Receipts
                    </Button>
                    </Col>
                    }
                    </Row>
                    </div>
                    </Col>
                   {/* <Col xs={1} md={1} sm={1} lg={1} style={{paddingLeft: "80px"}}></Col>*/}
                    

                    <Col xs={5} md={5} sm={5} lg={5} style={{paddingBottom: "15px"}}>
                    <div style={{paddingLeft: "30px"}}>
                     <Row style={{border:"0.5px solid", borderRight: "0.5px solid", borderTop: "0.5px solid", borderBottom: "0.5px solid", paddingBottom: "10px", borderColor: "#D3D3D3"}}>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <h5>Purchase</h5>
                    </Col>
                    {menuSettings.permissions.find(element => (element.code === 'listSuppInvoices' && element.access === true)) &&
                    <Col xs={4} md={4} sm={4} lg={4} style={{paddingRight: "10px"}}>
                    <Button
                      name="suppInv-btn-name"
                      id="suppInv-btn-id"
                      style={{width: "110%",height: "105%"}}
                      onClick={(e) => handleSuppInvoice(e)}
                    >  
                     Supplier Invoices
                    </Button>
                    </Col>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'listSuppPayments' && element.access === true)) &&
                    <Col xs={4} md={4} sm={4} lg={4} style={{paddingRight: "10px"}}>
                    <Button
                      name="suppPayment-btn-name"
                      id="suppPayment-btn-id"
                      style={{width: "110%",height: "105%"}}
                      onClick={(e) => handleSuppPayment(e)}
                    >  
                     Supplier Payments
                    </Button>
                    </Col>
                    }

                    {/*{menuSettings.permissions.find(element => (element.code === 'listInwardDC' && element.access === true)) &&*/}
                    <Col xs={4} md={4} sm={4} lg={4} style={{paddingRight: "5px"}}>
                    <Button
                      name="inwarddc-btn-name"
                      id="inwarddc-btn-id"
                      style={{width: "100%",height: "105%"}}
                      onClick={(e) => handleInwardDC(e)}
                    >  
                     Inward DC
                    </Button>
                    </Col>
                    
                    </Row>
                    </div>
                    </Col>
                    </Row>
                    <br></br>

                    <Col xs={12} md={12} sm={12} lg={12}>
                  <div style={{paddingRight: "105px", paddingLeft: "15px", paddingBottom: "15px"}} > 
                  <Row style={{border:"0.5px solid", borderRight: "0.5px solid", borderTop: "0.5px solid", borderBottom: "0.5px solid", paddingBottom: "10px", borderColor: "#D3D3D3"}}>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <h5>Reports</h5>
                    </Col>
                    {menuSettings.permissions.find(element => (element.code === 'viewCustomerOS' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3} style={{paddingRight: "35px"}}>
                    <Button
                      name="custos-btn-name"
                      id="custos-btn-id"
                      variant="success"
                      style={{width: "110%",height: "100%"}}
                      onClick={(e) => handleCustOS(e)}
                    >  
                     Customer Outstanding
                    </Button>
                    </Col>
                    }
                  
                    {menuSettings.permissions.find(element => (element.code === 'viewSupplierOS' && element.access === true)) &&
                    <Col xs={2} md={2} sm={2} lg={2} style={{paddingRight: "35px"}}>
                    <Button
                      style={{width: "120%",height: "100%"}}
                      name="suppos-btn-name"
                      id="suppos-btn-id"
                      variant="success"
                      onClick={(e) => handleSuppOS(e)}
                    >  
                     Supplier Outstanding
                    </Button>
                    </Col>
                    }
                    
                    {menuSettings.permissions.find(element => (element.code === 'viewCustomerLedger' && element.access === true)) &&
                    <Col xs={2} md={2} sm={2} lg={2}style={{paddingLeft: "5px"}}>
                    <Button
                      name="custledger-btn-name"
                      id="custledger-btn-name"
                      style={{width: "110%",height: "100%"}}
                      variant="success"
                      onClick={(e) => handleCustomerLedger(e)}
                    >  
                     Customer Ledger
                    </Button>
                    </Col>
                    }

                    {menuSettings.permissions.find(element => (element.code === 'viewSupplierLedger' && element.access === true)) &&
                    <Col xs={2} md={2} sm={2} lg={2} style={{paddingLeft: "25px"}}>
                    <Button
                      name="suppledger-btn-name"
                      id="suppledger-btn-name"
                      style={{width: "85%",height: "100%"}}
                      variant="success"
                      onClick={(e) => handleSupplierLedger(e)}
                    >  
                     Supplier Ledger
                    </Button>
                    </Col>
                    }                         
                    
                    
                {menuSettings.permissions.find(element => (element.code === 'viewCustomerINOUTDC' && element.access === true)) && 
                    
                    <Col xs={3} md={3} sm={3} lg={3} style={{paddingRight: "100px"}}>
                    <Button
                      name="inoutdc-btn-name"
                      id="inoutdc-btn-id"
                      variant="success"
                      style={{width: "135%",height: "100%"}}
                      onClick={(e) => handleInOutDC(e)}
                    >  
                     In Out DC Reconcilation
                    </Button>
                    </Col>
                    
                    }
                    </Row>
                    </div>
                    </Col>
                    <br></br>
                   

                 
                <Col xs={6} md={6} sm={6} lg={6} >
                <div style={{paddingLeft: "15px"}}>
                  <Row style={{border:"0.5px solid", borderRight: "0.5px solid", borderTop: "0.5px solid", borderBottom: "0.5px solid", paddingBottom: "10px", borderColor: "#D3D3D3"}} >
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <h5>Manage</h5>
                    </Col>
                    {menuSettings.permissions.find(element => (element.code === 'listItems' && element.access === true)) && 
                      
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="item-btn-name"
                      id="item-btn-id"
                      variant="danger"
                      onClick={(e) => handleItem(e)}
                      style={{width: "105%",height: "100%"}}
                    >  
                     Items
                    </Button>
                    </Col>
                    }

                 {menuSettings.permissions.find(element => (element.code === 'listCustomers' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="customer-btn-name"
                      id="customer-btn-id"
                      variant="danger"
                      onClick={(e) => handleCustomer(e)}
                      style={{width: "105%",height: "100%"}}
                    >  
                     Customers
                    </Button>
                    </Col>
                  }
                    {menuSettings.permissions.find(element => (element.code === 'listSuppliers' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="supplier-btn-name"
                      id="supplier-btn-id"
                      variant="danger"
                      onClick={(e) => handleSupplier(e)}
                      style={{width: "105%",height: "100%"}}
                    >  
                     Suppliers
                    </Button>
                    </Col>
                    }

                  {menuSettings.permissions.find(element => (element.code === 'listBankAccounts' && element.access === true)) &&
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Button
                      name="bankaccounts-btn-name"
                      id="bankaccounts-btn-id"
                      variant="danger"
                      onClick={(e) => handleBankAccount(e)}
                      style={{width: "105%",height: "100%"}}
                    >  
                     Bank Accounts
                    </Button>
                    </Col>
                    }
                    </Row>
                    </div>
                    </Col>
                   
                   
          

         
      </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       </div>
       </div>
      }  
      
     </Container>
   
  );
}
export default QuickBoard;
