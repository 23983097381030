
import React, { Fragment, useState } from 'react';
import { Alert } from 'react-bootstrap';
import logo_avn from './assets/images/logo_avs.png';

import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from "./pages/Auth";
import CsvGenerate from './pages/CsvGenerate';


import { client } from './config/Config';
import axios from 'axios';
import SplashScreen from './pages/SplashScreen';
import PrintInvoice from './pages/PrintInvoice'
import ListRcs from './pages/ListRcs';

import ListCustomers from './pages/ListCustomers';
import AddUpdateCustomer from './pages/AddUpdateCustomer';

import ListSuppliers from './pages/ListSuppliers';
import AddUpdateSupplier from './pages/AddUpdateSupplier';

import ListItems from './pages/ListItems';
import AddUpdateItem from './pages/AddUpdateItem';

import ListUnits from './pages/ListUnits';
import AddUpdateUnit from './pages/AddUpdateUnit';

import ListBankAccounts from './pages/ListBankAccounts';
import AddUpdateBankAccount from './pages/AddUpdateBankAccount';

import ListCustReceipts from './pages/ListCustReceipts';
import AddUpdateCustReceipt from './pages/AddUpdateCustReceipt';

import ListSuppPayments from './pages/ListSuppPayments';
import AddUpdateSuppPayment from './pages/AddUpdateSuppPayment';

import ListBusiness from './pages/ListBusiness';
import AddUpdateBusiness from './pages/AddUpdateBusiness';

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewCustomerOS from './pages/ViewCustomerOS';
import ViewSupplierOS from './pages/ViewSupplierOS';
import ViewTaxSummary from './pages/ViewTaxSummary';
import PrintDeliveryChallan from './pages/PrintDeliveryChallan';
import PrintBillPayment from './pages/PrintBillPayment';
import ListInvoices from './pages/ListInvoices';
import AddUpdateInvoiceMfgT1 from './pages/AddUpdateInvoiceMfgT1';
import ListCustomerBills from './pages/ListCustomerBills';

import AddUpdateCustBillMfgT1 from './pages/AddUpdateCustBillMfgT1';
import ListDeliveryNotes from './pages/ListDeliveryNotes';
//import AddUpdateDNMfgT1 from './pages/AddUpdateDNMfgT1_not_used';
import ListSuppInvoices from './pages/ListSuppInvoices';
import AddUpdateSuppInvoiceMfgT1 from './pages/AddUpdateSuppInvoiceMfgT1';

import ListBranches from './pages/ListBranches';
import AddUpdateBranch from './pages/AddUpdateBranch';

import PrivacyPolicy from './pages/PrivacyPolicy';
import MyProfile from './pages/MyProfile';


import PrintListInvoices from './pages/PrintListInvoices';
import PrintListDeliveryChallan from './pages/PrintListDeliveryChallan';
import PrintCustomerBills from './pages/PrintCustomerBills';
import PrintListCustReceipts from './pages/PrintListCustReceipts';
import PrintListSuppInvoices from './pages/PrintListSuppInvoices';
import PrintListSuppPayments from './pages/PrintListSuppPayments';
import ListDocSettings from './pages/ListDocSettings';
import AddUpdateDocSetting from './pages/AddUpdateDocSetting';
import PrintDNMfgT1 from './pages/PrintDNMfgT1';
import AddUpdateDeliveryMfgT1 from './pages/AddUpdateDeliveryMfgT1';
import PrintCustBillMfgT1 from './pages/PrintCustBillMfgT1';
import PrintCustReceipt from './pages/PrintCustReceipt';
import PrintCustomerOS from './pages/PrintCustomerOS';
import PrintTask from './pages/PrintTask';
import PrintSuppPayment from './pages/PrintSuppPayment';
import AddUpdateDeliveryMfgT2 from './pages/AddUpdateDeliveryMfgT2';
import PrintDNMfgT2 from './pages/PrintDNMfgT2';
import PrintSupplierOS from './pages/PrintSupplierOS';
import PrintTaxSummary from './pages/PrintTaxSummary';
import AddUpdateSupplierInvoiceMfgT1 from './pages/AddUpdateSupplierInvoiceMfgT1';
import ViewCustomerLedger from './pages/ViewCustomerLedger';
import PrintCustomerLedger from './pages/PrintCustomerLedger';
import ViewSupplierLedger from './pages/ViewSupplierLedger';
import PrintSupplierLedger from './pages/PrintSupplierLedger';
import ListInwardDC from './pages/ListInwardDC';
import AddUpdateInwardDCMfgT1 from './pages/AddUpdateInwardDCMfgT1';
import AddUpdateInwardDCMfgT2 from './pages/AddUpdateInwardDCMfgT2';
import PrintListInwardDC from './pages/PrintListInwardDC';
import ViewCustomerINOUTDC from './pages/ViewCustomerINOUTDC';
import ViewCustomerDCRecon from './pages/ViewCustomerDCRecon';
import PrintCustomerDCRecon from './pages/PrintCustomerDCRecon';
import QuickBoard from './pages/QuickBoard';
//import { useNavigate } from "react-router-dom";

function App() {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');

  //const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);


    let reqLoginPayload = {
      username: username.value,
      password: password.value
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var res = await axios.post(client.domain + '/login', reqLoginPayload, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.res_data);
        console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        console.log("Response message from server aI : ", res.data.detail.res_data.aI);

        localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setShowMsg(error.response.data.detail.res_data);
          setShowMsgTitle('LOGIN FAILED!');
          setShow(true);
        }
      }
    }

  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/printInvoice" element={<PrintInvoice />} />
        <Route path="/listRcs" element={< ListRcs />} />
        <Route path="/listCustomers" element={< ListCustomers />} />
        <Route path="/addUpdateCustomer" element={< AddUpdateCustomer />} />
        <Route path="/listSuppliers" element={< ListSuppliers />} />
        <Route path="/addUpdateSupplier" element={< AddUpdateSupplier />} />
        <Route path="/listItems" element={< ListItems />} />
        <Route path="/addUpdateItem" element={< AddUpdateItem />} />
        <Route path="/listUnits" element={< ListUnits />} />
        <Route path="/addUpdateUnit" element={< AddUpdateUnit />} />
        <Route path="/listBankAccounts" element={< ListBankAccounts />} />
        <Route path="/addUpdateBankAccount" element={< AddUpdateBankAccount />} />
        <Route path="/viewCustomerOS" element={< ViewCustomerOS />} />
        <Route path="/viewSupplierOS" element={< ViewSupplierOS />} />
        <Route path="/listCustReceipts" element={< ListCustReceipts />} />
        <Route path="/addUpdateCustReceipt" element={< AddUpdateCustReceipt />} />
        <Route path="/listSuppPayments" element={< ListSuppPayments />} />
        <Route path="/addUpdateSuppPayment" element={< AddUpdateSuppPayment />} />
        <Route path="/listBusiness" element={< ListBusiness />} />
        <Route path="/addUpdateBusiness" element={< AddUpdateBusiness />} />
        <Route path="/printDeliveryChallan" element={< PrintDeliveryChallan />} />
        <Route path="/printBillPayment" element={< PrintBillPayment />} />
        <Route path="/listInvoices" element={< ListInvoices />} />
        <Route path="/addUpdateInvoiceMfgT1" element={< AddUpdateInvoiceMfgT1 />} />
        <Route path="/listCustomerBills" element={< ListCustomerBills />} />
        <Route path="/addUpdateCustBillMfgT1" element={< AddUpdateCustBillMfgT1 />} />
        <Route path="/listDeliveryNotes" element={< ListDeliveryNotes />} />
        <Route path="/listSuppInvoices" element={< ListSuppInvoices />} />
        <Route path="/addUpdateSuppInvoiceMfgT1" element={< AddUpdateSuppInvoiceMfgT1 />} />
        <Route path="/listBranches" element={< ListBranches />} />
        <Route path="/addUpdateBranch" element={< AddUpdateBranch />} />
        <Route path="/viewTaxSummary" element={< ViewTaxSummary />} />
        <Route path="/privacyPolicy" element={< PrivacyPolicy />} />
        <Route path="/myProfile" element={< MyProfile />} />
        <Route path="/listDocSettings" element={< ListDocSettings />} />
        <Route path="/addUpdateDocSetting" element={< AddUpdateDocSetting />} />
        <Route path="/printListInvoices" element={< PrintListInvoices />} />
        <Route path="/printListDeliveryChallan" element={< PrintListDeliveryChallan />} />
        <Route path="/printCustomerBills" element={< PrintCustomerBills />} />
        <Route path="/printListCustReceipts" element={< PrintListCustReceipts />} />
        <Route path="/printListSuppInvoices" element={< PrintListSuppInvoices />} />
        <Route path="/printListSuppPayments" element={< PrintListSuppPayments />} />
        <Route path="/printDNMfgT1" element={< PrintDNMfgT1 />} />
        <Route path="/addUpdateDeliveryMfgT1" element={< AddUpdateDeliveryMfgT1 />} />
        <Route path="/printCustBillMfgT1" element={< PrintCustBillMfgT1 />} />
        <Route path="/printCustReceipt" element={< PrintCustReceipt />} />
        <Route path="/printCustomerOS" element={< PrintCustomerOS />} />
        <Route path="/printTask" element={< PrintTask />} />
        <Route path="/printSuppPayment" element={< PrintSuppPayment />} />
        <Route path="/addUpdateDeliveryMfgT2" element={< AddUpdateDeliveryMfgT2 />} />
        <Route path="/printDNMfgT2" element={< PrintDNMfgT2 />} />
        <Route path="/printSupplierOS" element={< PrintSupplierOS />} />
        <Route path="/printTaxSummary" element={< PrintTaxSummary />} />
        <Route path="/addUpdateSupplierInvoiceMfgT1" element={< AddUpdateSupplierInvoiceMfgT1 />} />
        <Route path="/viewCustomerLedger" element={< ViewCustomerLedger />} />
        <Route path="/printCustomerLedger" element={< PrintCustomerLedger />} />
        <Route path="/viewSupplierLedger" element={< ViewSupplierLedger />} />
        <Route path="/printSupplierLedger" element={< PrintSupplierLedger />} />
        <Route path="/listInwardDC" element={<ListInwardDC />} />
        <Route path="/addUpdateInwardDCMfgT1" element={<AddUpdateInwardDCMfgT1 />} />
        <Route path="/addUpdateInwardDCMfgT2" element={<AddUpdateInwardDCMfgT2 />} />
        <Route path="/printListInwardDC" element={<PrintListInwardDC />} />
        <Route path="/viewCustomerINOUTDC" element={<ViewCustomerINOUTDC />} />
        <Route path="/viewCustomerDCRecon" element={<ViewCustomerDCRecon />} />
        <Route path="/printCustomerDCRecon" element={<PrintCustomerDCRecon />} />
        <Route path="/quickBoard" element={<QuickBoard />} />

      </Routes>
    </HashRouter>
  );
}
export default App;
