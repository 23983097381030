import { React, Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal, LS_MSG_TAG, MAX_AMOUNT_LENGTH } from "../config/Config";
import axios from "axios";



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
import { getCustomerMessages } from "./utils";




//function AddUpdateCustomer () {
const AddUpdateItem = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  let initialItems = [];
  const intialValues = {
    name: "",
    description: "",
    unit: "",
    hsn_code: "",
    hsn_description: "",
    cgst_per: 0.00,
    sgst_per: 0.00,
    igst_per: 0.00,
    unit_price: 0.00,
    cess_per: 0.00,
    yield_per: 0.00,
    jw_hsn_code: "",
    jw_hsn_description: "",
    jw_cgst_per: 0.00,
    jw_sgst_per: 0.00,
    jw_igst_per: 0.00,
    jw_unit_price: 0.00,
    jw_cess_per: 0.00,
    jw_yield_per: 0.00,
    
  };

 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [checkState, setCheckState] =  useState(true);
  const [refresh, setRefresh] =  useState(false);
  const [itemTypeIdSelect, setItemTypeIdSelect] = useState({});
  const [optItemType, setOptItemType] = useState([]);
  const [tradeTypeIdSelect, setTradeTypeIdSelect] = useState({});
  const [optTradeType, setOptTradeType] = useState([]);
  const [unitIdSelect, setUnitIdSelect] = useState({});
  const [optUnit, setOptUnit] = useState([]);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertPurpose, setAlertPurpose] = useState({});
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save");

  

  var validateErr = false;

  

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    

    if (name === "unit_price" || name === "cgst_per" || name === "sgst_per" || name === "igst_per" || name === "cess_per" || name === "yield_per" || name === "jw_yield_per" || name === "jw_unit_price" || name === "jw_cgst_per" || name === "jw_sgst_per" || name === "jw_igst_per" || name === "jw_cess_per")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        var igst_perc_temp = 0.00;
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        if ( name === 'cgst_per') {
          //var igst_per_tmp = 0.00;
          console.log("CGST CONVERSION at cgst check : ", parseFloat(value));
          console.log("SGST CONVERSION at cgst check : ", parseFloat(formValues["sgst_per"]));
          if ( !isNaN(parseFloat(value)) && !isNaN(parseFloat(formValues["sgst_per"])) ) {
            //setFormValues({ ...formValues, igst_per: (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2)});
            igst_perc_temp = (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2);
          }
          else if( isNaN(parseFloat(value)) && !isNaN(parseFloat(formValues["sgst_per"])) ) {
            //setFormValues({ ...formValues, igst_per: (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2)});
            igst_perc_temp = parseFloat(formValues["sgst_per"]);
          }
          else if( !isNaN(parseFloat(value)) && isNaN(parseFloat(formValues["sgst_per"])) ) {
            //setFormValues({ ...formValues, igst_per: (parseFloat(value) + parseFloat(formValues["sgst_per"])).toFixed(2)});
            igst_perc_temp = parseFloat(value);
          }
          else {
            console.log("ELSE CALLED at cgst check ?????????????????????????");
            //setFormValues({ ...formValues, igst_per: 0.00});
            igst_perc_temp = 0.00;
          }
        }
        else if ( name === 'sgst_per' ) {
          console.log("CGST CONVERSION at sgst check : ", parseFloat(formValues["cgst_per"]));
          console.log("SGST CONVERSION at sgst check : ", parseFloat(value));
          if ( !isNaN(parseFloat(formValues["cgst_per"])) && !isNaN(parseFloat(value)) ) {
            console.log("<<<<<<<<<<<<<<<<< IGST VALUE UPDATED >>>>>>>>>>>>>>>>>", (parseFloat(formValues["cgst_per"]) + parseFloat(value)).toFixed(2));
            //setFormValues({ ...formValues, igst_per: (parseFloat(formValues["cgst_per"]) + parseFloat(value)).toFixed(2)});
            igst_perc_temp = (parseFloat(formValues["cgst_per"]) + parseFloat(value)).toFixed(2);
          }
          else if ( !isNaN(parseFloat(formValues["cgst_per"])) && isNaN(parseFloat(value)) ) {
            igst_perc_temp = parseFloat(formValues["cgst_per"]);
          }
          else if ( isNaN(parseFloat(formValues["cgst_per"])) && !isNaN(parseFloat(value)) ) {
            igst_perc_temp = parseFloat(value);
          }
          else {
            console.log("ELSE CALLED at sgst check ?????????????????????????");
            //setFormValues({ ...formValues, igst_per: 0.00});
            igst_perc_temp = 0.00;
          }
        } 
        setFormValues({ ...formValues, [name]: e.currentTarget.value, igst_per: igst_perc_temp }); 
      }
      
    }   
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_ITEM_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }

    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");
    event.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    //setIsSubmit(true);

    console.log("FORM VALUES at handleSubmit: ", formValues);
      validateErr = false;
      setFormErrors(validate(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        return -1;
      }

    console.log("FORM VALUES at handleSubmit: ", formValues);
    var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/bms/addItem";
      } else if (state.action === "view") {
        apiToCall = "/bms/updateItem";
      } else {
        apiToCall = "/bms/addItem";
      }
      
      var reqParam = {
        bid: currSelBusinessRef.current,
        type_id:itemTypeIdSelect.value,
        tt_id: tradeTypeIdSelect.value,
        name: formValues["name"],
        description: formValues["description"] || "",
        unit_id: unitIdSelect.value,
        hsn_code: formValues["hsn_code"] || "",
        hsn_desc: formValues["hsn_desc"] || "",
        unit_price: formValues["unit_price"] || 0.00,
        cgst_per: formValues["cgst_per"] || 0.00,
        sgst_per: formValues["sgst_per"] || 0.00,
        igst_per: formValues["igst_per"] || 0.00,
        cess_per: formValues["cess_per"] || 0.00,  
        yield_per: formValues["yield_per"] || 0.00,
        jw_hsn_code: formValues["jw_hsn_code"] || "",
        jw_hsn_desc: formValues["jw_hsn_desc"] || "",
        jw_unit_price: formValues["jw_unit_price"] || 0.00,
        jw_cgst_per: formValues["jw_cgst_per"] || 0.00,
        jw_sgst_per: formValues["jw_sgst_per"] || 0.00,
        jw_igst_per: formValues["jw_igst_per"] || 0.00,
        jw_cess_per: formValues["jw_cess_per"] || 0.00,  
        jw_yield_per: formValues["jw_yield_per"] || 0.00
      };

    if ( state.action === "edit" || state.action === "view") {
      reqParam.id = formValues["id"];
      reqParam.is_active = checkState;
    } 
    
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-add') {
         setSubmitAddAction(true);
         submitActionRef.current = "save-add";
       }
       else {
         setSubmitAction(true);
         submitActionRef.current = "save";
       }
       var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
       //var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.data);
         console.log("ACTION VALUE PASSED++++++++++++++++++++++++++-------------: ", actionVal);
         if ( actionVal === 'save-add' ) {
           setSubmitAddAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertTitle("Success");
         //setShowAlert(true);
         setSubmitAction(false);
         
         if(state.action === "add"){ 
           setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_ITEM_SAVED"));
         } 
        else {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_ITEM_UPDATED"));
         }
         //setShowAlert(true);
 
         if ( actionVal === 'save-add' ) {
          console.log("++++++++++++++++++++++++ SAVE AND PRINT ACTION BUTTON CICKED ...");
          //setShowAlert(true);
           //navigate(0);
           setShowAlert(true);
           //navigate(0);
         }
         else {
           setShowAlert(true);
         }  
       }
       else {
        setAlertTitle("FAILED!");
         setShowAlert(true);
         setSubmitAction(false);
         
         if(state.action === 'add'){    
           setAlertBody("Item Save Failed!");
         }
         else{
           setAlertBody("Item Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       setSubmitAction(false);
     }
  };
  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
     
    }
  };
  */
    

  
  
  
 
  

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
 
  const CallItemData = async () => {
    var retStateLoad = false;
    var itemTypeRet = [];
    var tradeTypeRet = [];
    var unitRet = [];

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}

      var reqParams = {
        bid: currSelBusinessRef.current
      };

      var res = await axios.post(client.domain + "/bms/getItemPreLoadData", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data.item_types);
        setOptItemType(res.data.detail.data.item_types);
        //setItemTypeIdSelect(res.data.detail.data.item_types[0]);
        setOptTradeType(res.data.detail.data.trade_types);
        console.log("Response message from server TRADE TYPE : ", res.data.detail.data.trade_types);
        //setTradeTypeIdSelect(res.data.detail.data.trade_types[0]);
        setOptUnit(res.data.detail.data.units);
        //setUnitIdSelect(res.data.detail.data.units[0]);
        
        setToRender(true);
        
        itemTypeRet = res.data.detail.data.item_types;
        tradeTypeRet = res.data.detail.data.trade_types;
        unitRet = res.data.detail.data.units;
        retStateLoad = true;
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, itemTypeRet, tradeTypeRet, unitRet];
  };

  const callItemDetById = async(itemIdPassed) => {
    var retState = false;
    var itemInfoRet = {};
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //var apiToCall = "";

    var reqParams = {
      id: itemIdPassed
    };

    var res = await axios.post(client.domain + "/bms/getItemDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);
        setFormValues(res.data.detail.data[0]);
        itemInfoRet = res.data.detail.data[0];
        setSubmitAction(false);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
    return [retState, itemInfoRet];    
  };
  
  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateItem called ##########################");
      ( async() =>{
      //setToRender(true);
      var [retStateLoad, itemTypeRet, tradeTypeRet, unitRet] = await CallItemData();
      
      if ( state.action === 'edit' || state.action === 'view' ) {
        setIsReadOnly(true);
        var [retState, itemInfoRet] = await callItemDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED TRADE INFO : ", itemInfoRet);
          if ( retStateLoad ) {
            console.log("RETURNED TRADE TYPE : ", tradeTypeRet);
            var findTrade = tradeTypeRet.find(element => element.value === itemInfoRet.tt_id);
            if (findTrade !== undefined) {
              setTradeTypeIdSelect(findTrade);  
            }
          }
        }
      }
      else {
        setTradeTypeIdSelect(tradeTypeRet[1]);
      }

      if ( state.action === 'edit' || state.action === 'view' ) {
        var [retState, itemInfoRet] = await callItemDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED ITEM INFO : ", itemInfoRet);
          if ( retStateLoad ) {
            console.log("RETURNED ITEM TYPE : ", itemTypeRet);
            var findItem = itemTypeRet.find(element => element.value === itemInfoRet.type_id);
            if (findItem !== undefined) {
              setItemTypeIdSelect(findItem);  
            }
          }
        }
      }
      else {
        setItemTypeIdSelect(itemTypeRet[0]);
      }

      if ( state.action === 'edit' || state.action === 'view' ) {
        var [retState, itemInfoRet] = await callItemDetById(state.passed_element.id);
        if ( retState ) {
          console.log("RETURNED UNIT INFO : ", itemInfoRet);
          if ( retStateLoad ) {
            console.log("RETURNED UNIT : ", unitRet);
            var findUnit = unitRet.find(element => element.value === itemInfoRet.unit);
            if (findUnit !== undefined) {
              setUnitIdSelect(findUnit);  
            }
          }
        }
      }
      else {
        setUnitIdSelect(unitRet[0]);
      }

      if (state === null) {
        navigate("/listItems");
      }
     
      else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
           
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        else if ( state.action === 'view' || state.action === 'edit') {
          // call api to get business info
          callItemDetById(state.passed_element.id);
        }
        else {
          setFormValues(state.passed_element);
        }
        
         //setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
    
    })();
      initialized.current = true;
    }
  }, []);

 /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/bms/addItem";
      } else if (state.action === "view") {
        apiToCall = "/bms/updateItem";
      } else {
        apiToCall = "/bms/addItem";
      }
      
      var reqParam = {
        bid: currSelBusinessRef.current,
        type_id:itemTypeIdSelect.value,
        tt_id: tradeTypeIdSelect.value,
        name: formValues["name"],
        description: formValues["description"] || "",
        unit_id: unitIdSelect.value,
        hsn_code: formValues["hsn_code"] || "",
        hsn_desc: formValues["hsn_desc"] || "",
        unit_price: formValues["unit_price"] || 0.00,
        cgst_per: formValues["cgst_per"] || 0.00,
        sgst_per: formValues["sgst_per"] || 0.00,
        igst_per: formValues["igst_per"] || 0.00,
        cess_per: formValues["cess_per"] || 0.00,
        
        
      };

      if ( state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        reqParam.is_active = checkState;
      }
      
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        if (state.action === "add"){
        setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_ITEM_SAVED"));
        }
        else{
          setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_ITEM_UPDATED"))
        }
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  */
  
  const handlePostSaveOk = (e) => {
    console.log("user confirmed to customer added ...");
    //navigate(0);
    setShowAlert(false);
    if( submitActionRef.current === 'save-add'){
      navigate(0);
      //navigate('/listItems')
      console.log("SaveOk action called for dialog confirm **************************");
    }
    else{
      navigate('/listItems')
    }
    
    
  };
  /*
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     // callUpdateApi();
     
    }
  };
  */

 
  console.log("formvalues FULL : ", formValues);

  const handleTradeRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
    setTradeTypeIdSelect({value: currentRowName, label: e.currentTarget.label})


  };


  

  /*

  const handleItemRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({ ...formValues, ["item_type"]: currentRowName.toUpperCase() });



  };
  */

  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    setCheckState(e.target.checked);

    
    if ( state.action === 'view' || state.action === 'edit'){
      setCheckState(e.target.checked);
    }

    setRefresh(true);
  };

  const handleItemType = async(e) => {
    console.log("handleItemType called ...", e);
    setItemTypeIdSelect(e);
    
  };

  const handleUnit = async(e) => {
    console.log("handleUnit called ...", e);
    setUnitIdSelect(e);
    
  };

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }


 

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listItems' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listItems')).page_title_addupd : "Item"}</span>
            {/*
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
          */}
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-item-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-item-name`}
                    id={`edit-icon-item-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listItems' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-item-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-item-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
            <Row>

            <Col xs={3} md={3} sm={3} lg={3}>
                {/*<div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>*/}
                <Form.Label>Trade Type</Form.Label>
                  <Form.Group>
                  
                  {optTradeType.map((tradeInd, idx) => (
                    <>                  
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label={tradeInd.label}
                      name="trade_type"
                      type={"radio"}
                      id={`inline-radio-${tradeInd.value}`}
                      onChange={(e) => handleTradeRadioChange(e)}
                      //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                      checked={tradeTypeIdSelect.value === tradeInd.value ? true : false}
                    />
                    </>
                    ))}
                  </Form.Group>
                {/*</div>*/}
              </Col>
              {/*
            <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                    <Form.Label>Trade Type &nbsp;&nbsp;</Form.Label>
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Buy"
                      name="trade_type"
                      type={"radio"}
                      id={`inline-radio-buy`}
                      onChange={(e) => handleTradeRadioChange(e)}
                      checked={formValues["trade_type"] === 'BUY' ? true : false}
                    />
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Sell"
                      name="trade_type"
                      type={"radio"}
                      id={`inline-radio-sell`}
                      onChange={(e) => handleTradeRadioChange(e)}
                      checked={formValues["trade_type"] === 'SELL' ? true : false}
                    />
                  </Form.Group>
                </div>
              </Col>
                  */}
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Item Type</Form.Label>
                  <Select
                    id="item_type"
                    name="item_type"
                    value={{
                      value: itemTypeIdSelect.value,
                      label: itemTypeIdSelect.label,
                    }}
                    defaultValue={{
                      value: itemTypeIdSelect.value,
                      label: itemTypeIdSelect.label
                    }}
                    options={optItemType}
                    onChange={handleItemType}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.item_type}</p>
              </Col>
              
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                    <Form.Label>Items Type &nbsp;&nbsp;</Form.Label>
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Goods"
                      name="item_type"
                      type={"radio"}
                      id={`inline-radio-goods`}
                      onChange={(e) => handleItemRadioChange(e)}
                      checked={formValues["item_type"] === 'GOODS' ? true : false}
                    />
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Service"
                      name="item_type"
                      type={"radio"}
                      id={`inline-radio-service`}
                      onChange={(e) => handleItemRadioChange(e)}
                      checked={formValues["item_type"] === 'SERVICE' ? true : false}
                    />
                  </Form.Group>
                </div>
              </Col>
              */}

            </Row>
           

            <Row>
              <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name-id"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
             
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Description
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["description"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.description}</p>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Unit</Form.Label>
                  <Select
                    id="unit"
                    name="unit"
                    value={{
                      value: unitIdSelect.value,
                      label: unitIdSelect.label,
                    }}
                    defaultValue={{
                      value: unitIdSelect.value,
                      label: unitIdSelect.label
                    }}
                    options={optUnit}
                    onChange={handleUnit}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.unit}</p>
              </Col>
              </Row>
              <Row>
              <h5>
            <Badge pill bg="secondary">
              {tradeTypeIdSelect.value === 'PURCHASE' ? 
                  "Purchase Details"
                  :
                  "Sales Details"
              }
              </Badge>
            </h5>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>HSN Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="hsn_code"
                    name="hsn_code"
                    placeholder="HSN Code"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["hsn_code"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.hsn_code}</p>
              </Col> 

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>HSN Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="hsn_desc"
                    name="hsn_desc"
                    placeholder="HSN Description"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["hsn_desc"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.hsn_desc}</p>
              </Col> 
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Unit Price</Form.Label>
                  <Form.Control
                    type="text"
                    id="unit_price"
                    name="unit_price"
                    placeholder="Unit Price"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={MAX_AMOUNT_LENGTH}
                    value={formValues["unit_price"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.unit_price}</p>
              </Col> 

              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Yield (%)</Form.Label>
                  <Form.Control
                    type="text"
                    id="yield_per"
                    name="yield_per"
                    placeholder="Yield (%)"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    //maxLength={MAX_AMOUNT_LENGTH}
                    value={formValues["yield_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.yield_per}</p>
              </Col> 
            </Row>
           
            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    CGST(%)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="cgst_per"
                    name="cgst_per"
                    placeholder="CGST"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["cgst_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.cgst_per}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    SGST(%)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="sgst_per"
                    name="sgst_per"
                    placeholder="SGST"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["sgst_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.sgst_per}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    IGST(%)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="igst_per"
                    name="igst_per"
                    placeholder="IGST"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["igst_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.igst_per}</p>
              </Col>
              
              <Col>
              <Form.Group className="mb-3">
                  <Form.Label>CESS(%)</Form.Label>
                  <Form.Control
                    type="text"
                    id="cess_per"
                    name="cess_per"
                    placeholder="CESS"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["cess_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.cess_per}</p>
              </Col> 
              
            </Row>

            <Row>
              <h5>
            <Badge pill bg="secondary">
                  Job Work Details
              </Badge>
            </h5>
            
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>HSN Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_hsn_code"
                    name="jw_hsn_code"
                    placeholder="HSN Code"
                    onChange={(e) => handleOnChange(e)}
                    //{receiptModeIdSelect.label === 'Cheque' ? 'field required' : 'field mb-3'}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    value={formValues["jw_hsn_code"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_hsn_code}</p>
              </Col> 

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>HSN Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_hsn_desc"
                    name="jw_hsn_desc"
                    placeholder="HSN Description"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    value={formValues["jw_hsn_desc"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_hsn_desc}</p>
              </Col> 
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Unit Price</Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_unit_price"
                    name="jw_unit_price"
                    placeholder="Unit Price"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    maxLength={MAX_AMOUNT_LENGTH}
                    value={formValues["jw_unit_price"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_unit_price}</p>
              </Col> 
              
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Yield (%)</Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_yield_per"
                    name="jw_yield_per"
                    placeholder="Yield (%)"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    //maxLength={MAX_AMOUNT_LENGTH}
                    value={formValues["jw_yield_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_yield_per}</p>
              </Col> 
            </Row>
           
            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    CGST(%)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_cgst_per"
                    name="jw_cgst_per"
                    placeholder="CGST"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    value={formValues["jw_cgst_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_cgst_per}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    SGST(%)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_sgst_per"
                    name="jw_sgst_per"
                    placeholder="SGST"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["jw_sgst_per"]}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_sgst_per}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    IGST(%)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_igst_per"
                    name="jw_igst_per"
                    placeholder="IGST"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    value={formValues["jw_igst_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_igst_per}</p>
              </Col>
              
              <Col>
              <Form.Group className="mb-3">
                  <Form.Label>CESS(%)</Form.Label>
                  <Form.Control
                    type="text"
                    id="jw_cess_per"
                    name="jw_cess_per"
                    placeholder="CESS"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    disabled={tradeTypeIdSelect.value === "PURCHASE"}
                    value={formValues["jw_cess_per"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.jw_cess_per}</p>
              </Col> 
              
            </Row>



            {!isReadOnly && (
                <Row>        
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  {state.action !== "add" && 
                  <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                  }
                          &nbsp;&nbsp;
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    {state.action === "add" &&
                    <Button
                          className="btn btn-primary"
                          id='save-add'
                          name='save-add'
                          onClick={(e) => handleSubmit(e, 'save-add')}
                        >
                          
                          {submitAddAction &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          }
                        
                          Save & Add</Button>
                        }
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}

          <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}>
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
          </Modal> 
          </div>
          </Form>
     )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
      </Container>
  );
};

export default AddUpdateItem;
