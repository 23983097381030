import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image,
  FormGroup,
  Accordion
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar from "./Sidebar";
//import Sidebar2 from "./Sidebar2";
//import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal, MAX_QTY_LENGTH, MAX_AMOUNT_LENGTH } from "../config/Config";
import axios from "axios";

//import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import spinner_logo from '../assets/images/spinner_logo.png';
import InputGroup from 'react-bootstrap/InputGroup';

import { convertNumberToWords, formatTime } from "./utils";

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsDatabaseDown,
  BsGridFill
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
//import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSellAll } from "./utils";

const AddUpdateDeliveryMfgT2 = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  let inwardArr = [
    {
      id: 123,
      inward_dc_no: "IDC123",
      inward_dc_date: "24-05-2024",
      customer_inward_dc_ref_no:"PRDC345",
      customer_inward_dc_ref_date: "12-05-2024"
    },
    {
      id: 124,
      inward_dc_no: "IDC125",
      inward_dc_date: "21-05-2024",
      customer_inward_dc_ref_no:"PRDC78",
      customer_inward_dc_ref_date: "06-05-2024"
    },
    
  ]

  let itemArr = [
    {
      item: "ES123",
      unit: "KG",
      total_qty:"100",
      balance_qty: "50",
      used_qty: 0.00,
      dc_item_check: false
    },
    {
      item: "pS456",
      unit: "KG",
      total_qty:"10",
      balance_qty: 5.00,
      used_qty: 0.00,
      dc_item_check: false
    },
    
  ];

  
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('DC Saved Successfully');
  const [dateIdSelect, setDateIdSelect] = useState({});
  const [dcList, setDcList] = useState([]);
  const [dcItemList, setDcItemList] = useState([])
  const [showDCDialog, setShowDCDialog] = useState(false);
  const [optdc, setOptDc] = useState(inwardArr);
  const [dcIdSelect, setDcIdSelect] = useState({});

  var expected_dc_number_ref = useRef(0);

  const total_items_ref = useRef(0);
  const sum_product_cost_ref = useRef(0.00);
  const sum_product_cgst_ref = useRef(0.00);
  const sum_product_sgst_ref = useRef(0.00);
  const sum_product_igst_ref = useRef(0.00);
  const sum_product_cess_ref = useRef(0.00);
  const sum_product_total_ref = useRef(0.00);
  const dc_info_ref = useRef(0.00);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [selectedRadio, setSelectedRadio] = useState(-1);
  //const dialogFromRef = useRef("notes");

  /*
  let initialItems = [
    {id:1, product: {value:3, label:"M15"}, price: "4900", qty: 5, "product_cost": "24500", "gst": "4410", "total_cost":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
    {id:1, product: {value:6, label:"M25"}, price: "4800", qty: 7, "product_cost": "215001", "gst": "38701", "total_cost":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
  ]
  */
  let initialItems = [];

  var sub_total = 0.00;

  /*
  const optionsDate = [
    { value: 1, label: "12-12-2023" },
    { value: 2, label: "01-04-2023" },
    { value: 3, label: "05-09-2023" },
    { value: 4, label: "07-08-2023" },
    { value: 5, label: "23-05-2023" },
  ];

  let stateArr = [
    { value: 1, label: "Andhra Pradesh" },
    { value: 2, label: "Assam" },
    { value: 3, label: "Tamil Nadu" },
    { value: 4, label: "Telangana" },
  ];

  let bankAccountArr = [
    { value: 1, label: "City Union Bank" },
    { value: 2, label: "Indian Bank" },
    { value: 3, label: "State Bank" },
    { value: 4, label: "Axis Bank" },
  ];
  */
  const [optionsStateBill, setOptionsStateBill] = useState([]);
  const [optionsStateShip, setOptionsStateShip] = useState([]);

  const [optionsDistrictBill, setOptionsDistrictBill] = useState([]);
  const [optionsDistrictShip, setOptionsDistrictShip] = useState([]);


  const [stateIdSelectBill, setStateIdSelectBill] = useState({});
  const [stateIdSelectShip, setStateIdSelectShip] = useState({});

  const [districtIdSelectBill, setDistrictIdSelectBill] = useState({});
  const [districtIdSelectShip, setDistrictIdSelectShip] = useState({});

  const [optionsSupply, setOptionsSupply] = useState([]);
  const [supplyIdSelect, setSupplyIdSelect] = useState({});

  const [bankAccountIdSelect, setBankAccountIdSelect] = useState({});
  const [optBankAccount, setOptBankAccount] = useState([]);
  const [itemcheckState, setItemCheckState] =  useState(false);
  const [dCcheckState, setDCCheckState] =  useState(false);
  const [disableState, setDisableState] = useState(false);

  const customerArrRef = useRef([]);
  const itemArrRef = useRef([]);
  const unitArrRef = useRef([]);
  const bankAccArrRef = useRef([]);
  const countryArrRef = useRef([]);
  const stateArrRef = useRef([]);
  const districtArrRef = useRef([]);
  const docNumberSettingRef = useRef([]);
  const dcItemsRef = useRef([]);
  const inoutdcReconAutoRef = useRef(false);

  const [showAddrDialog, setShowAddrDialog] = useState(false);

  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);

  const [inDCIdIdSelect, setInDCIdIdSelect] = useState({});

  const customerSelectInfoRef = useRef({});

  const calculateTaxAndTotal = (selectedStateGstCode) => {
    var itemsModNew =[];
    items.map((itemSelected) => {
    if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
      itemSelected.net_amt = 0.00;
      itemSelected.net_amt = parseFloat(itemSelected.qty) * parseFloat(itemSelected.unit_price);

      const currentProductIndex = productMaster.findIndex((productInd) => productInd.value === itemSelected.item_id);

      /*
      var businessList = localStorage.getItem("business");
      var businessObj = JSON.parse(businessList);
      console.log("BUSINESS LIST OBJECT : ", businessObj);
      var selBusiness = localStorage.getItem("selected_business");
      var selBusinessObj = JSON.parse(selBusiness);
      console.log("SELECTED BUSINESS : ", selBusinessObj);
      var findBusiness = businessObj.find(element => element.value === parseInt(selBusinessObj.value));
      console.log("**************** findBUSINESS value ++++++++++++ : ", findBusiness);
      if (findBusiness !== undefined) {
        console.log("SELECTED BUSINESS INFO 10001 : ", findBusiness);
      }
      */
      //if (parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
      console.log("SELECTED BUSINESS GST STATE CODE : ", JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2));
      console.log("SELECTED STATE CODE WHILE DOING COMPARISON : ", selectedStateGstCode);
      if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectedStateGstCode) ) {
        itemSelected.cgst_per = parseFloat(productMaster[currentProductIndex].cgst_per);
        if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
          itemSelected.cgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cgst_per / 100);
        }
        else {
          itemSelected.cgst_amt = 0.00;
        }
        itemSelected.sgst_per = parseFloat(productMaster[currentProductIndex].sgst_per);
        if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
          itemSelected.sgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.sgst_per / 100);
        }
        else {
          itemSelected.sgst_amt = 0.00;
        }
        itemSelected.igst_amt = 0.00;

        console.log("CALCULTED CGST AMOUNT at intra state SELECTION: ", itemSelected.cgst_amt);
        console.log("CALCULTED SGST AMOUNT at intra state SELECTION: ", itemSelected.sgst_amt);
        console.log("CALCULTED IGST AMOUNT at intra state SELECTION: ", itemSelected.igst_amt);
      }
      else {
        itemSelected.igst_per = parseFloat(productMaster[currentProductIndex].igst_per);
        if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
          itemSelected.igst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.igst_per / 100);
          console.log("IGST CALCULATED WHEN QTY AND UNIT PRICE VALID ...", itemSelected.igst_amt);
        }
        else {
          console.log("IGST NOT CALCULATED SO 0.00 ...", itemSelected.igst_amt);
          itemSelected.igst_amt = 0.00;
        }
        itemSelected.cgst_amt = 0.00;
        itemSelected.sgst_amt = 0.00;

        console.log("CALCULTED CGST AMOUNT at inter state SELECTION : ", itemSelected.cgst_amt);
        console.log("CALCULTED SGST AMOUNT at inter state SELECTION : ", itemSelected.sgst_amt);
        console.log("CALCULTED IGST AMOUNT at inter state SELECTION: ", itemSelected.igst_amt);
      }
      //igst_amt: 0.00,
      itemSelected.cess_per = parseFloat(productMaster[currentProductIndex].cess_per);
      if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
        itemSelected.cess_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cess_per / 100);
      }
      else {
        itemSelected.cess_amt = 0.00;
      }
      //cess_amt: 0.00,
      //if ( parseInt(stateIdSelectShip.gst_code) === parseInt(currSelBusinessAddlRef.current.slice(0,2)) ) {
      //if (findBusiness && parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
        if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectedStateGstCode) ) {
        itemSelected.total_amt = parseFloat(itemSelected.net_amt) + (parseFloat(itemSelected.cgst_amt) + parseFloat(itemSelected.sgst_amt) + parseFloat(itemSelected.igst_amt) + parseFloat(itemSelected.cess_amt));
      }
      else {
        itemSelected.total_amt = parseFloat(itemSelected.net_amt) + (parseFloat(itemSelected.igst_amt) + parseFloat(itemSelected.cess_amt));
      }
      //itemSelected.cgst_amt = itemSelected.net_amt * (itemSelected.cgst_per / 100 );
      //itemSelected.total_amt = itemSelected[nameOfElement] * itemSelected.unit_price;
      console.log("NET AMOUNT OF SELECTED ROW : ", itemSelected.net_amt);
    }
    else {
      console.log("ANY ONE OF THE VALUE IS NULL OR UNDEFINED ...");
    }

    itemsModNew.push(itemSelected);
  });

  setItems(itemsModNew);
  }

  const handleCustomerSelect = async (e) => {
    console.log("handleCustomerSelect called ...", e);
    customerSelectInfoRef.current = e;
    setCustomerIdSelect(e);

    setFormValues({ ...formValues, gstin: e.gstin });

    //if ( dcList.length < 1 ) {
      var [retStateInward, inwardDcList] = await  getInwardDcList(e.value);
      if ( retStateInward ) {
        console.log("INWARD DC LIST : ", inwardDcList);
        var inwardDcListMod = [];
        inwardDcList.forEach((indc)=>{
          var indcMod = indc;
          indcMod.value = indc.indc_id;
          indcMod.label = indc.cust_indc_no;
          inwardDcListMod.push(indcMod);
        })
        console.log("MODIFIED INDC ARRAY LIST : ", inwardDcListMod);
        setDcList(inwardDcListMod);
        setInDCIdIdSelect(inwardDcListMod[0]);
        setFormValuesAddl({ ...formValuesAddl, ref_no: inwardDcListMod[0].cust_indc_no, ref_date: inwardDcListMod[0].cust_indc_date });

        var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcIdForAuto(inwardDcListMod[0].indc_id, items);
        setToRender(true);
      } 
    //}

    //await getPrevAddr(e.value);
    var [retStatePrevAddr, prevAddress] = await getPrevAddr(e.value);
          if ( retStatePrevAddr ) {
            setFormValues({ ...formValues, 
              address1_ship: prevAddress[0].address1_ship,
              address2_ship: prevAddress[0].address2_ship,
              pincode_ship: prevAddress[0].pincode_ship,
              address1_bill: prevAddress[0].address1_bill,
              address2_bill: prevAddress[0].address2_bill,
              pincode_bill: prevAddress[0].pincode_bill
            });
          }
    setLoadPrevAddrState(true);
    setRefresh(true);
  }

  const fetchInDCItemList = async(curSelId) => {
    var inwardDcList = dcList;
    var retState1 = false;
    var inwardDCItemsRet = [];
    if ( state.action === 'view' || state.action === 'edit' ) {
      inwardDcList.forEach(async(inDcInd, idx) =>{
        if ( inDcInd.indc_id === items[curSelId].indc_id ) {
          setSelectedRadio(idx);
          [retState1, inwardDCItemsRet] = await getInwardDCItemsByDcId(items[curSelId].indc_id, curSelId, curSelId);
          console.log("INWARD DC ITEM LIST <<<<<<<<<<<<<<<<>>>>>>>>>>>>> : ", inwardDCItemsRet);
          var newDcItemList = [];
          inwardDCItemsRet.forEach((dcItem) => {
            var newDcItem = dcItem;
            console.log("DC ITEM $$$$$$$$$$$$$$$$ : ", dcItem);
            console.log("CURRETLY SELECTED ITEM &&&&&&&&&&&&&& : ", items[curSelId]);
            newDcItem.dc_item_check = false;
            if ( state.action === 'add') {
              //newDcItem.qty_used = newDcItem.indc_qty_unused;
              newDcItem.qty_used = items[curSelId].qty_used;
              newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
            }
            else {
              if ( items[curSelId].indc_id === newDcItem.indc_dc_id && items[curSelId].indc_item_id === newDcItem.indc_item_id ){
                newDcItem.qty_used = items[curSelId].qty_used;
                newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
                newDcItem.dc_item_check = true;
                setDisableState(true);
              }
              else {
                newDcItem.qty_used = items[curSelId].qty_used;
                newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
              }
            }
            newDcItemList.push(newDcItem);
          })
          console.log("NEW DC ITEM ARRAY : ", newDcItemList);
          setDcItemList(newDcItemList);
        }
        /*
        else {
          var [retState2, inwardDCItemsRet2] = await getInwardDCItemsByDcId(inwardDcList[0].indc_id, curSelId);
          console.log("INWARD DC ITEM LIST <<<<<<<<<<<<<<<<>>>>>>>>>>>>> : ", inwardDCItemsRet2);
          var newDcItemList2 = [];
          inwardDCItemsRet.forEach((dcItem) => {
            var newDcItem = dcItem;
            newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
            console.log("DC ITEM $$$$$$$$$$$$$$$$ : ", dcItem);
            console.log("CURRETLY SELECTED ITEM &&&&&&&&&&&&&& : ", items[curSelId]);

            newDcItemList2.push(dcItem);
          })
          console.log("NEW DC ITEM ARRAY ELSE CONDITION : ", newDcItemList2);
          setDcItemList(newDcItemList2); 
        }
        */
      })
    }
    else {
      var selectedIndexTmp = 0;
      if (  selectedRadio < 0 ) {
        setSelectedRadio(0);
        selectedIndexTmp = 0;
      }
      else {
        selectedIndexTmp = selectedRadio;
      }
      if ( inwardDcList[selectedIndexTmp] !== null && inwardDcList[selectedIndexTmp] !== undefined ) {
      [retState1, inwardDCItemsRet] = await getInwardDCItemsByDcId(inwardDcList[selectedIndexTmp].indc_id, curSelId);
          console.log("INWARD DC ITEM LIST <<<<<<<<<<<<<<<<>>>>>>>>>>>>> : ", inwardDCItemsRet);
          var newDcItemList = [];
          inwardDCItemsRet.forEach((dcItem) => {
            var newDcItem = dcItem;
            newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
            console.log("DC ITEM $$$$$$$$$$$$$$$$ : ", dcItem);
            console.log("CURRETLY SELECTED ITEM &&&&&&&&&&&&&& : ", items[curSelId]);
            //newDcItem.dc_item_check = false;
            /*
            if ( state.action === 'add' && !newDcItem.dc_item_check ) {
              newDcItem.qty_used = newDcItem.indc_qty_unused;
            }
            / *
            else if ( state.action === 'add' && newDcItem.dc_item_check ) {
              newDcItem.qty_used = items[curSelId].qty;
            }
            * /
            else {
              if ( items[curSelId].indc_dc_id === newDcItem.indc_dc_id && items[curSelId].indc_item_id === newDcItem.indc_item_id ){
                newDcItem.qty_used = items[curSelId].qty;
                //newDcItem.dc_item_check = true;
              }
            }
            */
            if ( "in_dc_items" in items[curSelId] ) {
            items[curSelId].in_dc_items.forEach((indcitem) =>{
              if ( indcitem.indc_dc_id === newDcItem.indc_dc_id && indcitem.indc_item_id === newDcItem.indc_item_id ){
                newDcItem.qty_used = indcitem.qty_used;
                newDcItem.jw_yield_per = indcitem.jw_yield_per;
                newDcItem.dc_item_check = true;
                setDisableState(true);
              }
            })
            }
            newDcItemList.push(newDcItem);
          })

          console.log("NEW DC ITEM ARRAY : ", newDcItemList);
          setDcItemList(newDcItemList);
        }
    }
  }

  const handleInDCSelect = async(e) => {
    console.log("handleInDCSelect called ...", e);
    setInDCIdIdSelect(e);

    setFormValuesAddl({ ...formValuesAddl, ref_no: e.cust_indc_no, ref_date: e.cust_indc_date });
    //var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcId(e.indc_id, selectedRowId);
    //var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcIdForAuto(e.indc_id, items[selectedRowId].jw_yield_per);
    var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcIdForAuto(e.indc_id, items);
  }

  const handleCloseAddrDialog = async () => {
    console.log("handleCloseAddrDialog called ...");
  }

  const handleOkAddrDialog = async () => {
    console.log("handleOkAddrDialog called ...");
  }

  const handleSupplySelect = async (e) => {
    console.log("handleSupplySelect called....", e);
    setSupplyIdSelect(e);
  };

  const handleBankAccountSelect = async (e) => {
    console.log("handleBankAccountSelect called...", e);
    setBankAccountIdSelect(e);
  }

  const handleStateSelectBill = async (e) => {
    console.log("handleStateSelectBill called ...", e);
    setStateIdSelectBill(e);
  };

  const handleStateSelectShip = async (e) => {
    console.log("handleStateSelectSipp called ...", e);
    setStateIdSelectShip(e);

    calculateTaxAndTotal(e.gst_code);

    sumDelivery(items, e);
  };


  const handleDistrictSelectShip = async (e) => {
    console.log("handleStateSelectSipp called ...", e);
    setDistrictIdSelectShip(e);
  };

  const handleDistrictSelectBill = async (e) => {
    console.log("handleStateSelectBill called ...", e);
    setDistrictIdSelectBill(e);
  };


  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    /*
    customer_name: "",
    customer_disp_name: "",
    order_date: formatDate(new Date()),
    due_date: formatDate(new Date()),
    delivery_date: formatDate(new Date()),
    ref_date: formatDate(new Date()),
    buyer_date: formatDate(new Date()),
    rr_date: formatDate(new Date()),
    delivery_no: "",
    mode_payment: "",
    ref_no: "",
    reference: "",
    buyer_no: "",
    dispatch_no: "",
    notes: "",
    tc_declaration: "",


    gst_number_bill: "",
    address1_bill: "",
    address2_bill: "",
    pincode_bill: "",
    state_bill: 1,

    gst_number_shipp: "",
    address1_shipp: "",
    address2_shipp: "",
    pincode_shipp: "",
    state_shipp: 1,

    product: { value: "M15", label: "M15" },
    notes: ""
    */
    dc_no : "",
    dc_sno : 0,
    dc_date : formatDate(new Date()),
    //due_date : formatDate(new Date()),
    customer_id : 0,

    shipto_id : 0,
    address1_ship : "",
    address2_ship : "",
    state_ship : 0,
    district_ship : 0,
    pincode_ship: "",

    billto_id : 0,
    address1_bill : "",
    address2_bill : "",
    state_bill : 0,
    district_bill : 0,
    pincode_bill: "", 
    
    pos_state_id : 0,
    sub_total_amt : 0.00,
    cgst_amt : 0.00,
    sgst_amt : 0.00,
    igst_amt : 0.00,
    cess_amt : 0.00,
    total_amt : 0.00,
    roundoff_amt : 0.00,
    dc_total_amt : 0.00,
    reference : "",
    //bank_acc_id : 0,
    //bank_acc_details : "",
    notes : "",
    //tc : "", 
    //declaration : "",
    dc_details_id: 0,
    called_from: "",
    dialog_value: "",
  };

  /*
  const initialValuesOrder = {
    inv_sno: 0,
    order_date: formatDate(new Date()),
    gstin: ""
  };
  */


  let productArr = [
    { value: 1, label: "TMT Rods" },
    { value: 2, label: "Amman Steels" },

  ];

  const initialValuesAddl = {
    /*
    delivery_no: "",
    delivery_date: "",
    mode_payment: "",
    ref_no: "",
    ref_date: "",
    reference: "",
    other_reference: "",
    buyer_no: "",
    buyer_date: "",
    dispatch_no: "",
    dispatch_through: "",
    destination: "",
    rr_no: "",
    rr_date: "",
    vehicle_no: "",
    terms_delivery: ""
    */
    
    delivery_note_no: "",
    delivery_note_date: formatDate(new Date()),
    pay_terms: "",
    ref_no: "",
    ref_date: null,
    ref_others: "",
    buyer_order_no: "",
    buyer_order_date: null,
    dispatch_doc_no: "",
    dispatch_through: "",
    destination: "",
    bol_no: "",
    bol_date: null,
    vehicle_no: "",
    delivery_terms: "",
    process_nature: "Job Work Conversion into Lamination",
    issue_date: null,
    issue_time: formatTime(new Date())
  };


  //const [formValuesOrder, setFormValuesOrder] = useState(initialValuesOrder);


  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [formValuesAddl, setFormValuesAddl] = useState(initialValuesAddl);
  const [formErrorsAddl, setFormErrorsAddl] = useState({});
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productMaster, setProductMaster] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [toRender, setToRender] = useState(false);

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [billDistrictIdSelect, setBillDistrictIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [customerSearchText, setCustomerSearchText] = useState("");

  const [searchedCustomer, setSearchedCustomer] = useState({});

  const [orderAddress, setOrderAddress] = useState({});
  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  const [productList, setProductList] = useState();
  const [editAction, setEditAction] = useState(false);

  //const [optDate, setOptDate] = useState(optionsDate);
  const [optionsProd, setOptionsProd] = useState([]);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  const currSelBusinessRef = useRef(0);
  const currSelBusinessAddlRef = useRef(0);
  const currBranchRef = useRef(0);
  const [copyCheckState, setCopyCheckState] = useState(false);
  const [loadPrevAddrState, setLoadPrevAddrState] = useState(false);
  const [billableState, setBillableState] = useState(true);
  //const [disableState, setDisableState] = useState(true);

  var validateErr = false;

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [prodList, setProdList] = useState(prodListLoad);

  const [gstBillStatus, setGstBillStatus] = useState(true);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  const [showItemConfigDialog, setShowItemConfigDialog] = useState(false);
  const [showInfoConfigDialog, setShowInfoConfigDialog] = useState(false);
  const [uomIdSelect, setUomIdSelect] = useState({});
  const [productCompositionList, setProductCompositionList] = useState([]);

  const [compositionUpdate, setCompositionUpdate] = useState(false);

  const [selectedCompositionId, setSelectedCompositionId] = useState(-1);

  //const [searchText, setSearchText] = useState('');

  const handleAddlValueChange = async(e) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    setFormValuesAddl({ ...formValuesAddl, [name]: e.target.value});
  }

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    
    

    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);

    if (
      name === "phone" ||
      name === "contact_number" ||
      name === "shipContactNumber" ||
      name === "billContactNumber" ||
      name === "pincode" ||
      name === "shipPincode" ||
      name === "billPincode"
    ) {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        /*
        if (name === 'dc_no') {
          setFormValues({ ...formValues, [name]: e.target.value });
          //expected_dc_number_ref.current = e.target.value;
          console.log("................ BEFORE EXTRACT NAME AND VALUE ...............");
          var currentId = value.substring(name.lastIndexOf("/") + 1);
          var nameOfElement = value.substring(0, name.lastIndexOf("/"));
          console.log("Prefix NAME : ", nameOfElement);
          console.log("DC SERIAL NUMBER : ", currentId);

          expected_dc_number_ref.current = parseInt(currentId);
          formValues({ ...formValues, dc_sno: parseInt(currentId) });
        }
        else {
          setFormValues({ ...formValues, [name]: e.target.value });
        }
        */
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    }
    else {
      if (name === 'dc_no') {
        //setFormValues({ ...formValues, [name]: e.target.value });
        //expected_dc_number_ref.current = e.target.value;
        console.log("................ BEFORE EXTRACT NAME AND VALUE ...............");
        var currentId = value.substring(value.lastIndexOf("/") + 1);
        var nameOfElement = value.substring(0, value.lastIndexOf("/"));
        console.log("Prefix NAME : ", nameOfElement);
        console.log("DC SERIAL NUMBER : ", currentId);

        expected_dc_number_ref.current = parseInt(currentId);
        setFormValues({ ...formValues, dc_sno: parseInt(currentId),  dc_no: e.target.value});
      }
      else {
        setFormValues({ ...formValues, [name]: value });
      }
      /*
      if ( name === 'contact_person' && addrCopyCheck) {
        setFormValues({ ...formValues, ["shipContactName"]: value });
      }
      */
    }
    var quantity_tmp = 0.00;
    var unit_price_tmp = 0.00;
    /*
    if ( !isNaN(parseFloat(formValues["quantity"])) ) {
      quantity_tmp = parseFloat(formValues["quantity"]);
    }
    if ( !isNaN(parseFloat(formValues["unit_price"])) ) {
      unit_price_tmp = parseFloat(formValues["unit_price"]);
    }
    */
    sub_total = quantity_tmp + unit_price_tmp;
    console.log("SUB TOTAL at handleValueChange : ", sub_total);
  };

  const handleChangeForList = async(e) => {
    console.log("handleChangeForList called : ", e);
    const { name, value } = e.target;

    console.log("Captured input element name : ", name);
    var currPos = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("SELECTED POSTION : ", currPos);
    console.log("SELECTED NAME : ", nameOfElement);

   if (nameOfElement === 'qty_used' || nameOfElement === 'jw_yield_per') {
    if (e.target.value.match(appGlobal.numericRegExp)) {
      var selectedDCItem = dcItemList[currPos];
      selectedDCItem[nameOfElement] = e.target.value;

      /*
      if (nameOfElement === 'balance_qty' < nameOfElement === 'used_qty') {
        console.log("Condition checked....")
        dcItemList.used_qty_err = "Used Quatity should be less than Balance Quantity";
      } else {
        dcItemList.used_qty_err = "";
      }
      */

      var dcItemListNew = dcItemList.slice();
      dcItemListNew[currPos] = selectedDCItem;
      setDcItemList(dcItemListNew);
   }
  }
}

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);


    // getDistrictList(stateIdInput);
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleBillDistrictSelect = async (e) => {
    console.log("handle bill district : ", e);

    setBillDistrictIdSelect(e);
  };



  const handleChange = (e, data) => {
    //const min = 0;
    //const max = 10000;
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    //console.log("Product: ", itemSelected.product);
    console.log("^^^ SELECTED ITEM FULL VALUE FOR TESTING ^^^*************** :", itemSelected);
    console.log("Product ID : ", itemSelected.item_id);
    console.log("Product Name : ", itemSelected.item_name);
    console.log("Rate : ", itemSelected.rate);
    console.log("Unit price : ", itemSelected.unit_price);
    console.log("CGST : ", parseFloat(itemSelected.cgst));
    console.log("SGST : ", parseFloat(itemSelected.sgst));
    console.log("Total Amount : ", itemSelected.total_price);
    console.log("Area : ", itemSelected.area);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.value === itemSelected.item_id);
    console.log("Currently selected row product id : ", currentProductIndex);

    
    /*
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }
    */

    //console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    //console.log("%%%%%%%%%%%%%% GST INFO ++++++++++++++ : ", productMaster[currentProductIndex]["gst"].cgst);

    /*
    itemSelected.product = {
      value: productMaster[currentProductIndex].product_id,
      label: productMaster[currentProductIndex].product_name,
      stock_id: productMaster[currentProductIndex].stock_id,
      cgst: productMaster[currentProductIndex]["gst"].cgst,
      sgst: productMaster[currentProductIndex]["gst"].sgst,
      igst: productMaster[currentProductIndex]["gst"].igst,
    };
    */

    //itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    //itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    //itemSelected.igst_perc = productMaster[currentProductIndex].igst;
    //itemSelected.addl_notes = productMaster[currentProductIndex].addl_notes;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "qty" || nameOfElement === "unit_price" || nameOfElement === "jw_yield_per") {
      console.log("Product Quantity item edited ...");
      /*
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      */
      //var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //var valueTmp = e.target.value.replace(/^-?\d+\.?\d*$/g, "");
      //var numericRegExp = new RegExp("^[0-9]+$");
      //const numericRegExp = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
      //const numericRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      //const numericRegExp = new RegExp(/^-?\d*[\.]?\d+$/);

      // const numericRegExp = /^\d*\.?\d*$/;//original
      ////^([0-9]+-)*([0-9]+)$///.replace(/(\..*)\./g, "$1");
      //console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      //if (numericRegExp.match(e.target.value)) {
      if (e.target.value.match(appGlobal.numericRegExp)) {
        console.log("PATTERN MATCHED ...: ", e.target.value);
        //if ( parseFloat(e.target.value.toString()) !== NaN ) {
        //console.log("INSIDE IF CONDITION ...");
        //itemSelected[nameOfElement] = parseFloat(e.target.value.toString());
        /*
        if ( e.target.value < 0.01 ) {
          console.log("INSIDE IF CONDITION ...");
          itemSelected[nameOfElement] = 0;
        }
        else {
          console.log("INSIDE ELSE CONDITION ...");
          itemSelected[nameOfElement] = e.target.value.toString();
        }
        */
        /*
        }
        else {
          console.log("INSIDE ELSE CONDITION ...");
          itemSelected[nameOfElement] = "";
        }
        */

        if ( nameOfElement === "qty" ) {
          if ( e.target.value < 0.01 ) {
            itemSelected.qty_err = "Please Fill";
          }
          else {
            itemSelected.qty_err = "";
          }
        }
        else if ( nameOfElement === "unit_price" ) {
          if ( e.target.value < 0.01 ) {
            itemSelected.unit_price_err = "Please Fill";
          }
          else {
            itemSelected.unit_price_err = "";
          }
        }
        else if ( nameOfElement === "pkg_info" ) {
          if ( e.target.value < 0.01 ) {
          itemSelected.pkg_info_error = "Please Fill";
          }
        else {
          itemSelected.pkg_info_error = "";
        }
      }

        itemSelected[nameOfElement] = e.target.value;

        if (nameOfElement === 'qty' || nameOfElement === 'unit_price') {
          
          if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
            itemSelected.net_amt = 0.00;
            if ( nameOfElement === 'qty' ) {
              itemSelected.net_amt = parseFloat(itemSelected[nameOfElement]) * parseFloat(itemSelected.unit_price);
              if ( inoutdcReconAutoRef.current ) {
                itemSelected.in_dc_items[0]["qty_used"] = parseFloat(itemSelected[nameOfElement]);
              }
            }
            else {
              itemSelected.net_amt = parseFloat(itemSelected.qty) * parseFloat(itemSelected[nameOfElement]);
            }
            /*
            var businessList = localStorage.getItem("business");
            var businessObj = JSON.parse(businessList);
            console.log("BUSINESS LIST OBJECT : ", businessObj);
            var selBusiness = localStorage.getItem("selected_business");
            var selBusinessObj = JSON.parse(selBusiness);
            console.log("SELECTED BUSINESS : ", selBusinessObj);
            var findBusiness = businessObj.find(element => element.value === parseInt(selBusinessObj.value));
            console.log("**************** findBUSINESS value ++++++++++++ : ", findBusiness);
            if (findBusiness !== undefined) {
              console.log("SELECTED BUSINESS INFO 10001 : ", findBusiness);
            }
            */
            //if (parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
            if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectShip.gst_code) ) {
              itemSelected.cgst_per = productMaster[currentProductIndex].cgst_per;
              if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
                itemSelected.cgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cgst_per / 100);
              }
              else {
                itemSelected.cgst_amt = 0.00;
              }
              itemSelected.sgst_per = productMaster[currentProductIndex].sgst_per;
              if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
                itemSelected.sgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.sgst_per / 100);
              }
              else {
                itemSelected.sgst_amt = 0.00;
              }
              itemSelected.igst_amt = 0.00;

              console.log("CALCULTED CGST AMOUNT at intra state SELECTION: ", itemSelected.cgst_amt);
              console.log("CALCULTED SGST AMOUNT at intra state SELECTION: ", itemSelected.sgst_amt);
              console.log("CALCULTED IGST AMOUNT at intra state SELECTION: ", itemSelected.igst_amt);
            }
            else {
              itemSelected.igst_per = productMaster[currentProductIndex].igst_per;
              if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
                itemSelected.igst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.igst_per / 100);
                console.log("IGST CALCULATED WHEN QTY AND UNIT PRICE VALID ...", itemSelected.igst_amt);
              }
              else {
                console.log("IGST NOT CALCULATED SO 0.00 ...", itemSelected.igst_amt);
                itemSelected.igst_amt = 0.00;
              }
              itemSelected.cgst_amt = 0.00;
              itemSelected.sgst_amt = 0.00;

              console.log("CALCULTED CGST AMOUNT at inter state SELECTION : ", itemSelected.cgst_amt);
              console.log("CALCULTED SGST AMOUNT at inter state SELECTION : ", itemSelected.sgst_amt);
              console.log("CALCULTED IGST AMOUNT at inter state SELECTION: ", itemSelected.igst_amt);
              console.log("CALCULTED CESS AMOUNT at inter state SELECTION: ", itemSelected.igst_amt);
            }
            //igst_amt: 0.00,
            itemSelected.cess_per = productMaster[currentProductIndex].cess_per;
            if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
              itemSelected.cess_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cess_per / 100);
            }
            else {
              itemSelected.cess_amt = 0.00;
            }
            //cess_amt: 0.00,
            //if ( parseInt(stateIdSelectShip.gst_code) === parseInt(currSelBusinessAddlRef.current.slice(0,2)) ) {
            //if (findBusiness && parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
              itemSelected.total_amt = 0.00;
            if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectShip.gst_code) ) {
              itemSelected.total_amt = parseFloat(itemSelected.net_amt) + (parseFloat(itemSelected.cgst_amt) + parseFloat(itemSelected.sgst_amt) + parseFloat(itemSelected.cess_amt));
            }
            else {
              itemSelected.total_amt = parseFloat(itemSelected.net_amt) + (parseFloat(itemSelected.igst_amt) + parseFloat(itemSelected.cess_amt));
            }
            //itemSelected.cgst_amt = itemSelected.net_amt * (itemSelected.cgst_per / 100 );
            //itemSelected.total_amt = itemSelected[nameOfElement] * itemSelected.unit_price;
            console.log("TOTAL AMOUNT OF SELECTED ROW : ", itemSelected.total_amt);
          }
          else {
            console.log("ANY ONE OF THE VALUE IS NULL OR UNDEFINED ...");
          }
        }
      }
      else {
        console.log("PATTERN DOESN'T MATCH ...");
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
      itemSelected[nameOfElement] = e.target.value;
    }
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    /*
    if(itemSelected.product_type === 'PRODUCT') {
    if ( (itemSelected.quantity && itemSelected.unit_price) && parseFloat(itemSelected.quantity) !== isNaN && parseFloat(itemSelected.unit_price) !== isNaN) {
      itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    }
    

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;

    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst;
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    console.log("CGST STATE 501 : ", itemSelected.cgst);
    console.log("SGST STATE 501 : ", itemSelected.sgst);
    console.log("IGST STATE 501 : ", itemSelected.igst);

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);
    console.log("Modified Additional Notes : ", itemSelected.addl_notes);
*/

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);

    sumDelivery(newitems, stateIdSelectShip);
  };

  /*
   const getOrderAddress = async () => {
     try {
       var aIVal = localStorage.getItem("_aI");
       var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
       var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
       //.then((res) => {
       //console.log("ADD New user res:", res);
       //localStorage.setItem('_token', res.data.res_data.token);
       console.log("Response from server getOrderAddress : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
         setOrderAddress(res.data.detail.res_data.order_address);
 
         formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
         formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
         formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
         formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
         formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;
 
         console.log("Delivery contact : ", formValues["shipContactName"]);
 
         formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
         formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
         formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
         formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
         formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;
 
         console.log("Billing : ", formValues["billContactName"]);
 
         setAddressFetched(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       if (error.code === "ERR_NETWORK") {
         console.log("Not able to reach target server please try after sometime");
       } else {
         console.log("ERROR : ", error.response.data.detail);
         if (error.response.data.detail.status === "FAILED") {
           console.log("Request failed due to ", error.response.data.detail.res_data);
           //setShowMsg(error.response.data.detail.res_data);
           //setShowMsgTitle('LOGIN FAILED!');
           //setShow(true);
         }
       }
     }
   };
   */
  /*
  const getOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderById?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server getOrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server getOrderDetails order info : ", res.data.detail.res_data.order_info);
        console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        setOrderInfoDet(res.data.detail.res_data.order_info);
        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        formValues["order_date"] = formatDate(dd);
        console.log("Date of order_date : ", dd.getMonth());
        setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

       
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
  /*
   const getOrderItemsByOrderId = async () => {
     try {
       var aIVal = localStorage.getItem("_aI");
       var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
       var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
       //.then((res) => {
       //console.log("ADD New user res:", res);
       //localStorage.setItem('_token', res.data.res_data.token);
       console.log("Response from server getOrdersList : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
         console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
         console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
         console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
         //optionsDistrict = res.data.detail.res_data.district_list
 
         //setOrderInfoDet(res.data.detail.res_data.order_info);
 
         if (res.data.detail.res_data.order_item_list.length > 0) {
           //setProdList(res.data.detail.res_data.order_item_list);
           setItems(res.data.detail.res_data.order_item_list);
           //setOptDist(res.data.detail.res_data.district_list)
           //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
         }
 
         setOrderItemFetched(true);
         //setDistrictIdSelect(res.data.detail.res_data.district_list);
 
         //localStorage.setItem('_aI', res.data.detail.res_data.aI);
         //localStorage.setItem('userRoleId', userRoleId);
         //localStorage.setItem('username', res.data.detail.res_data.user_info.username);
 
         //navigate("/dashboard");
       }
      
     } catch (error) {
       console.log("FULL ERROR : ", error);
       if (error.code === "ERR_NETWORK") {
         console.log("Not able to reach target server please try after sometime");
       } else {
         console.log("ERROR : ", error.response.data.detail);
         if (error.response.data.detail.status === "FAILED") {
           console.log("Request failed due to ", error.response.data.detail.res_data);
           //setShowMsg(error.response.data.detail.res_data);
           //setShowMsgTitle('LOGIN FAILED!');
           //setShow(true);
         }
       }
     }
   };
   */
  /*
   const getDCListByOrderId = async () => {
     try {
       var aIVal = localStorage.getItem("_aI");
       var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
       var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
       //.then((res) => {
       //console.log("ADD New user res:", res);
       //localStorage.setItem('_token', res.data.res_data.token);
       console.log("Response from server getOrdersList : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
         console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
         console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
         console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
         //optionsDistrict = res.data.detail.res_data.district_list
 
         //setOrderInfoDet(res.data.detail.res_data.order_info);
 
         if (res.data.detail.res_data.dc_list.length > 0) {
           setDeliveryChellan(res.data.detail.res_data.dc_list);
           //setProdList(res.data.detail.res_data.dc_list);
           //setOptDist(res.data.detail.res_data.district_list)
           //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
         }
 
         //setDistrictIdSelect(res.data.detail.res_data.district_list);
 
         //localStorage.setItem('_aI', res.data.detail.res_data.aI);
         //localStorage.setItem('userRoleId', userRoleId);
         //localStorage.setItem('username', res.data.detail.res_data.user_info.username);
 
         //navigate("/dashboard");
         setOrderDcFetched(true);
       }
      
     } catch (error) {
       console.log("FULL ERROR : ", error);
       if (error.code === "ERR_NETWORK") {
         console.log("Not able to reach target server please try after sometime");
       } else {
         console.log("ERROR : ", error.response.data.detail);
         if (error.response.data.detail.status === "FAILED") {
           console.log("Request failed due to ", error.response.data.detail.res_data);
           //setShowMsg(error.response.data.detail.res_data);
           //setShowMsgTitle('LOGIN FAILED!');
           //setShow(true);
         }
       }
     }
     setOrderDcFetched(true);
   };
   */

  /*
  const getProductApiCall = async () => {
    console.log("getTradeType method called ...");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: ` ${aIVal}`
      }
      var res = await axios.get(
        client.domain + "/ims/getProductsTTSell",
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server getProducts : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProducts : ", res.data.detail.data);

        var newSellProdArr = [];
        res.data.detail.data.map((prodItem) => {
          var newSellProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "product_id": prodItem.id,
            "product_name": prodItem.name,
            "stock_id": 1,
            "uom": prodItem.uom_value,
            "unit_cost": prodItem.unit_price,
            "gst": { "cgst": prodItem["cgst"], "sgst": prodItem["sgst"], "igst": prodItem["igst"] }

          }
          newSellProdArr.push(newSellProd);
        })
        setProductMaster(newSellProdArr);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.data);

        }
      }
    }
  };
  */

  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ", res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  /*
  const getOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderById?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server getOrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server getOrderDetails order info : ", res.data.detail.res_data.order_info);
        console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        setOrderInfoDet(res.data.detail.res_data.order_info);
        setFormValues({ ...formValues, notes: res.data.detail.res_data.order_info.notes});
        setFormValuesOrder({ ...formValuesOrder, expected_inv_number: parseInt(res.data.detail.res_data.order_info.order_no), order_date: formatDate(dd)});
        
        setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

        / *
        if ( res.data.detail.res_data.order_list.length > 0 ) {
          setOrderList(res.data.detail.res_data.order_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        * /
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      / *
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    * /
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  */
  /*
    const getOrderItemsByOrderId = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
        //.then((res) => {
        //console.log("ADD New user res:", res);
        //localStorage.setItem('_token', res.data.res_data.token);
        console.log("Response from server getOrdersList : ", res.data.detail);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
          console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
          console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
          console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
          //optionsDistrict = res.data.detail.res_data.district_list
  
          //setOrderInfoDet(res.data.detail.res_data.order_info);
  
          if (res.data.detail.res_data.order_item_list.length > 0) {
            //setProdList(res.data.detail.res_data.order_item_list);
            setItems(res.data.detail.res_data.order_item_list);
            //setOptDist(res.data.detail.res_data.district_list)
            //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
          }
  
          setOrderItemFetched(true);
          //setDistrictIdSelect(res.data.detail.res_data.district_list);
  
          //localStorage.setItem('_aI', res.data.detail.res_data.aI);
          //localStorage.setItem('userRoleId', userRoleId);
          //localStorage.setItem('username', res.data.detail.res_data.user_info.username);
  
          //navigate("/dashboard");
        }
        / *
        if (res.data.status === "SUCCESS" ) {
          this.setState ({
          tokenJwt: res.data.res_data.token,
          isLoaded: true,
          openOTP: true,
          })
            // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
        }
        else {
        }
      * /
        //setOrderItemFetched(true);
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log("Request failed due to ", error.response.data.detail.res_data);
            //setShowMsg(error.response.data.detail.res_data);
            //setShowMsgTitle('LOGIN FAILED!');
            //setShow(true);
          }
        }
      }
    };
    */
  /*
   const getDCListByOrderId = async () => {
     try {
       var aIVal = localStorage.getItem("_aI");
       var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
       var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
       //.then((res) => {
       //console.log("ADD New user res:", res);
       //localStorage.setItem('_token', res.data.res_data.token);
       console.log("Response from server getOrdersList : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
         console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
         console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
         console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
         //optionsDistrict = res.data.detail.res_data.district_list
 
         //setOrderInfoDet(res.data.detail.res_data.order_info);
 
         if (res.data.detail.res_data.dc_list.length > 0) {
           setDeliveryChellan(res.data.detail.res_data.dc_list);
           //setProdList(res.data.detail.res_data.dc_list);
           //setOptDist(res.data.detail.res_data.district_list)
           //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
         }
 
         //setDistrictIdSelect(res.data.detail.res_data.district_list);
 
         //localStorage.setItem('_aI', res.data.detail.res_data.aI);
         //localStorage.setItem('userRoleId', userRoleId);
         //localStorage.setItem('username', res.data.detail.res_data.user_info.username);
 
         //navigate("/dashboard");
         setOrderDcFetched(true);
       }
       / *
       if (res.data.status === "SUCCESS" ) {
         this.setState ({
         tokenJwt: res.data.res_data.token,
         isLoaded: true,
         openOTP: true,
         })
           // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
       }
       else {
       }
     * /
       //setOrderDcFetched(true);
     } catch (error) {
       console.log("FULL ERROR : ", error);
       if (error.code === "ERR_NETWORK") {
         console.log("Not able to reach target server please try after sometime");
       } else {
         console.log("ERROR : ", error.response.data.detail);
         if (error.response.data.detail.status === "FAILED") {
           console.log("Request failed due to ", error.response.data.detail.res_data);
           //setShowMsg(error.response.data.detail.res_data);
           //setShowMsgTitle('LOGIN FAILED!');
           //setShow(true);
         }
       }
     }
     setOrderDcFetched(true);
   };
   */

  const getInitValues = async (business_id) => {
    console.log("getInitValues called ...");

    var retState = false;
    var initLoadDC = {};
    var reqDCInitLoad = {
      bid: business_id
    };
    var aIVal = localStorage.getItem("_aT");
    var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
    console.log("payload (BUSINESS ID) :", business_id);
    console.log("HEADERS : ", headers);

    try {
      var res = await axios.post(client.domain + `/bms/getCustDCAddUpdPreLoadData?bid=${business_id}`, reqDCInitLoad, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.data);
        //console.log("Response message from server CUSTOMERS : ", res.data.detail.data.customers);
        //console.log("Response message from server BANK ACCOUNTS : ", res.data.detail.data.bank_accounts);
        //console.log("Response message from server STATES : ", res.data.detail.data.states);

        initLoadDC = res.data.detail.data;
        retState = true;
        /*
        customerArrRef.current = res.data.detail.data.customers;
        itemArrRef.current = res.data.detail.data.items;
        unitArrRef.current = res.data.detail.data.units;
        bankAccArrRef.current = res.data.detail.data.bank_accounts;
        countryArrRef.current = res.data.detail.data.countries;
        stateArrRef.current = res.data.detail.data.states;
        districtArrRef.current = res.data.detail.data.districts;
        docNumberSettingRef.current = res.data.detail.data.doc_number_settings;

        setOptCustomer(customerArrRef.current);
        setCustomerIdSelect(customerArrRef.current[0]);

        setOptionsStateShip(stateArrRef.current);
        console.log("^^^^^^^^^^^^^^^^ BEFORE ASSIGN VALUE TO STATEIDSELECTSHIP ************** : ", stateArrRef.current[0]);
        setStateIdSelectShip(stateArrRef.current[0]);

        setOptionsDistrictShip(districtArrRef.current);
        setDistrictIdSelectShip(districtArrRef.current[0]);

        setOptionsStateBill(stateArrRef.current);
        setStateIdSelectBill(stateArrRef.current[0]);

        setOptionsDistrictBill(districtArrRef.current);
        setDistrictIdSelectBill(districtArrRef.current[0]);

        setOptBankAccount(bankAccArrRef.current);
        setBankAccountIdSelect(bankAccArrRef.current[0]);

        console.log("<<<<<<<<<<<<<<<< EXPECTED INV NUMBER : ", docNumberSettingRef.current[0].sno_generated + 1);
        //setFormValues({ ...formValues, inv_sno: docNumberSettingRef.current[0].sno_generated + 1 });
        setFormValues({ ...formValues, inv_no: docNumberSettingRef.current[0].sno_generated + 1 });
        expected_inv_number_ref.current = docNumberSettingRef.current[0].sno_generated + 1;
        console.log("expected invoice number : ", expected_inv_number_ref.current);
        setProductMaster(itemArrRef.current);
        */
        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setAlertTitle("FAILED!");
          setAlertBody("DC initial data loading failed...");
          setShow(true);
        }
      }
    }

    return [retState, initLoadDC];
  }


  const getDCItemsByDCId = async (dc_id_pass) => {
    var retState = false;
    var dcInfoRet = {};
    var dcItemsRet = [];
    var customerInfoRet = {};
    var dcDetailsRet = {};
    console.log("getDCItemsByDCId called ...");

    var aIVal = localStorage.getItem("_aT");
    var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
    console.log("payload (BUSINESS ID) :", dc_id_pass);
    console.log("HEADERS : ", headers);

    try {
      var res = await axios.get(client.domain + `/bms/getDCItemsByDCId?dc_id=${dc_id_pass}`, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.data);
        dcItemsRet = res.data.detail.data.dc_items;
        dcInfoRet = res.data.detail.data.dc_info;
        customerInfoRet = res.data.detail.data.customer_info;
        dcDetailsRet = res.data.detail.data.dc_details;
        retState = true;
        /*
        invItemsRef.current = res.data.detail.data.inv_items;
        setItems(invItemsRef.current);
        sumInvoice(invItemsRef.current);
        */
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setAlertTitle("FAILED!");
          setAlertBody("DC initial data loading failed...");
          setShow(true);
        }
      }
    }

    return [retState, dcItemsRet, dcInfoRet, customerInfoRet, dcDetailsRet];
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");

      var businessInfo = localStorage.getItem("selected_business");
      var businessObj = JSON.parse(businessInfo);

      
      //setStateIdSelectBill(optionsStateBill[0]);
      //setStateIdSe0lectShip(optionsStateShip[0]);
      //setBankAccountIdSelect(optBankAccount[0]);
      //console.log(formErrors);

      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }

      if (state === null) {
        navigate("/listDeliveryNotes");

      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);

      (async () => {
        var [retState, initLoadDC] = await getInitValues(businessObj.value);
        if ( retState ) {
          console.log("FULL DC INIT LOAD VALUE : ", initLoadDC);
          console.log("INOUTDC RECON AUTO STATUS : ", initLoadDC.inoutdc_recon_auto);

        customerArrRef.current = initLoadDC.customers;
        itemArrRef.current = initLoadDC.items;
        unitArrRef.current = initLoadDC.units;
        bankAccArrRef.current = initLoadDC.bank_accounts;
        countryArrRef.current = initLoadDC.countries;
        stateArrRef.current = initLoadDC.states;
        districtArrRef.current = initLoadDC.districts;
        docNumberSettingRef.current = initLoadDC.doc_number_settings;
        inoutdcReconAutoRef.current = initLoadDC.inoutdc_recon_auto;

        setOptCustomer(customerArrRef.current);
        setCustomerIdSelect(customerArrRef.current[0]);

        setOptionsStateShip(stateArrRef.current);
        setStateIdSelectShip(stateArrRef.current[0]);

        setOptionsDistrictShip(districtArrRef.current);
        setDistrictIdSelectShip(districtArrRef.current[0]);

        setOptionsStateBill(stateArrRef.current);
        setStateIdSelectBill(stateArrRef.current[0]);

        setOptionsDistrictBill(districtArrRef.current);
        setDistrictIdSelectBill(districtArrRef.current[0]);

        setOptBankAccount(bankAccArrRef.current);
        setBankAccountIdSelect(bankAccArrRef.current[0]);

        if (  docNumberSettingRef.current.length < 0 ) {
           setShowAlert(true);
           setAlertPurpose("warning-msg")
           setAlertTitle("Warning");
           setAlertBody("Please select Branch");
        }
        console.log("<<<<<<<<<<<<<<<< EXPECTED DC NUMBER : ", docNumberSettingRef.current[0].sno_generated + 1);
        //setFormValues({ ...formValues, inv_sno: docNumberSettingRef.current[0].sno_generated + 1 });
        //var doc_suffix = "2024-25/";
        var suffix_tmp = "";
        if ( docNumberSettingRef.current[0].doc_suffix ) {
          suffix_tmp = docNumberSettingRef.current[0].doc_suffix;
        }
        setFormValues({ ...formValues, 
          gstin: customerArrRef.current[0].gstin,
          dc_no: docNumberSettingRef.current[0].doc_prefix + parseInt(docNumberSettingRef.current[0].sno_generated + 1) + suffix_tmp, 
          dc_sno: docNumberSettingRef.current[0].sno_generated + 1 });
          //setFormValues({ ...formValues, inv_sno: docNumberSettingRef.current[0].sno_generated + 1 });
          expected_dc_number_ref.current = docNumberSettingRef.current[0].sno_generated + 1;
          console.log("expected DC number : ", expected_dc_number_ref.current);
          setProductMaster(itemArrRef.current);
        }
        //setDateIdSelect(optionsDate[0]);

        if ( state.action === 'edit' || state.action === 'view' ) {
          var [retStateDCItem, dcItemsRet, dcInfoRet, customerInfoRet, dcDetailsRet] = await getDCItemsByDCId(state.passed_element.id);
          if ( retStateDCItem ) {
            console.log("RETURNED DC INFO at useEffect : ", dcInfoRet);
            console.log("RETURNED DC ITEM LIST at useEffect : ", dcItemsRet);
            console.log("RETURNED CUSTOMER INFO at useEffect : ", customerInfoRet);
            console.log("RETURNED DC DETAILS at useEffect : ", dcDetailsRet);

            dc_info_ref.current = dcInfoRet;

            var findCustomer = customerArrRef.current.find(element => element.value === parseInt(dcInfoRet.customer_id));
            if ( findCustomer !== undefined ) {
              setCustomerIdSelect(findCustomer);

              // Load Inward DC List
              if ( dcList.length < 1 ) {
                var [retStateInward, inwardDcList] = await  getInwardDcList(findCustomer.value);
                if ( retStateInward ) {
                  console.log("INWARD DC LIST : ", inwardDcList);
                  var inwardDcListMod = [];
                  inwardDcList.forEach((indc)=>{
                    var indcMod = indc;
                    indcMod.value = indc.indc_id;
                    indcMod.label = indc.cust_indc_no;
                    inwardDcListMod.push(indcMod);
                  })
                  console.log("MODIFIED INDC ARRAY LIST : ", inwardDcListMod);
                  setDcList(inwardDcListMod);
                  var currentlySetInwardDC = {};
                  var findInDc = inwardDcListMod.find(element => element.indc_id === parseInt(dcItemsRet[0].indc_id));
                  if ( findInDc ) {
                    currentlySetInwardDC = findInDc;
                    console.log("previously assigned inward dc set");
                    setInDCIdIdSelect(findInDc);
                  }
                  else {
                    console.log("Default inward DC set");
                    currentlySetInwardDC = inwardDcListMod[0];
                    setInDCIdIdSelect(inwardDcListMod[0]);
                  }
                  var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcIdForAuto(currentlySetInwardDC.indc_id, dcItemsRet);
                  setToRender(true);
                } 
              }
            }

            var districtsObj = JSON.parse(localStorage.getItem("districts"));
            var findDistrictShip = districtsObj.find(element => element.value === parseInt(dcInfoRet.district_id_ship));
            console.log("**************** findBUSINESS value ++++++++++++ : ", findDistrictShip);
            if (findDistrictShip !== undefined) {
              setDistrictIdSelectShip(findDistrictShip);
            }

            var findDistrictBill = districtsObj.find(element => element.value === parseInt(dcInfoRet.district_id_bill));
            console.log("**************** findBUSINESS value ++++++++++++ : ", findDistrictBill);
            if (findDistrictBill !== undefined) {
              setDistrictIdSelectBill(findDistrictBill);
            }

            var statesObj = JSON.parse(localStorage.getItem("states"));
            var findStateShip = statesObj.find(element => element.value === parseInt(dcInfoRet.state_id_ship));
            console.log("**************** findBUSINESS value ++++++++++++ : ", findStateShip);
            if (findStateShip !== undefined) {
              setStateIdSelectShip(findStateShip);
            }

            var findStateBill = statesObj.find(element => element.value === parseInt(dcInfoRet.state_id_bill));
            console.log("**************** findBUSINESS value ++++++++++++ : ", findStateBill);
            if (findStateBill !== undefined) {
              setStateIdSelectBill(findStateBill);
            }

            expected_dc_number_ref.current = dcInfoRet.dc_sno;
            setFormValues({ ...formValues,
              id: dcInfoRet.id,
              bid : dcInfoRet.bid,
              brid : dcInfoRet.brid,
              gstin: customerInfoRet.gstin,
              dc_no : dcInfoRet.dc_no,
              dc_sno : dcInfoRet.dc_sno,
              dc_date : formatDate(new Date(dcInfoRet.dc_date)),
              //due_date : formatDate(new Date(dcInfoRet.due_date)),
              customer_id : dcInfoRet.customer_id,
              shipto_id : dcInfoRet.shipto_id,
              address1_ship : dcInfoRet.address_line1_ship,
              address2_ship : dcInfoRet.address_line2_ship,
              state_ship : dcInfoRet.state_id_ship,
              district_ship : dcInfoRet.district_id_ship,
              country_id_ship: dcInfoRet.country_id_ship,
              pincode_ship: dcInfoRet.pincode_ship,
              billto_id : dcInfoRet.billto_id,
              address1_bill : dcInfoRet.address_line1_bill,
              address2_bill : dcInfoRet.address_line2_bill,
              state_bill : dcInfoRet.state_id_bill,
              district_bill : dcInfoRet.district_id_bill,
              country_id_bill: dcInfoRet.country_id_bill,
              pincode_bill: dcInfoRet.pincode_bill, 
              pos_state_id : dcInfoRet.pos_state_id,
              sub_total_amt : dcInfoRet.sub_total_amt,
              cgst_amt : dcInfoRet.cgst_amt,
              sgst_amt : dcInfoRet.sgst_amt,
              igst_amt : dcInfoRet.igst_amt,
              cess_amt : dcInfoRet.cess_amt,
              total_amt : dcInfoRet.total_amt,
              roundoff_amt : dcInfoRet.roundoff_amt,
              dc_total_amt : dcInfoRet.dc_total_amt,
              reference : dcInfoRet.reference,
              //bank_acc_id : dcInfoRet.bank_acc_id,
              //bank_acc_details : dcInfoRet.bank_acc_details,
              notes : dcInfoRet.notes,
              //tc : dcInfoRet.tc, 
              //declaration : dcInfoRet.declaration,

              /*
              delivery_no: formValuesAddl.delivery_no,
              delivery_date: formValuesAddl.delivery_date,
              mode_payment: formValuesAddl.mode_payment,
              ref_no: formValuesAddl.ref_no,
              ref_date: formValuesAddl.ref_date,
              addl_reference: formValuesAddl.reference,
              other_reference: formValuesAddl.other_reference,
              buyer_no: formValuesAddl.buyer_no,
              buyer_date: formValuesAddl.buyer_date,
              dispatch_no: formValuesAddl.dispatch_no,
              dispatch_through: formValuesAddl.dispatch_through,
              destination: formValuesAddl.destination,
              rr_no: formValuesAddl.rr_no,
              rr_date: formValuesAddl.rr_date,
              vehicle_no: formValuesAddl.vehicle_no,
              terms_delivery: formValuesAddl.terms_delivery,
              */
             dc_details_id: dcDetailsRet.id
            });

            setBillableState(dcInfoRet.is_billable);

            /*
            var findBank = bankAccArrRef.current.find(element => element.value === dcInfoRet.bank_acc_id);
            if ( findBank !== undefined ) {
              setBankAccountIdSelect(findBank);
            }
            */

            dcItemsRef.current = dcItemsRet;
            setItems(dcItemsRet);
            var findState = JSON.parse(localStorage.getItem("states")).find(element => element.value === parseInt(dcInfoRet.state_id_ship));
            console.log("**************** findBUSINESS value ++++++++++++ : ", findState);
            if (findState !== undefined) {
              sumDelivery(dcItemsRef.current, findState);
            }

            setFormValuesAddl({ ...formValuesAddl,
              //delivery_note_no: dcDetailsRet.delivery_note_no,
              //delivery_note_date: formatDate(new Date(dcDetailsRet.delivery_note_date)),
              pay_terms: dcDetailsRet.pay_terms,
              ref_no: dcDetailsRet.ref_no,
              ref_date: dcDetailsRet.ref_date !== null ? formatDate(new Date(dcDetailsRet.ref_date)) : null,
              ref_others: dcDetailsRet.ref_others,
              buyer_order_no: dcDetailsRet.buyer_order_no,
              buyer_order_date: dcDetailsRet.buyer_order_date !== null ? formatDate(new Date(dcDetailsRet.buyer_order_date)) : null,
              dispatch_doc_no: dcDetailsRet.dispatch_doc_no,
              dispatch_through: dcDetailsRet.dispatch_through,
              destination: dcDetailsRet.destination,
              bol_no: dcDetailsRet.bol_no,
              bol_date: dcDetailsRet.bol_date !== null ? formatDate(new Date(dcDetailsRet.bol_date)) : null,
              vehicle_no: dcDetailsRet.vehicle_no,
              issue_date: dcDetailsRet.issue_datetime !== null ? formatDate(new Date(dcDetailsRet.issue_datetime)) : null,
              issue_time: dcDetailsRet.issue_datetime !== null ? formatTime(new Date(dcDetailsRet.issue_datetime)) : formatTime(new Date()),
              process_duration: dcDetailsRet.process_duration, 
              process_nature: dcDetailsRet.process_nature
            })
          } 
          /*
          var [retStateInward, inwardDcList] = await  getInwardDcList(customerIdSelect.value);
          if ( retStateInward ) {
            console.log("INWARD DC LIST : ", inwardDcList);
            setDcList(inwardDcList);
            setToRender(true);
          }
          */
        }
        else {
          //if ( dcList.length < 1 ) {
            console.log("GET INWARD DC LIST FOR DC ADD ...");
            var [retStateInward2, inwardDcList2] = await  getInwardDcList(customerArrRef.current[0].value);
            if ( retStateInward2 ) {
              console.log("INWARD DC LIST : ", inwardDcList2);
              var inwardDcListMod2 = [];
              inwardDcList2.forEach((indc)=>{
                var indcMod = indc;
                indcMod.value = indc.indc_id;
                indcMod.label = indc.cust_indc_no;
                inwardDcListMod2.push(indcMod);
              })
              console.log("MODIFIED INDC ARRAY LIST : ", inwardDcListMod2);
              setDcList(inwardDcListMod2);
              setInDCIdIdSelect(inwardDcListMod2[0]);
              setFormValuesAddl({ ...formValuesAddl, ref_no: inwardDcListMod2[0].cust_indc_no, ref_date: inwardDcListMod2[0].cust_indc_date });
              var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcIdForAuto(inwardDcListMod2[0].indc_id, items);
              setToRender(true);
            } 
          //}

          console.log("GET PREVIOUS ADDRESS FOR DC ADD ...");
          var [retStatePrevAddr, prevAddress] = await getPrevAddr(customerArrRef.current[0].value);
          if ( retStatePrevAddr ) {
            console.log("PREVIOUS ADDRRESS LOADED FOR DC ADD ...");
            setFormValues({ ...formValues, 
              gstin: customerArrRef.current[0].gstin,
              dc_no: docNumberSettingRef.current[0].doc_prefix + parseInt(docNumberSettingRef.current[0].sno_generated + 1) + suffix_tmp, 
              dc_sno: docNumberSettingRef.current[0].sno_generated + 1,
              address1_ship: prevAddress[0].address1_ship,
              address2_ship: prevAddress[0].address2_ship,
              pincode_ship: prevAddress[0].pincode_ship,
              address1_bill: prevAddress[0].address1_bill,
              address2_bill: prevAddress[0].address2_bill,
              pincode_bill: prevAddress[0].pincode_bill
            });
          }
          setLoadPrevAddrState(true);
          setRefresh(true);
        }
        setToRender(true);
      })()
        initialized.current = true;
      }
    }
  }, []);

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");
    console.log("FORM VALUES at handleSubmit: ", formValues);
      validateErr = false;
      setFormErrors(validate(formValues));
      console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
      if (validateErr) {
        return -1;
      }

    console.log("FORM VALUES at handleSubmit: ", formValues);
    console.log("LINE ITEMS : ", items);
    console.log("Form submit called ...");

    console.log("CUSTOMER : ", formValues["customer_name"]);

    console.log("GST Number : ", formValues["gst_number"]);
    console.log("Phone Number : ", formValues["phone"]);

    console.log("Tot number of items : ", total_items_ref.current);
    console.log("Sum of Tot Product Cost : ", sum_product_cost_ref.current);
    console.log("Sum of Tot CGST : ", sum_product_cgst_ref.current);
    console.log("Sum of Tot IGST : ", sum_product_igst_ref.current);
    console.log("Sum of total cost : ", sum_product_total_ref.current);

    console.log("SEARCHED CUSTOMER : ", searchedCustomer);

    var selBusiness = localStorage.getItem("selected_business");
    var selBusinessObj = JSON.parse(selBusiness);
    var bidFromLocal = selBusinessObj.value;

    var selBranch = localStorage.getItem("selected_branch");
    var selBranchObj = JSON.parse(selBranch);
    var bridFromLocal = selBranchObj.value;
         
     console.log("expected DC number (2) : ", expected_dc_number_ref.current);
     console.log("+++++++++++ SUM PRODUCT TOTAL ***************** : ", sum_product_total_ref.current)
     console.log("ISSUE DATE : %s, ISSUE TIME : %s", formValuesAddl.issue_date, formValuesAddl.issue_time);
     let reqDeliveryAddUpdate = {
      id: formValues["id"] || 0,
      bid : bidFromLocal,
      brid : bridFromLocal || 0,
      dc_no : formValues.dc_no,
      dc_sno : expected_dc_number_ref.current,
      dc_date : formValues["dc_date"],
      customer_id : customerIdSelect.value,

      shipto_id : formValues["ship_id"] || 0,
      address1_ship : formValues["address1_ship"],
      address2_ship : formValues["address2_ship"],
      state_ship : stateIdSelectShip.value,
      district_ship : districtIdSelectShip.value,
      pincode_ship: formValues["pincode_ship"],

      billto_id : formValues["bill_id"] || 0,
      address1_bill : formValues["address1_bill"],
      address2_bill : formValues["address2_bill"],
      state_bill : stateIdSelectBill.value,
      district_bill : districtIdSelectBill.value,
      pincode_bill: formValues["pincode_bill"], 
      
      pos_state_id : stateIdSelectShip.value,
      sub_total_amt : sum_product_cost_ref.current.toFixed(2),
      cgst_amt : sum_product_cgst_ref.current.toFixed(2),
      sgst_amt : sum_product_sgst_ref.current.toFixed(2),
      igst_amt : sum_product_igst_ref.current.toFixed(2),
      cess_amt : sum_product_cess_ref.current,
      total_amt : sum_product_total_ref.current.toFixed(2),
      roundoff_amt : (Math.round(sum_product_total_ref.current) - sum_product_total_ref.current).toFixed(2),
      dc_total_amt : Math.round(sum_product_total_ref.current).toFixed(2),
      reference : formValues["reference"],
      //bank_acc_id : bankAccountIdSelect.value,
      //bank_acc_details : JSON.stringify(bankAccountIdSelect.bank_acc_json),
      notes : formValues["notes"],
      //tc : formValues["tc"], 
      //declaration : formValues["declaration"],

      /*
      delivery_no: formValuesAddl.delivery_no,
      delivery_date: formValuesAddl.delivery_date,
      mode_payment: formValuesAddl.mode_payment,
      ref_no: formValuesAddl.ref_no,
      ref_date: formValuesAddl.ref_date,
      addl_reference: formValuesAddl.reference,
      other_reference: formValuesAddl.other_reference,
      buyer_no: formValuesAddl.buyer_no,
      buyer_date: formValuesAddl.buyer_date,
      dispatch_no: formValuesAddl.dispatch_no,
      dispatch_through: formValuesAddl.dispatch_through,
      destination: formValuesAddl.destination,
      rr_no: formValuesAddl.rr_no,
      rr_date: formValuesAddl.rr_date,
      vehicle_no: formValuesAddl.vehicle_no,
      terms_delivery: formValuesAddl.terms_delivery,
      */

      dc_details_id: formValues["dc_details_id"] || 0,
      //delivery_note_no: formValuesAddl.delivery_note_no  || "",
      //delivery_note_date: formValuesAddl.delivery_note_date || formatDate(new Date()),
      pay_terms: formValuesAddl.pay_terms || "",
      ref_no: formValuesAddl.ref_no || "",
      ref_date: formValuesAddl.ref_date,
      ref_others: formValuesAddl.ref_others || "",
      buyer_order_no: formValuesAddl.buyer_order_no || "",
      buyer_order_date: formValuesAddl.buyer_order_date,
      dispatch_doc_no: formValuesAddl.dispatch_doc_no || "",
      dispatch_through: formValuesAddl.dispatch_through || "",
      destination: formValuesAddl.destination || "",
      bol_no: formValuesAddl.bol_no || "",
      bol_date: formValuesAddl.bol_date,
      vehicle_no: formValuesAddl.vehicle_no || "",
      //delivery_terms: formValuesAddl.delivery_terms || "",
      issue_datetime: (formValuesAddl.issue_date !== null && formValuesAddl.issue_time !== null) ? (formValuesAddl.issue_date + " " + formValuesAddl.issue_time) : "",
      process_duration: formValuesAddl.process_duration || "",
      process_nature: formValuesAddl.process_nature || "",
      is_billable: billableState,
      dc_items: items
     };
 
    
     console.log("payload:", reqDeliveryAddUpdate);
    
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-print') {
         setSubmitPrintAction(true);
       }
       else {
         setSubmitAction(true);
       }
       
       var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.res_data);
         if ( actionVal === 'save-print' ) {
           setSubmitPrintAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertTitle("Success");
         setAlertPurpose('success');
         if(state.action === "add"){ 
           setAlertBody("DC Saved Successfully!");
         } 
        else {
         setAlertBody("DC Updated Successfully!");
         }
         setShowAlert(true);
 
         if ( actionVal === 'save-print' ) {
           navigate('/listDeliveryNotes');
 
           var urlStr = window.location.href;
           var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
           var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
       
           console.log("CALLED URL : ", urlTmp);
           console.log("CALLED PAGE : ", paramsTmp);
           //var idPass = state.passed_element.id;
           //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
           var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDNMfgT2?id=${res.data.detail.res_data.id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
           winPrint.moveTo(0,0);
           winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
         }
         else {
           setShowAlert(true);
         }  
       }
       else {
         setSubmitAction(false);
         setAlertPurpose('failed');  
         setAlertTitle("Failed");
         if(state.action === 'add'){    
           setAlertBody("DC Save Failed!");
         }
         else{
           setAlertBody("DC Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertPurpose("failure");
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       
      
       setSubmitAction(false);
     }


    /*
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var idPass = 3;
    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printInvoice?id=${idPass}`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    */
  };
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (sum_product_total_ref.current < 0.01){
      //errors.dc_total_amt = "Total Amount is Empty!";
      setShowAlert(true);
      setAlertPurpose("warning-total")
      setAlertTitle("Warning!")
      setAlertBody("Invalid Total Amount. please check in")
      validateErr = true;
    }
    else {
    var newItems = [];
    items.map((itemTmp) => {
      console.log("+++++++++++++++++  ITEM TEMP ++++++++++++++++++", itemTmp);
      var item = itemTmp;
      if ( item.qty < 0.01 ) {
          item.qty_error = "Please Fill";
          console.log("CONDITION NOT MET for qty");
          validateErr = true;
        }
        else {
          item.qty_err = "";
        }
      
      
      if ( item.unit_price < 0.01 ) {
          item.unit_price_error = "Please Fill";
          console.log("CONDITION NOT MET for gst_rate");
          validateErr = true;
        }
        else {
          item.unit_price_err = "";
        }
      
        /*
        if ( item.pkg_info < 0.01 ) {
          item.pkg_info_error = "Please Fill";
          console.log("CONDITION NOT MET for total_amt");
          validateErr = true;
        }
        else {
          item.pkg_info_error = "";
        }
        */
    
      newItems.push(item);
    })
    setItems(newItems);
  } 

    //if (!customerSearchText && !searchedCustomer) {
      /*
    if (!values.customer_name) {
      console.log("Customer name is missing ...", formValues["customer_name"]);
      //errors.customer_name = "Customer name is required!";
      //validateErr = true;
    }

    if (!values.customer_disp_name) {
      errors.customer_disp_name = "Customer name is required!";
      validateErr = true;
    }
    */
    /*
    if (!values.addl_notes) {
      errors.addl_notes = "Customer name is required!";
      validateErr = true;
    }
    */



    /*
    if (!values.phone) {
      errors.phone = "Phone  is required!";
      validateErr = true;
    }
    */
    /*
    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits";
      validateErr = true;
    }
    */
   /*
    if (gstBillStatus === true) {
      if (!values.gst_number || values.gst_number === '0') {
        errors.gst_number = "GST is required!";
        validateErr = true;
      }
    }
    */

    /*
    if (!values.contact_number) {
      errors.contact_number = "Contact person number is required!";
      validateErr = true;
    }
    */
    /*  
   if (!values.address1) {
     errors.address1 = "Street / Location is required!";
     validateErr = true;
   }
   */
    {
      /*
    if (!values.address2) {
      errors.address2 = "City or Town name is required!";
      validateErr = true;
    }
  */
    }

    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
    }
    */
    /*
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits";
      validateErr = true;
    }
    */
    /*
    if (!values.primary_number) {
      errors.primary_number = "Mobile number is required";
      validateErr = true;
    }

    if (values.primary_number && values.primary_number.length != 10) {
      errors.primary_number = "Mobile number should be 10 digits";
      validateErr = true;
    }
    */
    // Delivery address validation
    /*
    if (!formValues.shipContactName) {
      errors.shipContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.shipContactNumber) {
      errors.shipContactNumber = "Contact number is required!";
      validateErr = true;
    }
    */
    /*
    if (!formValues.shipPincode) {
      errors.shipPincode = " Pincode is required!";
      validateErr = true;
    }
    */

    /*
    if (!formValues.shipAddress2) {
      errors.shipAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */

    // Billing address validation
    /*
    if (!formValues.billContactName) {
      errors.billContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.billContactNumber) {
      errors.billContactNumber = "Contact number is required!";
      validateErr = true;
    }
    */

    /*
    if (!formValues.billAddress2) {
      errors.billAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */
    /*
    if (!formValues.billPincode) {
      errors.billPincode = "Pincode is required!";
      validateErr = true;
    }
    */
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);
    */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    if ( productMaster.length < 1 ) {
      console.log("UNABLE TO FETCH MASTER ITEMS SO NOT ABLE TO PROCEED ...");
      return false;
    }
    console.log("New item addition button clicked ...", productMaster[0]);
    console.log("INWARD DC ITEM LIST WHICH WAS FETCHED EALIER : ", dcItemList);
    var firstItemAsDefault = {};
    var indcItemNew = [];
    if ( inoutdcReconAutoRef.current ) {
      if ( dcItemList[0] !== null && dcItemList[0] !== undefined) {
        firstItemAsDefault = dcItemList[0];
        firstItemAsDefault["dc_item_check"] = true;
        firstItemAsDefault["jw_yield_per"] = productMaster[0].jw_yield_per;
      }
    }
    indcItemNew.push(firstItemAsDefault);

    const newitems = items.slice();
    //const newitems = [];
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      id: 0,
      //product_id: productMaster[0].id,
      //product_name: productMaster[0].name,
      //product_type:productMaster[0].product_type,
      //rate: 0.00,
      //quantity: 0.00,
      //unit_price: 0.00,
      //area: 1,
      //total_rate: 0.00,
      //total_price: 0.00,

      //cgst: 0.00,
      //sgst: 0.00,
      //igst: 0.00,
      //total_item_price: 0.00,
      //addl_notes: "",
      item_id: productMaster[0].value,
      item_name: productMaster[0].label,
      hsn_code: productMaster[0].hsn_code,
      hsn_desc: productMaster[0].hsn_desc,
      unit: productMaster[0].unit,
      qty: 0.00,
      unit_price: productMaster[0].unit_price,
      jw_yield_per: productMaster[0].jw_yield_per,
      yield_per: productMaster[0].yield_per,
      net_amt: 0.00,
      cgst_per: 0.00,
      cgst_amt: 0.00,
      sgst_per: 0.00,
      sgst_amt: 0.00,
      igst_per: 0.00,
      igst_amt: 0.00,
      cess_per: 0.00,
      cess_amt: 0.00,
      total_amt: 0.00,
      notes: "",
      editAction: false,
      enableState: false,
      pkg_info: "",
      //jw_yield_per: 0.00,
      //yield_per: 0.00
      //composition: [],
      //is_product_cc: false,
      in_dc_items: indcItemNew,
    };
    newitems.push(itemNew);
    setItems(newitems);
  };

  const handleProductSelect = (e, data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected.product);
    console.log("********************** itemSelected: ", itemSelected);
    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.value === e.value);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    //itemSelected.item_id = e.value;
    //itemSelected.item_name = e.label;
    itemSelected.enableState = true;
    //itemSelected.isEditable = true;
    itemSelected.editAction = false;

    itemSelected.item_id = e.value;
    itemSelected.item_name = e.label;
    itemSelected.hsn_code = productMaster[currentProductIndex].hsn_code;
    itemSelected.hsn_desc = productMaster[currentProductIndex].hsn_desc;
    itemSelected.unit = productMaster[currentProductIndex].unit;
    itemSelected.jw_yield_per = productMaster[currentProductIndex].jw_yield_per;
    itemSelected.yield_per = productMaster[currentProductIndex].yield_per;
    
    var inDCItems = itemSelected.in_dc_items;
    var findDCItem = inDCItems.find(element => parseInt(element.indc_item_product_id) === parseInt(itemSelected.item_id));
    if ( findDCItem ) {
      console.log("FOUND DC ITEMS FROM INNER OBJECT in_dc_items +++++++++++++++++++++ : ", findDCItem);
    }

    itemSelected.in_dc_items[0]["jw_yield_per"] = productMaster[currentProductIndex].jw_yield_per;
    itemSelected.in_dc_items[0]["yield_per"] = productMaster[currentProductIndex].yield_per;
    //itemSelected.qty = ,
    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    itemSelected.net_amt = 0.00;
    if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
      itemSelected.net_amt = parseFloat(itemSelected.qty) * parseFloat(itemSelected.unit_price);
    }
    else {
      itemSelected.net_amt = 0.00;
    }

    console.log("Selected CUSTOMER SHIPPING GST CODE : ", stateIdSelectShip);
    console.log("CURRENTLY SELECTED BUSINESS ADDR GST : ", currSelBusinessAddlRef);
    var businessList = localStorage.getItem("business");
    var businessObj = JSON.parse(businessList);
    console.log("BUSINESS LIST OBJECT : ", businessObj);
    var selBusiness = localStorage.getItem("selected_business");
    var selBusinessObj = JSON.parse(selBusiness);
    console.log("SELECTED BUSINESS : ", selBusinessObj);
    var findBusiness = businessObj.find(element => element.value === parseInt(selBusinessObj.value));
    console.log("**************** findBUSINESS value ++++++++++++ : ", findBusiness);
    if (findBusiness !== undefined) {
      console.log("SELECTED BUSINESS INFO 10001 : ", findBusiness);
    }
    if (findBusiness && parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
      itemSelected.cgst_per = productMaster[currentProductIndex].cgst_per;
      if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
        itemSelected.cgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cgst_per / 100);
      }
      else {
        itemSelected.cgst_amt = 0.00;
      }
      itemSelected.sgst_per = productMaster[currentProductIndex].sgst_per;
      if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
        itemSelected.sgst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.sgst_per / 100);
      }
      else {
        itemSelected.sgst_amt = 0.00;
      }
    }
    else {
      itemSelected.igst_per = productMaster[currentProductIndex].igst_per;
      if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
        itemSelected.igst_amt = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.igst_per / 100);
      }
      else {
        itemSelected.igst_amt = 0.00;
      }
    }
    //igst_amt: 0.00,
    itemSelected.cess_per = productMaster[currentProductIndex].cess_per;
    if (!isNaN(parseFloat(itemSelected.qty)) && !isNaN(parseFloat(itemSelected.unit_price))) {
      itemSelected.cess_amt = (parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.qty) * (itemSelected.cess_per / 100)).toFixed(2);
    }
    else {
      itemSelected.cess_amt = 0.00;
    }
    //cess_amt: 0.00,
    //if ( parseInt(stateIdSelectShip.gst_code) === parseInt(currSelBusinessAddlRef.current.slice(0,2)) ) {
    if (findBusiness && parseInt(stateIdSelectShip.gst_code) === parseInt(findBusiness.gstin.slice(0, 2))) {
      itemSelected.total_amt = parseFloat(itemSelected.net_amt) + (parseFloat(itemSelected.cgst_amt) + parseFloat(itemSelected.sgst_amt) + parseFloat(itemSelected.igst_amt) + parseFloat(itemSelected.cess_amt));
    }
    else {
      itemSelected.total_amt = parseFloat(itemSelected.net_amt) + (parseFloat(itemSelected.igst_amt) + parseFloat(itemSelected.cess_amt));
    }
    //itemSelected.notes ="";
    //itemSelected.product.label = e.label;

    /*
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.product_id,
      product_name: e.product_name,
      stock_id: e.stock_id,
      unit_cost: e.unit_cost,
      //cgst: e.cgst,
      //sgst: e.sgst,
      //igst: e.igst,
    };
    itemSelected.product = productNew;
    */

    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    /*
    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.igst_perc = productMaster[currentProductIndex].igst;
    itemSelected.product_type = productMaster[currentProductIndex].product_type;
    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    if ( (itemSelected.quantity && productMaster[currentProductIndex].unit_price) && parseFloat(productMaster[currentProductIndex].unit_price) !== isNaN && itemSelected.quantity) {
      itemSelected.total_item_price = productMaster[currentProductIndex].unit_price * itemSelected.quantity;
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    itemSelected.total_price =
      parseFloat(itemSelected.total_item_price) +
      parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);
    console.log("Modified Additional Notes : ", itemSelected.addl_notes);

*/
    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
    setIsEditable(false);

    fetchInDCItemList(currentId);

    sumDelivery(newitems, stateIdSelectShip);
  };

  /*
  const searchActionFunc = (json) => {
    setOptionsSearch(json.detail.res_data.district_list);
    setIsLoading(false)
    console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
  }
  */

  const customerSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);

      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(
        customer_info.detail.res_data.customer_info
      )) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues(customer_info.detail.res_data.customer_info);
      if (gstBillStatus) {
        if (!customer_info.detail.res_data.customer_info.gst_number || customer_info.detail.res_data.customer_info.gst_number === '0') {
          setAlertPurpose("gst_validate");
          setAlertTitle("Warning");
          setAlertBody("Customer GST not available, needs to update customer detail. \nPress Ok to update GST.\nPress Cancel to continue Delivery without GST");
          setShowAlert(true);
        }
      }
      //setIsLoading(true);

      console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
      setStateIdSelect({
        value: customer_info.detail.res_data.customer_info["state_id"],
        label: customer_info.detail.res_data.customer_info["state_name"],
      });
      console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
      setDistrictIdSelect({
        value: customer_info.detail.res_data.customer_info["dist_id"],
        label: customer_info.detail.res_data.customer_info["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      console.log("FORMVALUES 2 : ", formValues);
    }
  };

  const getCustomerDetails = async (customerId) => {
    var retState = false;
    //console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    //if (selected.length > 0) {
    //setSearchedCompany(selected[0])
    //var customerId = passedValue.customer_id;

    const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${customerId}`);
    const customer_info = await response.json();
    retState = true;
    console.log("CuSTOMER INFO : ", customer_info.detail.res_data.customer_info);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }

    //setFormValues(customer_info.detail.res_data.customer_info);

    formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
    formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
    formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
    formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
    formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
    formValues["gst_number"] = customer_info.detail.res_data.customer_info.gst_number;
    formValues["phone"] = customer_info.detail.res_data.customer_info.phone;
    formValues["email"] = customer_info.detail.res_data.customer_info.email;
    formValues["website"] = customer_info.detail.res_data.customer_info.website;
    formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
    formValues["address2"] = customer_info.detail.res_data.customer_info.address2;

    formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
    formValues["primary_number"] = customer_info.detail.res_data.customer_info.primary_number;
    formValues["secondary_number"] = customer_info.detail.res_data.customer_info.secondary_number;

    if (state.action === "edit" || state.action === "view") {
      console.log(
        "######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%"
      );
      setCustomerSearchText(customer_info.detail.res_data.customer_info.customer_name);
      //setIsLoading(true);
    }

    /*
    if (!customer_info.detail.res_data.customer_info.gst_number && !gstBillStatus){
      setAlertPurpose('failed');
      setShowAlert(true);
      setAlertDialogBody("Please enter GST Number");
      setAlertDialogTitle("CONFIRMATION!");
    }
    */

    console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
    setStateIdSelect({
      value: customer_info.detail.res_data.customer_info["state_id"],
      label: customer_info.detail.res_data.customer_info["state_name"],
    });
    console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
    setDistrictIdSelect({
      value: customer_info.detail.res_data.customer_info["dist_id"],
      label: customer_info.detail.res_data.customer_info["district_name"],
    });

    //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
    //setFormValues({...formValues, ["pincode"]: "641654"});

    console.log("FORMVALUES 2 : ", formValues);
    //}
    return [retState, customer_info.detail.res_data.customer_info];
  };

  /*
  const getExpectedDCNumber = async () => {
    var retState = false;
    var retExpectedDCNumber = 0;
    var retState = false;
    var prodRes = {};
    console.log("getExpectedInvNumber called ... ");

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`};
      var res = await axios.get(client.domain + "/getExpectedInvNumber", { headers }, { withCredentials: false });
      console.log("Response from server getExpectedInvNumber : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getExpectedInvNumber : ", res.data.detail.data);
        retState = true;
        retExpectedInvNumber = res.data.detail.data.dc_sno;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
        }
      }
    }
    return [retState, retExpectedInvNumber];
  }
*/

/*
  const searchHandleFunc = async (query) => {
    setIsLoading(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.customer_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.customer_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    / *
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    * /
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  }
*/
  console.log("FORM VALUES Before rendering :", formValues);

  
  //var amount_discount = 0.0;
  //var sum_rounded_off = 0.00;

  const sumDelivery = (items_pass, selectStateIdInfo) => {
    console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items_pass.length);
    setToRender(false);
    sum_product_cost_ref.current = 0.00;
    sum_product_cgst_ref.current = 0.00;
    sum_product_sgst_ref.current = 0.00;
    sum_product_igst_ref.current = 0.00;
    sum_product_cess_ref.current = 0.00;
    sum_product_total_ref.current = 0.00;
    items_pass.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.net_amt);
    console.log("SELECTEd STATE ID VALUE at sumDelivery: ", selectStateIdInfo);

    sum_product_cost_ref.current = sum_product_cost_ref.current + parseFloat(item.net_amt);
    if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectStateIdInfo.gst_code) ) {
      console.log("SAME STATE MOVE SO CALCUALTE CGST AND SGST AMOUNT");
      sum_product_cgst_ref.current = sum_product_cgst_ref.current + item.cgst_amt;
      sum_product_sgst_ref.current = sum_product_sgst_ref.current + item.sgst_amt;
    }
    else {
      console.log("INTER STATE MOVE SO CALCUALTE ONLY IGST AMOUNT");
      sum_product_igst_ref.current = sum_product_igst_ref.current + item.igst_amt;
    }
    sum_product_cess_ref.current = sum_product_cess_ref.current + parseFloat(item.cess_amt);
    sum_product_total_ref.current = sum_product_total_ref.current + parseFloat(item.total_amt);
    total_items_ref.current = total_items_ref.current + 1;
  });

  console.log("PRODUCT TOTAL FOR DELIVERY : ", sum_product_cost_ref.current);
  console.log("SELECTED BUSINESS GST CODE : ", JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2));
  console.log("SELECTED SATTE CODE AT SUM OF DELIVERY : ", selectStateIdInfo.gst_code);
  if ( parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(selectStateIdInfo.gst_code) ) {
    console.log("(CGST + SGST) TOTAL FOR DELIVERY : ", (sum_product_cgst_ref.current + sum_product_sgst_ref.current));
  }
  else {
    console.log("(IGST) TOTAL FOR DELIVERY : ", sum_product_igst_ref.current);
  }
  console.log("FINAL TOTAL FOR DELIVERY : ", sum_product_total_ref.current);

  setToRender(true);
  }

  /*
  console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items.length);
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    var totGst = item.cgst + item.sgst + item.igst;
    console.log("GST  : ", totGst);

    //console.log("GST  : ", item.gst.cgst);
    //console.log("GST  : ", item.gst.sgst);
    //console.log("GST  : ", item.gst.igst);

    console.log("Total Price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + parseFloat(item.total_item_price);

    //sum_product_gst = sum_product_gst + item.gst.totGst;
    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    if (parseFloat(item.igst) !== isNaN) {
      sum_product_igst = sum_product_igst + item.igst;
    }
    else {
      sum_product_igst = sum_product_igst + 0.00;
    }

    //sum_product_total = sum_product_total + item.total_cost;
    //sum_product_total =  sum_product_total +
  });

  console.log("************** SUM PRODUCT COST : ", sum_product_cost);

  //sum_product_cgst = Math.round((sum_product_cgst * 100)/100);
  //sum_product_sgst = Math.round((sum_product_sgst * 100)/100);
  console.log("^^^^^^^^^^^^^^^^^^^^^ IGST BEFORE ROUNDED OFF:", sum_product_igst);
  if (sum_product_igst !== 'NaN' || sum_product_igst !== NaN) {
    //sum_product_igst = Math.round((sum_product_igst * 100)/100);
  }
  else {
    sum_product_igst = 0.00;
  }
  console.log("************** CGST : ", sum_product_cgst);
  console.log("************** SGST : ", sum_product_sgst);
  console.log("************** IGST : ", sum_product_igst);
  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;
  console.log("************** SUM PRODUCT TOTAL 1 : ", sum_product_total);

  sum_product_total = sum_product_total - amount_discount;
  console.log("************** SUM PRODUCT TOTAL 2 : ", sum_product_total);
  //sum_product_total = Math.round((sum_product_total + Number.EPSILON)*100)/100;

  console.log("Tot number of items : ", total_items);
  console.log("Sum of Tot Product Cost : ", sum_product_cost);

  console.log("Sum of Tot CGST : ", sum_product_cgst);
  console.log("Sum of Tot SGST : ", sum_product_sgst);
  console.log("Sum of Tot iGST : ", sum_product_igst);

  console.log("Sum of Tot GST : ", sum_product_gst);

  console.log("Sum of total cost : ", sum_product_total);

  //var tmp_val2 = sum_product_total - Math.round(sum_product_total);
  var tmp_val2 = Math.round(sum_product_total) - sum_product_total;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 1 : ", tmp_val2);
  sum_rounded_off = Math.round((tmp_val2 + Number.EPSILON) * 100) / 100;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 2 : ", sum_rounded_off);



  console.log("Currently selected district : ", districtIdSelect);
  console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  console.log("Currently selected state : ", stateIdSelect);
  console.log("+++++++++++++++ Customer search text ^^^^^^^^^^^^^^^^^^^^  :", customerSearchText);
  console.log("Searched customer <<<<<<<<<<<<<<<<<<< : ", searchedCustomer);
  */
  const [addrCopyCheck, setAddrCopyCheck] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handleCopyToShipping = async (e) => {
    console.log("handleCopyToShipping called : ", e);
    console.log("handleCopyToShipping called CHECKED : ", e.target.checked);

    //var contactPersontmp = formValues["contact_person"];
    //var contactPersonAssign = "";
    //var contactNumbertmp = formValues["contact_number"];
    //var contactNumberAssign = "";
    //setFormValues({...formValues, ["addrCopyCheck"]: e.currentTarget.checked});
    if (e.target.checked === true) {
      if (!formValues.contact_person) {
        formValues["shipContactName"] = formValues["customer_name"];
      }
      else {
        formValues["shipContactName"] = formValues["contact_person"];
      }

      if (!formValues.contact_number) {
        formValues["shipContactNumber"] = formValues["phone"];
      }
      else {
        formValues["shipContactNumber"] = formValues["contact_number"];
      }
      formValues["shipAddress1"] = formValues["address1"];
      formValues["shipAddress2"] = formValues["address2"];
      setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });
      //setFormValues(e.target.checked);
    }
    setAddrCopyCheck(e.target.checked);
    setRefresh(true);
  };

  /*
  var contactPersontmp = formValues["contact_person"];
  var contactPersonAssign = "";

  console.log("Customer contact name before check : ", contactPersontmp);

  console.log("Customer contact name length before check : ", contactPersontmp.length);

  if (contactPersontmp.length > 0) {
    contactPersonAssign = contactPersontmp;
  } else {
    var customer_name_tmp = "";
    var customer_id_tmp = 0;

    console.log("searched customer array : ", searchedCustomer);
    console.log("searched customer length : ", searchedCustomer.length);
    console.log("searched customer length Object : ", Object.keys(searchedCustomer).length);
    //if (!searchedCustomer || searchedCustomer.length < 1) {
    if (Object.keys(searchedCustomer).length < 1) {
      console.log("Inside IF condition");
      customer_name_tmp = customerSearchText;
      customer_id_tmp = 0;
    } else {
      console.log("Inside ELSE conditiob");
      customer_name_tmp = searchedCustomer.label;
      customer_id_tmp = searchedCustomer.value;
    }

    console.log("Customer name to submit : ", customer_name_tmp);

    contactPersonAssign = customer_name_tmp;
  }

  var contactNumbertmp = formValues["contact_number"];
  var contactNumberAssign = "";

  console.log("Contact number : ", contactNumbertmp);
  if (contactNumbertmp) {
    contactNumberAssign = contactNumbertmp;
  } else {
    contactNumberAssign = formValues["phone"];
  }

  formValues["shipContactName"] = contactPersonAssign;
  formValues["shipContactNumber"] = contactNumberAssign;
  formValues["shipAddress1"] = formValues["address1"];
  formValues["shipAddress2"] = formValues["address2"];
  //formValues["billPincode"] =  formValues["pincode"];
  //setFormValues({...formValues, ["billContactName"]: formValues["contact_person"]});
  //setFormValues({...formValues, ["billContactNumber"]: formValues["contact_number"]});
  //setFormValues({...formValues, ["billAddress1"]: formValues["address1"]});
  //setFormValues({...formValues, ["billAddress2"]: formValues["Address2"]});
  setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });

  //setBillDistrictIdSelect(districtIdSelect);
}
};
*/
  const [addrCopyBillCheck, setAddrCopyBillCheck] = useState(false);

  const handleCopyToBilling = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setAddrCopyBillCheck(e.target.checked);

    if (e.target.checked === true) {
      formValues["billContactName"] = formValues["shipContactName"];
      formValues["billContactNumber"] = formValues["shipContactNumber"];
      formValues["billAddress1"] = formValues["shipAddress1"];
      formValues["billAddress2"] = formValues["shipAddress2"];
      setFormValues({ ...formValues, ["billPincode"]: formValues["shipPincode"] });
    }
    setRefresh(true);
  };

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);
    setShowAlert(true);
    setAlertPurpose('item_delete_confirmation')
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to delete this Item");
    
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      setSelectedRowId(curSelId);

      /*
      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }
  };

  const getInwardDcList = async (customer_id_pass) => {
    console.log("getInwardDcList called : ", customer_id_pass);
    var retStateLoad = false;
    var dcRet = [];
    var summaryRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        bid: currSelBusinessRef.current,
        brid: currBranchRef.current || 0,
        customer_id: customer_id_pass
      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getInwardDCForOutDC", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ",res.data.detail.data );
        //setDcList(res.data.detail.data.customer_dc);
        //setDcSummary(res.data.detail.data.customer_dc_summary[0]);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        dcRet = res.data.detail.data.customer_dc;
        //summaryRet = res.data.detail.data.customer_dc_summary;
        retStateLoad = true;
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, dcRet];
  };

  const handleDCEdit = async (e) => {
    var retState1 = false;
    var inwardDCItemsRet = [];
    
    //setItemCheckState(false);
    //setSelectedRadio(-1);

    console.log("handle dc edit called ....: ", e);
    console.log("EVENT TARGET : ", e.target);
    console.log("EVENT CURRENT TARGET :", e.currentTarget);
    console.log("EVENT CURRENT TARGET NAME : ", e.currentTarget.name);
    console.log("EVENT CURRENT TARGET VALUE : ", e.currentTarget.value);
    console.log("EVENT CURRENT TARGET ID : ", e.currentTarget.id);
   
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected EDIT element name : ", nameOfElement);
      console.log("Current SELECTED EDIT id : ", curSelId);
      console.log("CUR SELECTED EDIT VALUE : ", items[curSelId]);

      setSelectedRowId(curSelId);
    }
    if ( dcList.length < 1 ) {
    
      var [retStateInward, inwardDcList] = await  getInwardDcList(customerIdSelect.value);
      if ( retStateInward ) {
        console.log("INWARD DC LIST : ", inwardDcList);
        setDcList(inwardDcList);
        setToRender(true);
      } 
    }
    else {
      inwardDcList = dcList;
    }
    if ( state.action === 'view' || state.action === 'edit' ) {
      inwardDcList.forEach(async(inDcInd, idx) =>{
        if ( inDcInd.indc_id === items[curSelId].indc_id ) {
          setSelectedRadio(idx);
          [retState1, inwardDCItemsRet] = await getInwardDCItemsByDcId(items[curSelId].indc_id, curSelId, curSelId);
          console.log("INWARD DC ITEM LIST <<<<<<<<<<<<<<<<>>>>>>>>>>>>> : ", inwardDCItemsRet);
          var newDcItemList = [];
          inwardDCItemsRet.forEach((dcItem) => {
            var newDcItem = dcItem;
            console.log("DC ITEM $$$$$$$$$$$$$$$$ : ", dcItem);
            console.log("CURRETLY SELECTED ITEM &&&&&&&&&&&&&& : ", items[curSelId]);
            newDcItem.dc_item_check = false;
            if ( state.action === 'add') {
              newDcItem.qty_used = newDcItem.indc_qty_unused;
              newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
            }
            else {
              if ( items[curSelId].indc_id === newDcItem.indc_dc_id && items[curSelId].indc_item_id === newDcItem.indc_item_id ){
                newDcItem.qty_used = items[curSelId].qty_used;
                newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
                newDcItem.dc_item_check = true;
                setDisableState(true);
              }
              else {
                newDcItem.qty_used = items[curSelId].qty_used;
                newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
              }
            }
            newDcItemList.push(newDcItem);
          })
          console.log("NEW DC ITEM ARRAY : ", newDcItemList);
          setDcItemList(newDcItemList);
        }
        /*
        else {
          var [retState2, inwardDCItemsRet2] = await getInwardDCItemsByDcId(inwardDcList[0].indc_id, curSelId);
          console.log("INWARD DC ITEM LIST <<<<<<<<<<<<<<<<>>>>>>>>>>>>> : ", inwardDCItemsRet2);
          var newDcItemList2 = [];
          inwardDCItemsRet.forEach((dcItem) => {
            var newDcItem = dcItem;
            newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
            console.log("DC ITEM $$$$$$$$$$$$$$$$ : ", dcItem);
            console.log("CURRETLY SELECTED ITEM &&&&&&&&&&&&&& : ", items[curSelId]);

            newDcItemList2.push(dcItem);
          })
          console.log("NEW DC ITEM ARRAY ELSE CONDITION : ", newDcItemList2);
          setDcItemList(newDcItemList2); 
        }
        */
      })
    }
    else {
      var selectedIndexTmp = 0;
      if (  selectedRadio < 0 ) {
        setSelectedRadio(0);
        selectedIndexTmp = 0;
      }
      else {
        selectedIndexTmp = selectedRadio;
      }
      [retState1, inwardDCItemsRet] = await getInwardDCItemsByDcId(inwardDcList[selectedIndexTmp].indc_id, curSelId);
          console.log("INWARD DC ITEM LIST <<<<<<<<<<<<<<<<>>>>>>>>>>>>> : ", inwardDCItemsRet);
          var newDcItemList = [];
          inwardDCItemsRet.forEach((dcItem) => {
            var newDcItem = dcItem;
            newDcItem.jw_yield_per = items[curSelId].jw_yield_per;
            console.log("DC ITEM $$$$$$$$$$$$$$$$ : ", dcItem);
            console.log("CURRETLY SELECTED ITEM &&&&&&&&&&&&&& : ", items[curSelId]);
            //newDcItem.dc_item_check = false;
            /*
            if ( state.action === 'add' && !newDcItem.dc_item_check ) {
              newDcItem.qty_used = newDcItem.indc_qty_unused;
            }
            / *
            else if ( state.action === 'add' && newDcItem.dc_item_check ) {
              newDcItem.qty_used = items[curSelId].qty;
            }
            * /
            else {
              if ( items[curSelId].indc_dc_id === newDcItem.indc_dc_id && items[curSelId].indc_item_id === newDcItem.indc_item_id ){
                newDcItem.qty_used = items[curSelId].qty;
                //newDcItem.dc_item_check = true;
              }
            }
            */
            if ( "in_dc_items" in items[curSelId] ) {
            items[curSelId].in_dc_items.forEach((indcitem) =>{
              if ( indcitem.indc_dc_id === newDcItem.indc_dc_id && indcitem.indc_item_id === newDcItem.indc_item_id ){
                newDcItem.qty_used = indcitem.qty_used;
                newDcItem.jw_yield_per = indcitem.jw_yield_per;
                newDcItem.dc_item_check = true;
                setDisableState(true);
              }
            })
            }
            newDcItemList.push(newDcItem);
          })

          console.log("NEW DC ITEM ARRAY : ", newDcItemList);
          setDcItemList(newDcItemList);
    }
    setShowDCDialog(true);
  }

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  /*

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };
  */

  const handleGSTBillStatus = async (e) => {
    console.log("HandleGSTBillStatus called : ", e);
    console.log("target checked : ", e.target.checked);

    var itemModNew = [];
    items.map((item) => {
      console.log("IND ITEM : ", item);

      var cgstCalculate = 0.0;
      var sgstCalculate = 0.0;
      var igstCalculate = 0.0;

      if (e.target.checked) {
        //if (item["product"] !== undefined) {
        console.log("CGST FROM EXISTING : ", item.cgst_perc);
        console.log("TOTAL ITEM PRICE FROM EXISTING : ", item.total_item_price);
        cgstCalculate = (item.total_item_price * parseFloat(item.cgst_perc)) / 100;
        sgstCalculate = (item.total_item_price * parseFloat(item.sgst_perc)) / 100;
        igstCalculate = (item.total_item_price * parseFloat(item.igst_perc)) / 100;
        //}
      }
      item.cgst = cgstCalculate;
      item.sgst = sgstCalculate;
      item.igst = igstCalculate;

      console.log("CALCULATED CGST : ", cgstCalculate);
      console.log("CALCULATED SGST : ", sgstCalculate);
      console.log("CALCULATED IGST : ", igstCalculate);
      item.total_price =
        item.total_item_price + cgstCalculate + sgstCalculate + igstCalculate;
      //item.total_price = Math.round(item.total_price+'e' + 2) + 'e-' + 2

      console.log("CALCULATED TOTAL : ", item.total_price);

      itemModNew.push(item);
    });

    setGstBillStatus(e.target.checked);
    setItems(itemModNew);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit clicked : ", e);
    console.log("Handle Edit clicked current target ID: ", e.currentTarget.id);

    setIsReadOnly(false);
    //setDisableState(false);
  };

  const getProductForLoad = async (productIdInput, prodIdIdx) => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE' };
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + productIdInput, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        //setFormValues(res.data.detail.data);
        /*
        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }
        */
        //setProductCompositionList(res.data.detail.data.composite);
        console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
        console.log("SELECTED PROD DATA : ", items[prodIdIdx]);

        var selectedProdTmp2 = items[prodIdIdx];

        selectedProdTmp2.composition = res.data.detail.data.composite;

        var newItemsTmp2 = items.slice();
        newItemsTmp2[prodIdIdx] = selectedProdTmp2;
        setItems(newItemsTmp2);
        //setProductCompositionList(res.data.detail.data.composite);
        /*
        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
        */
        //setProdDetLoaded(true);
        //setToRender(true);
        //setEditAction(false);
        setShowInfoConfigDialog(true);
        setShowItemConfigDialog(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, prodRes];
  }

  const handleInfoCompositeEdit = async (e, fromWhere) => {
    setEditAction(true);
    //dialogFromRef.current = "pkg_info";
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id, ", name : ", name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", fromWhere);

    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);

      //setFormValues({ ...formValues, pkg_info: ""});
      console.log("ALREDY EXISTING NOTES VALUE : ", items[productIdInput].notes);
      if ( fromWhere === 'notes') {
      if (items[productIdInput].notes ) {
         setFormValues({ ...formValues, called_from: fromWhere, dialog_value: items[productIdInput].notes});
      }
      else {
        setFormValues({ ...formValues, called_from: fromWhere, dialog_value: ""});
      }
    }
    else {
      if (items[productIdInput].pkg_info ) {
        setFormValues({ ...formValues, called_from: fromWhere, dialog_value: items[productIdInput].pkg_info});
     }
     else {
       setFormValues({ ...formValues, called_from: fromWhere, dialog_value: ""});
     }
    }
      setShowInfoConfigDialog(true);

      setSelectedCompositionId(productIdInput);
      
    }

    var selectedItem = items[productIdInput];

    selectedItem.enableState = false;
    selectedItem.editAction = true;
    
    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

  };
  const handleProductCompositeEdit = async (e, rowData) => {
    //dialogFromRef.current = "notes";
    setEditAction(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id, ", name : ", name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", rowData);

    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);

      /*
      if (items[productIdInput].addl_notes) {
        setFormValues({ ...formValues, addl_notes: items[productIdInput].addl_notes });
      }
      else {
        setFormValues({ ...formValues, addl_notes: "" });
      }
      */
      setFormValues({ ...formValues, addl_notes: ""});
      setShowItemConfigDialog(true);

      setSelectedCompositionId(productIdInput);
      /*
      let sliced = items.splice(productIdInput, 1);
      console.log("Edit item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }


    // get composition from api call
    //https://api.erp.avinasoft.in/ims/getProduct?id=6


    var selectedItem = items[productIdInput];

    //selectedItem.addl_notes = ;
    //var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    //if ( retStateProdBuy ) {
    //setProductList(buyProdArr);
    //}
    //var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    //if ( selectedItem.composition.length < 1 ) { 
    //await getProductForLoad(productIdPass, productIdInput);
    //}
    //else {
    //  setShowConfigDialog(true);
    //}

    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

    /*
    if (!selectedItem.hasOwnProperty("composition")) {
      console.log("********************** Product composition doesn't exist +++++++++++++++++ ");
      //selectedItem.composition = [];
    }
    else {
      console.log("******************* Product composition already exist in items ++++++++++++++");
    }


    const newitems = items.slice();
    newitems[productIdInput] = selectedItem;
    setItems(newitems);
    */
  };


  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handle save called : ", e);
    console.log("Handle save clicked current target ID: ", e.currentTarget.id);
  };

  const handleKeyDown = async (e) => {
    console.log("handle key down called : ", e);
    return e.keyCode !== 190;
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, dc_date: e.target.value });
  };

  const onChangeDeliveryDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, delivery_note_date: e.target.value });
  };

  const onChangeRefDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, ref_date: e.target.value });
  };

  const onChangeBuyerDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, buyer_order_date: e.target.value });
  };

  const onChangeRRDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, bol_date: e.target.value });
  };


  const onChangeDueDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    //setFormValues({ ...formValues, due_date: e.target.value });
  };

  const onChangeIssueDatePicker = async(e) => {
    console.log("onChangeIssueDatePicker called ...", e);

    console.log("Date onChangeIssueDatePicker called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, issue_date: e.target.value});
  }

  const onChangeIssueTimePicker = async(e) => {
    console.log("onChangeIssueTimePicker called ...", e);

    console.log("Time onChangeIssueTimePicker called ...", e.target.value);

    setFormValuesAddl({ ...formValuesAddl, issue_time: e.target.value});
  }
  /*
    const newItemHandleComposition = async (event) => {
      event.preventDefault();
      console.log("New item addition button clicked : ", event);
      console.log("New item addition button clicked target :", event.target);
      console.log("New item addition button clicked currentTarget : ", event.currentTarget);
  
      /*
      var { name, id } = event.currentTarget;
      var currentId = name.substring(name.lastIndexOf("-") + 1);
      var nameOfElement = name.substring(0, name.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current id : ", currentId);
  
      var itemSelected = items[currentId];
  
      //console.log("Product: ", itemSelected.product);
      console.log("Product Name : ", itemSelected.name);
      console.log("Price : ", itemSelected.unit_price);
      console.log("Product cost : ", itemSelected.total_item_price);
      console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
      console.log("Total cost : ", itemSelected.total_price);
      console.log("Qty : ", itemSelected.quantity);
  
      console.log("Product Info : ", productMaster);
      const currentProductIndex = productMaster.findIndex(
        (productInd) => productInd.name === itemSelected.product_name);
      console.log("Currently selected row product id : ", currentProductIndex);
      if (currentProductIndex < 0) {
        itemSelected.error = "Please select product from list";
      }
      * /
      //const newProductCompositionList = productCompositionList.slice();
      var selectedSellProdItem = items[selectedCompositionId];
      
      //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
      let itemNewComposite = {
        id: 0,
        product_id: 0,
        product_name: "",
        quantity: 0.00,
      };
      //selectedSellProdItem.composition = [];
      /*
      if ( selectedSellProdItem.composition.length < 1 ) {
        selectedSellProdItem.composition = [];
      }
      * /
      selectedSellProdItem.composition.push(itemNewComposite);
      //selectedSellProdItem.composition.push();
      //newProductCompositionList.push(itemNew);
      //setProductCompositionList(newProductCompositionList);
      //itemNew.composition.push();
      //itemsNew[selectedCompositionId].composition.push(itemNewComposite);
      const itemsNew = items.slice();
      itemsNew[selectedCompositionId] = selectedSellProdItem;
      console.log("modified with composition array : ", selectedSellProdItem);
      console.log("item array : ", itemsNew);
      setItems(itemsNew);
    };
    */

  const handleProductSelectComposition = (e, item, idx) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element full : ", item);
    console.log("SELECTED element idx : ", idx);
    //var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
    // console.log("Product Info : ", productMaster);

    var itemSelected = items[idx];
    console.log("Passed element item : ", item);
    console.log("Item selected : ", itemSelected);


    items[selectedCompositionId].composition.push();
    /*
    if (itemSelected.product) {
      console.log("Product: ", itemSelected.product);
    }
    */
    //console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);

    /*
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productCompositionList.findIndex(
      (productInd) => productInd.name === e.label
    );
    */

    //console.log("Currently selected row product id : ", currentProductIndex);
    //console.log("Currently selected row product value : ", productCompositionList[currentProductIndex]);

    //itemSelected.product_id = e.id;
    //itemSelected.product_name = e.name;
    //itemSelected.uom = e.uom;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      id: e.id,
      name: e.name,
    };
    console.log("Product Value : ", productNew);
    itemSelected.product = productNew;
    */

    /*
    const newProductCompositionList = productCompositionList.slice();
    newProductCompositionList[currentId] = itemSelected;
    setProductCompositionList(newProductCompositionList);
    */
    //setProductMaster(newSellProdArr);


  };
  const handleItemDeleteComposition = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", productCompositionList[curSelId]);

      let sliced = productCompositionList.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newProductCompositionList = productCompositionList.slice();
      setProductCompositionList(newProductCompositionList);

    }
  };

  const handleItemCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowItemConfigDialog(false);
  };

  const handleItemSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowItemConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    //selectedItem.compositionUpdate = true;
    //selectedItem.is_product_cc = true;
    console.log("SELECTED ITEMS<<<<<<<<<<<<:", selectedItem);

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  };

  const handleInfoCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowInfoConfigDialog(false);
  };

  const handleInfoSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowInfoConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    
    if ( formValues.called_from === 'notes') {
      selectedItem.notes = formValues.dialog_value;
    }
    else {
      selectedItem.pkg_info = formValues.dialog_value;
    }
    //selectedItem.compositionUpdate = true;
    //selectedItem.is_product_cc = true;
    console.log("SELECTED ITEMS<<<<<<<<<<<<:", selectedItem);

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  };
  const handleCloseCompositionProduct = async () => {
    console.log("handle close composition  product called ...");
    navigate(0);

  }

  const handleOnItemChangeComposition = (e, fromWhere) => {
    //e.preventDefault();
    console.log("handleOnItemChangeComposition called : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    //if ( fromWhere === 'pkg_info') {
      //itemSelected.pkg_info = value;
    //}
    //else {
      itemSelected.notes = value;
      setFormValues({ ...formValues, addl_notes: value });
    //}
    //console.log("selected composition for M grade products : ", itemSelected.composition);

    //console.log("SELECTED COMPOSITION COMPONENt element : ", itemSelected.composition[currentId]);

    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    //setFormValues({ ...formValues, [name]: value });

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === itemSelected.product_name);

    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      //const newProductCompositionList = productCompositionList.slice();
      //newProductCompositionList[currentId] = itemSelected;
      //setProductCompositionList(newProductCompositionList);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);


    /*
    itemSelected.product = {
      id: productList[currentProductIndex].id,
      name: productList[currentProductIndex].name,
      
    };
    */
    //itemSelected.addl_notes = productMaster[currentProductIndex].addl_notes;
    /*
    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");

      // const regex =  /^[0-9\b]+$/;
      /*
       const numericRegExp = /^\d*\.?\d*$/;
       //if (numericRegExp.match(e.target.value)) {
       if (e.currentTarget.value.match(numericRegExp)) {
         itemSelected[nameOfElement] = e.currentTarget.value;
       }
       * /
      //const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //itemSelected[nameOfElement] = e.currentTarget.value.replace(/^0+/, '');
        itemSelected.composition[currentId].quantity = value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    */

    //const newProductCompositionList = productCompositionList.slice();
    //newProductCompositionList[currentId] = itemSelected;
    //setProductCompositionList(newProductCompositionList);
    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    console.log("NEW ITEMS TMP<<<<<<<<<<<<< :", newItemsTmp);
    setItems(newItemsTmp);
  };

  const handleOnInfoChangeComposition = (e, fromWhere) => {
    //e.preventDefault();
    console.log("handleOnItemChangeComposition called : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    //if ( fromWhere === 'pkg_info') {
      //itemSelected.pkg_info = value;
    //}
    //else {
      /*
      if ( fromWhere === 'notes') {
        itemSelected.notes = value;
      }
      else {
        itemSelected.pkg_info = value;
      }
      */
      setFormValues({ ...formValues, dialog_value: value });
    //}
   
    console.log("Product Name : ", itemSelected.product_name);
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === itemSelected.product_name);
   /*
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      return 0;
    } else {
      itemSelected.error = "";
    }
    */

    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    console.log("NEW ITEMS TMP<<<<<<<<<<<<< :", newItemsTmp);
    setItems(newItemsTmp);
  };

  const deleteRowItem = async(e) => {
    console.log("deleteRowItem called : ", e);

    let sliced = items.splice(selectedRowId, 1);
    console.log("Removed item from order items: ", sliced);
    var newItem = items.slice();
    setItems(newItem);
  }

  const handleAlertOK = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if (alertPurpose === 'success') {
      navigate("/listDeliveryNotes");
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'confirmation') {
      //navigate(0);
      formValues["customer_name"] = "";
      formValues["customer_disp_name"] = "";
      formValues["gst_number"] = "";
      formValues["phone"] = "";
      formValues["primary_number"] = "";
      formValues["secondary_number"] = "";
      formValues["contact_person"] = "";
      formValues["contact_number"] = "";
      formValues["email"] = "";
      formValues["address1"] = "";
      formValues["address2"] = "";
      //formValues["distId"] = "";
      //formValues["stateId"] = "";
      setFormValues({ ...formValues, ["pincode"]: "" });
    }
    else if (alertPurpose === 'gst_validate') {
      navigate('/listCustomers');
    }
    else if (alertPurpose === 'warning-msg'){
      navigate(0);
    }

    else if (alertPurpose === 'warning-items'){
      navigate(0);
    }

    else if (alertPurpose === 'warning-total') {
      navigate(0);
    }


    else if (alertPurpose === 'item_delete_confirmation') {
      deleteRowItem();
      setAlertPurpose('init');
    }
  };

  const handleCloseDialog = () => {
    console.log("handleCloseDialog called ....");
    setShowAlert(false);

    if (alertPurpose === 'success') {
      navigate("/listDeliveryNotes");
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'gst_validate') {
      console.log("Inside Cancel for gst validate");
      setGstBillStatus(false);
    }

    else if (alertPurpose === 'item_delete_confirmation') {
      setAlertPurpose('init');
    }
  }
  const handleReset = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    setDistrictIdSelect(optDist[0]);
    //setStateIdSelect(optionsState[0]);
    setAlertPurpose('confirmation');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to Reset  Customer Details and Address");
    setShowAlert(true);

    //navigate(0);
  };

  const handleDateSelect = (e, data) => {
    console.log("handleDateSelect called ...", e);

    setDateIdSelect(e);
  };
  const handlePrintFullOrder = async (e) => {
    console.log("Handle print order clicked : ", e);
    //passedValue.order_id

    e.preventDefault();
    console.log("Handle action print Clicked : ", e);

    console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

    console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var idPass = state.passed_element.id;    
    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDNMfgT2?id=${idPass}`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }


  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
  };

  const sendDataToParent = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if (passedBackValue.hasOwnProperty("business_id")) {
      currSelBusinessRef.current = passedBackValue["business_id"];

      if (passedBackValue.hasOwnProperty("addl")) {
        currSelBusinessAddlRef.current = passedBackValue["addl"];
      }
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);

  }

  const handleCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setCopyCheckState(e.target.checked);

    if ( e.target.checked ) {
      setFormValues({ ...formValues,
        address1_bill: formValues["address1_ship"],
        address2_bill: formValues["address2_ship"],
        pincode_bill: formValues["pincode_ship"]
      });
      setDistrictIdSelectBill(districtIdSelectShip);
      setStateIdSelectBill(stateIdSelectShip);
    }
    setRefresh(true);
  };

  const getPrevAddr = async(customer_id) => {
    var retState = false;
    var prevAddress = {};
    console.log("getPrevAddr called customer id : ", customer_id);

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + `/bms/getPrevDCAddrByCustId?customer_id=${customer_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getPrevAddr : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPrevAddr : ", res.data.detail.data);
        setOrderAddress(res.data.detail.data.prev_address);
        retState = true;
        prevAddress = res.data.detail.data.prev_address;
        //formValues["address1_ship"] = res.data.detail.data.prev_address[0].address1_ship;
        //formValues["address2_ship"] = res.data.detail.data.prev_address[0].address2_ship;
        //formValues["pincode_ship"] = res.data.detail.data.prev_address[0].pincode_ship;
      
        var findStateShip = JSON.parse(localStorage.getItem("states")).find((element) => element.value === res.data.detail.data.prev_address[0].state_id_ship);
        if ( findStateShip !== undefined ) {
          setStateIdSelectShip(findStateShip);
        }

        var findDistrictShip = JSON.parse(localStorage.getItem("districts")).find((element) => element.value === parseInt(res.data.detail.data.prev_address[0].district_id_ship));
        if ( findDistrictShip !== undefined ) {
          console.log("FOUND DISTRICT VALUE : ", findDistrictShip);
          setDistrictIdSelectShip(findDistrictShip);
        }

        /*
        var findCountryShip = JSON.parse(localStorage.getItem("countries")).find((element) => element.value === res.data.detail.data.prev_address[0].country_id_ship);
        if ( findCountryShip !== undefined ) {
          //setcount(findCountryShip);
        }
        */

        
        //formValues["address1_bill"] = res.data.detail.data.prev_address[0].address1_bill;
        //formValues["address2_bill"] = res.data.detail.data.prev_address[0].address2_bill;
        //formValues["pincode_bill"] = res.data.detail.data.prev_address[0].pincode_bill;

        /*
        setFormValues({ ...formValues, 
          address1_ship: res.data.detail.data.prev_address[0].address1_ship,
          address2_ship: res.data.detail.data.prev_address[0].address2_ship,
          pincode_ship: res.data.detail.data.prev_address[0].pincode_ship,
          address1_bill: res.data.detail.data.prev_address[0].address1_bill,
          address2_bill: res.data.detail.data.prev_address[0].address2_bill,
          pincode_bill: res.data.detail.data.prev_address[0].pincode_bill
        });
        */
        

        var findStateBill = JSON.parse(localStorage.getItem("states")).find((element) => element.value === res.data.detail.data.prev_address[0].state_id_bill);
        if ( findStateBill !== undefined ) {
          setStateIdSelectBill(findStateBill);
        }

        var findDistrictBill = JSON.parse(localStorage.getItem("districts")).find((element) => element.value === res.data.detail.data.prev_address[0].district_id_bill);
        if ( findDistrictBill !== undefined ) {
          setDistrictIdSelectBill(findDistrictBill);
        }

        /*
        var findCountryBill = JSON.parse(localStorage.getItem("countries")).find((element) => element.value === res.data.detail.data.prev_address[0].country_id_bill);
        if ( findCountryBill !== undefined ) {
          //setDistrictIdSelectShip(findCountryBill);
        }
        */

        //setAddressFetched(true);
      }
      else {
        console.log("OTHER THAN SUCCESS STATUS CODE : ", res.data.detail.status);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.detail.data);
          if ( error.response.data.detail.detail.data === 'No Data') {
            if ( state.action === 'view') {
              setFormValues({ ...formValues, address1_ship: "", address2_ship: "", pincode_ship: "", address1_bill: "", address2_bill: "", pincode_bill: ""})
              }
            else {
              var suffix_tmp = "";
              if ( docNumberSettingRef.current[0].doc_suffix ) {
                suffix_tmp = docNumberSettingRef.current[0].doc_suffix;
              }
              var gstin_tmp = "";
              console.log("GST IN VALUE at getPrevAddr : ", formValues.gstin);
              console.log("GST IN VALUE at getPrevAddr 2 : ", customerSelectInfoRef.current.gstin);
              
              if ( customerSelectInfoRef.current.gstin === undefined ) {
                gstin_tmp = customerArrRef.current[0].gstin;
              }
              else {
                gstin_tmp = customerSelectInfoRef.current.gstin;
              }
              
              setFormValues({ ...formValues, 
              //gstin: customerArrRef.current[0].gstin,
              //gstin: gstin_tmp,
              dc_no: docNumberSettingRef.current[0].doc_prefix + parseInt(docNumberSettingRef.current[0].sno_generated + 1) + suffix_tmp, 
              dc_sno: docNumberSettingRef.current[0].sno_generated + 1,
              address1_ship: "", address2_ship: "", pincode_ship: "", address1_bill: "", address2_bill: "", pincode_bill: ""
              });
            }
          }
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    return [retState, prevAddress];
  }

  const handleCheckBoxLoadAddr = async (e) => {
    console.log("handleCheckBoxLoadAddr called :", e);
    console.log("handleCheckBoxLoadAddr called CHECKED :", e.target.checked);
    setLoadPrevAddrState(e.target.checked);

    if ( e.target.checked ) {
      //await getPrevAddr(customerIdSelect.value);
      var [retStatePrevAddr, prevAddress] = await getPrevAddr(customerIdSelect.value);
          if ( retStatePrevAddr ) {
            setFormValues({ ...formValues, 
              address1_ship: prevAddress[0].address1_ship,
              address2_ship: prevAddress[0].address2_ship,
              pincode_ship: prevAddress[0].pincode_ship,
              address1_bill: prevAddress[0].address1_bill,
              address2_bill: prevAddress[0].address2_bill,
              pincode_bill: prevAddress[0].pincode_bill
            });
          }
    }
    setRefresh(true);
  };

  const handleAddrLoad = async () => {
    console.log("handleAddrLoad called ...");
  }

  const handleBillableState = async (e) => {
    console.log("handleBillableState called :", e);
    console.log("handleBillableState called CHECKED :", e.target.checked);
    setBillableState(e.target.checked);

    setRefresh(true);
  };

  const getInwardDCItemsByDcId = async(inward_dc_id, passed_selected_row) => {
    var retState = false;
    console.log("getInwardDCItemsByDcId called : ", inward_dc_id);

    var [retStateLoad, inwardDCItemsRet] = await getInwardDcItemList(inward_dc_id);
    if ( retStateLoad ) {
      console.log("<<<<<<<<<<<< getInwardDcItemList RESULT <<<<<<<<<<<<<<< : ", inwardDCItemsRet);
      retState = true;
      var itemArrNew = [];
      inwardDCItemsRet.forEach(function(itemInd){
      var itemIndMod = itemInd;
      if ( state.action === 'add') {
        itemIndMod.qty_used = itemInd.indc_qty_unused;
        itemIndMod.jw_yield_per = items[passed_selected_row].jw_yield_per;
      }
      itemArrNew.push(itemIndMod);
      })

      setDcItemList(itemArrNew);
      setItemCheckState(true);
  }
  return [retState, inwardDCItemsRet];
  }


  const getInwardDCItemsByDcIdForAuto = async(inward_dc_id, dcItemsPass) => {
    var retState = false;
    console.log("getInwardDCItemsByDcId called : ", inward_dc_id);

    var [retStateLoad, inwardDCItemsRet] = await getInwardDcItemList(inward_dc_id);
    if ( retStateLoad ) {
      console.log("<<<<<<<<<<<< getInwardDcItemList RESULT <<<<<<<<<<<<<<< : ", inwardDCItemsRet);
      retState = true;
      var itemArrNew = [];
      inwardDCItemsRet.forEach(function(itemInd){
      var itemIndMod = itemInd;
      if ( state.action === 'add') {
        itemIndMod.qty_used = itemInd.indc_qty_unused;
        //itemIndMod.jw_yield_per = passed_yield_per;
      }
      itemArrNew.push(itemIndMod);
      })
      console.log(">>>>>>>>>>>>>>>>>>>> INWARD DC ITEM ARRAY MODIFIED : ", itemArrNew);
      setDcItemList(itemArrNew);

      var outDCItems = [];
      console.log("OUT DC LIST BEFORE ITERATE : ", dcItemsPass);
      dcItemsPass.forEach((item_tmp) => {
        console.log("out DC items iterated with ind value : ", item_tmp);
      //var firstItemAsDefault = {};
    //var indcItemNew = [];
    var item_tmp_new = {};
    if ( inoutdcReconAutoRef.current ) {
      console.log("inoutdcReconAutoRef check called because condition is passed ...");
      if ( itemArrNew[0] !== null && itemArrNew[0] !== undefined) {
        //firstItemAsDefault = itemArrNew[0];
        //firstItemAsDefault["dc_item_check"] = true;
        //firstItemAsDefault["qty_used"] = item_tmp.qty;
        //firstItemAsDefault["jw_yield_per"] = productMaster[0].jw_yield_per;
        console.log("******************* OUT DC ITEM VALUE FULL FOR IN DC CHANGE ++++++++++++++ : ", item_tmp);
        item_tmp_new = item_tmp;
        item_tmp_new.in_dc_items[0]["dc_item_check"] = true;
        item_tmp_new.in_dc_items[0]["qty_used"] = item_tmp_new.qty;
        if ( item_tmp.jw_yield_per === null ) {
          var findProdItem = itemArrRef.current.find((element) => element.value === item_tmp.item_id);
          if ( findProdItem ) {
            item_tmp_new.in_dc_items[0]["jw_yield_per"] = findProdItem.jw_yield_per;
          }
        }
        else { 
          item_tmp_new.in_dc_items[0]["jw_yield_per"] = item_tmp.jw_yield_per;
        }
        item_tmp_new.in_dc_items[0]["indc_dc_id"] = itemArrNew[0].indc_dc_id;
        item_tmp_new.in_dc_items[0]["indc_item_id"] = itemArrNew[0].indc_item_id;
      }
    }
    //indcItemNew.push(firstItemAsDefault);
    outDCItems.push(item_tmp_new);
    })
    console.log("MODIFIED INWARD DC ITEM LIST FOR AUTO CHECK ...");
    setItems(outDCItems);

    setItemCheckState(true);
  }
  return [retState, inwardDCItemsRet];
  }

  const getInwardDcItemList = async (dc_id_pass) => {
    console.log("getInwardDcItemList called : ", dc_id_pass);
    var retStateLoad = false;
    var dcRet = [];
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      console.log("Selected Customer :", customerIdSelect);
      var reqParams = {
        dc_id: dc_id_pass
      };
      console.log("REQUEST PARAMS: ", reqParams );
      var res = await axios.post(client.domain + "/bms/getInwardDCItemsForOutDC", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server Invoice : ",res.data.detail.data );
        //setDcList(res.data.detail.data.customer_dc);
        //setDcSummary(res.data.detail.data.customer_dc_summary[0]);
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        //setToRender(true);
        dcRet = res.data.detail.data.customer_dc_items;
        //summaryRet = res.data.detail.data.customer_dc_summary;
        retStateLoad = true;
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log( "Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log( "Request failed due to ",error.response.data.detail.res_data);
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retStateLoad, dcRet];
  };

  const handleDialogRadio = async (e) => {
    console.log("handleDialogRadio called  : ", e);
    const { name, id } = e.currentTarget;
    console.log("Captured value name at handleDialogRadioChange : ", name);
    console.log("Captured value id at handleDialogRadioChange : ", id);
    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element at handleDialogRadioChange : ", nameOfElement);
    console.log("Current row id at handleDialogRadioChange : ", currentRowName);
    setSelectedRadio(parseInt(currentRowName));
    var [retState, inwardDCItemsRet] = await getInwardDCItemsByDcId(dcList[currentRowName].indc_id, selectedRowId);
  };

  const handleDCItemCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    console.log("handle copy to billing called TARGET :", e.target);
    console.log("handle copy to billing called CURRENT TARGET :", e.currentTarget);
    console.log("handle copy to billing called CURRENT TARGET NAME:", e.currentTarget.name);
    console.log("handle copy to billing called CURRENT TARGET VALUE:", e.currentTarget.value);

    var currentId = e.currentTarget.name.substring(e.currentTarget.name.lastIndexOf("-") + 1);
    var nameOfElement = e.currentTarget.name.substring(0, e.currentTarget.name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
    //setItemCheckState(e.target.checked);
    setDisableState(e.currentTarget.checked);
    var dcItemSelected = dcItemList[currentId];
    dcItemSelected.dc_item_check = e.target.checked;
    const newDcItemList = dcItemList.slice();
    newDcItemList[currentId] = dcItemSelected;
    setDcItemList(newDcItemList);
  };

  const handleDCSaveComposition = (e) => {
    console.log("handle save Composition called ...");

    var inDcItemListSelect = [];
    dcItemList.forEach(function(inDcItem) {
      if ( inDcItem.dc_item_check ) {
        inDcItemListSelect.push(inDcItem);
      }
    })
    console.log("SELECTED INWARD DC ITEM LIST : ", inDcItemListSelect);
    setShowDCDialog(false); 

    var itemSelected = items[selectedRowId];
    itemSelected.in_dc_items = inDcItemListSelect;

    var itemsNew = items.slice();
    itemsNew[selectedRowId] = itemSelected;
    setItems(itemsNew);
  };

  const handleDCCloseComposition = (e) => {
    console.log("handle close composition called ...");
    //setShowDCDialog(false);
    setItemCheckState(false);
    setSelectedRadio(-1);
    //navigate(0); 
  };

  const handleCloseInwardDialog = async(e) => {
    //console.log("handleCloseInwardDialog called : ", e);
    setShowDCDialog(false);
  }

  return (
    <>
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent} />
        {toRender ?
          (
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    {menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes')).page_title_addupd : "Invoice"}
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6 style={{paddingTop: '4px'}}>{state.passed_element.dc_no}</h6>
                      </Badge>
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-order-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-order-name`}
                      id={`edit-icon-order-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                      &nbsp;&nbsp;
                      <Button id="fullOrderPrint" name="fullorderPrint" onClick={(e) => handlePrintFullOrder(e)}
                        disabled={menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes' && element.a_print === true)) ? false : true}>
                        <BsFillPrinterFill
                          size={18}
                          style={{ color: "white", cursor: "pointer" }}
                          name={`print-order-dc-icon`}
                          id={`print-order-dc-icon`}
                        />
                      </Button>

                      &nbsp;&nbsp;
                      <Button
                        variant="primary"
                        className="btn btn-primary btn-md"
                        onClick={(e) => handleEdit(e)}
                        disabled={menuSettings.permissions.find(element => (element.code === 'listDeliveryNotes' && element.a_edit === true)) ? false : true}
                      >
                        <BsPencilSquare
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <hr align="center" />
              <Row>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field required">Challan Number</Form.Label>

                    <Form.Control
                      type="text"
                      name="dc_no"
                      placeholder="DC Number"
                      value={formValues.dc_no}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                      }}
                    />
                  </Form.Group>
                  {/*<p className="error-msg">{formErrors.expected_inv_number}</p>*/}
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field">DC Date</Form.Label>

                    <Form.Control
                      type="date"
                      name="dc_date"
                      //disabled="true"
                      placeholder="DC Date"
                      value={formValues.dc_date}
                      onChange={onChangeDatePicker}
                      //readOnly={isReadOnly}
                      //disabled={state.action !== 'add' || isLoading }
                      disabled={isLoading}
                    />
                  </Form.Group>
                  <p className="error-msg">{formErrors.dc_date}</p>
                </Col>

                {/*
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field">Due Date</Form.Label>

                    <Form.Control
                      type="date"
                      name="due_date"
                      //disabled="true"
                      placeholder="Due Date"
                      value={formValues.due_date}
                      onChange={onChangeDueDatePicker}
                    //readOnly={isReadOnly}
                    //disabled={state.action !== 'add' || isLoading }
                    //disabled={ isLoading }
                    />
                  </Form.Group>
                  <p className="error-msg">{formErrors.due_date}</p>
                </Col>
                */}

                {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="invform.customer_name"
                    >
                      <Form.Label className="field">Customer Search</Form.Label>
                      <AsyncTypeahead
                        id="customer-search-fd-id"
                        name="customer_name"
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleFunc(query);
                        }}
                        onChange={(selected) => {
                          customerSearchSelectFunc(selected);
                        }}
                        options={optionsSearch}
                        placeholder="Search Customer ..."
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        style={{
                          borderColor: "gray"
                        }}
                        className="rounded-sm"
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.customer_name}</p>
                    </Col>
                */}
                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" >
                    <Form.Label className="field">Customer Name</Form.Label>

                    <Select
                      id="customer_name"
                      name="customer_name"
                      value={{
                        value: customerIdSelect.value,
                        label: customerIdSelect.label,
                      }}
                      options={optCustomer}
                      defaultValue={{
                        value: customerIdSelect.value,
                        label: customerIdSelect.label,
                      }}
                      onChange={handleCustomerSelect}
                      readOnly={state.action === 'add' ? isReadOnly : true}
                      //isDisabled="true"
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.customer_name}</p>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field">GSTIN</Form.Label>

                    <Form.Control
                      type="text"
                      id="gstin"
                      name="gstin"
                      placeholder="gstin"
                      value={formValues.gstin}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                      }}
                    />
                  </Form.Group>
                  {/*<p className="error-msg">{formErrors.expected_inv_number}</p>*/}
                </Col>

                { (dcList.length > 0 && customerIdSelect.value > 0) &&
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3" >
                    <Form.Label className="field">Inward DCs</Form.Label>

                    <Select
                      id="indc"
                      name="indc"
                      value={{
                        value: inDCIdIdSelect.value,
                        label: inDCIdIdSelect.label,
                      }}
                      options={dcList}
                      defaultValue={{
                        value: inDCIdIdSelect.value,
                        label: inDCIdIdSelect.label,
                      }}
                      onChange={handleInDCSelect}
                      readOnly={state.action === 'add' ? isReadOnly : true}
                      //isDisabled="true"
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.indc}</p>
                </Col>
                }

                <Col
                  xs={1}
                  md={1}
                  sm={1}
                  lg={1}
                  style={{ paddingTop: "30px" }}>

                  {/*
                  <Button
                    variant="warning"
                    id="add-btn-id"
                    name="add-btn-name"

                    onClick={(e) => handleAdd(e)}
                  >
                    New
                  </Button>
                  */}
                  <Form.Check
                        style={{ paddingTop: "5px" }}
                        label="billable"
                        name="checkbox"
                        id="id-billable-state"
                        type="checkbox"
                        className="checkCopy"
                        //defaultChecked=
                        checked={billableState}
                        onChange={handleBillableState}
                      ></Form.Check>
                </Col>

              </Row>

              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <Row>
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <h5>Dispatch To</h5>
                    </Col>
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Check
                        style={{ paddingTop: "5px" }}
                        label="Load Previous Address"
                        name="checkbox"
                        id="id-prev-addr-ship"
                        type="checkbox"
                        className="checkCopy"
                        //defaultChecked=
                        checked={loadPrevAddrState}
                        onChange={handleCheckBoxLoadAddr}
                      ></Form.Check>
                    </Col>
                    {/*
                <Col xs={3} md={3} sm={3} lg={3} style={{paddingBottom: '2px'}}>
                <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleAddrLoad(e)}>Load Address</Button>
                </Col>
                */}
                    {/*
                <Col xs={1} md={1} sm={1} lg={1}>
                <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleAddrLoad(e)}>
                    <BsDatabaseDown
                      key={`addr-load-btn-key`}
                      style={{ cursor: "pointer" }}
                      name={`addr-load-icon-name`}
                      id={`addr-load-icon-id`}
                    />
                  </Button>
                  </Col>
                  */}
                    <hr align="center" style={{ width: '99%' }} />
                  </Row>

                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="field">
                          Street / Location
                        </Form.Label>

                        <Form.Control
                          type="text"
                          id="address1_ship"
                          name="address1_ship"
                          placeholder="Building No / Street Name"
                          value={formValues.address1_ship}
                          onChange={handleValueChange}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //disabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.address1_ship}</p>
                    </Col>
                  </Row>

                  <Row>

                    <Col xs={12} md={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>City / Town</Form.Label>

                        <Form.Control
                          type="text"
                          id="adddress2_ship"
                          name="address2_ship"
                          placeholder="City / Town"
                          value={formValues.address2_ship}
                          onChange={handleValueChange}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //disabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.address2_ship}</p>
                    </Col>
                  </Row>

                  <Row>

                    {/*
            <Col xs={4} md={4} sm={4} lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label className="field">District</Form.Label>

                      <Form.Control
                        type="text"
                        name="district_ship"
                        id="district_ship"
                        placeholder="District"
                        value={formValues.district_ship}
                        onChange={handleValueChange}
                        //maxLength={6}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        //disabled="true"
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.district_ship}</p>
                  </Col>
              */}
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Group className="mb-3" >
                        <Form.Label className="field">State</Form.Label>

                        <Select
                          id="state_ship"
                          name="state_ship"
                          value={{
                            value: stateIdSelectShip.value,
                            label: stateIdSelectShip.label,
                          }}
                          options={optionsStateShip}
                          defaultValue={{
                            value: stateIdSelectShip.value,
                            label: stateIdSelectShip.label,
                          }}
                          onChange={handleStateSelectShip}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //isDisabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.state_ship}</p>
                    </Col>
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Group className="mb-3" >
                        <Form.Label className="field">District</Form.Label>

                        <Select
                          id="district_ship"
                          name="district_ship"
                          value={{
                            value: districtIdSelectShip.value,
                            label: districtIdSelectShip.label,
                          }}
                          options={optionsDistrictShip}
                          defaultValue={{
                            value: districtIdSelectShip.value,
                            label: districtIdSelectShip.label,
                          }}
                          onChange={handleDistrictSelectShip}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //isDisabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.district_ship}</p>
                    </Col>

                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label className="field">Pincode</Form.Label>

                        <Form.Control
                          type="text"
                          id="pincode_ship"
                          name="pincode_ship"
                          placeholder="Pincode"
                          value={formValues.pincode_ship}
                          onChange={handleValueChange}
                          maxLength={6}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //disabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.pincode_ship}</p>
                    </Col>

                  </Row>
                </Col>

                <Col xs={6} md={6} sm={6} lg={6}>
                  <Row>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <h5>Party</h5>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <Form.Check
                        style={{ paddingTop: "5px" }}
                        label="same as dispatch"
                        name="checkbox"
                        id="id-addr-copy-bill"
                        type="checkbox"
                        className="checkCopy"
                        //defaultChecked=
                        checked={copyCheckState}
                        onChange={handleCheckBox}
                      ></Form.Check>
                    </Col>
                    <hr align="center" style={{ width: '99%' }} />
                  </Row>

                  <Row>
                    <Col xs={12} md={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="field">
                          Street / Location
                        </Form.Label>

                        <Form.Control
                          type="text"
                          id="address1_bill"
                          name="address1_bill"
                          placeholder="Building No / Street Name"
                          value={formValues.address1_bill}
                          onChange={handleValueChange}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //disabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.address1_bill}</p>
                    </Col>
                  </Row>

                  <Row>

                    <Col xs={12} md={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>City / Town</Form.Label>

                        <Form.Control
                          type="text"
                          id="address2_bill"
                          name="address2_bill"
                          placeholder="City / Town"
                          value={formValues.address2_bill}
                          onChange={handleValueChange}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //disabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.address2_bill}</p>
                    </Col>
                  </Row>

                  <Row>

                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Group className="mb-3" >
                        <Form.Label className="field">State</Form.Label>

                        <Select
                          id="state_bill"
                          name="state_bill"
                          value={{
                            value: stateIdSelectBill.value,
                            label: stateIdSelectBill.label,
                          }}
                          options={optionsStateBill}
                          defaultValue={{
                            value: stateIdSelectBill.value,
                            label: stateIdSelectBill.label,
                          }}
                          onChange={handleStateSelectBill}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //isDisabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.state_bill}</p>
                    </Col>

                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Group className="mb-3" >
                        <Form.Label className="field">District</Form.Label>

                        <Select
                          id="district_bill"
                          name="district_bill"
                          value={{
                            value: districtIdSelectBill.value,
                            label: districtIdSelectBill.label,
                          }}
                          options={optionsDistrictShip}
                          defaultValue={{
                            value: districtIdSelectBill.value,
                            label: districtIdSelectBill.label,
                          }}
                          onChange={handleDistrictSelectBill}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //isDisabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.district_bill}</p>
                    </Col>

                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label className="field">Pincode</Form.Label>

                        <Form.Control
                          type="text"
                          id="pincode_bill"
                          name="pincode_bill"
                          placeholder="Pincode"
                          value={formValues.pincode_bill}
                          onChange={handleValueChange}
                          maxLength={6}
                          readOnly={state.action === 'edit' ? isReadOnly : false}
                          //disabled="true"
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>

                      <p className="error-msg">{formErrors.pincode_bill}</p>
                    </Col>
                  </Row>
                </Col>

              </Row>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Additional Details</Accordion.Header>
                  <Accordion.Body>
                    {/*<h5>Additional Details</h5>*/}

                    <Row>
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Mode / Terms of  Payment</Form.Label>

                          <Form.Control
                            type="text"
                            name="pay_terms"
                            placeholder="Mode / Terms of  Payment"
                            value={formValuesAddl.pay_terms}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.pay_terms}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Reference No</Form.Label>

                          <Form.Control
                            type="text"
                            name="ref_no"
                            placeholder="Party DC No"
                            value={formValuesAddl.ref_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.ref_no}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Reference Date</Form.Label>

                          <Form.Control
                            type="date"
                            name="ref_date"
                            //disabled="true"
                            placeholder="Reference Date"
                            value={formValuesAddl.ref_date}
                            onChange={onChangeRefDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.ref_date}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Other Reference</Form.Label>

                          <Form.Control
                            type="text"
                            name="ref_others"
                            placeholder="Other Reference"
                            value={formValuesAddl.ref_others}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.ref_others}</p>
                      </Col>

                    </Row>

                    <Row>
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Buyer's Order No</Form.Label>

                          <Form.Control
                            type="text"
                            name="buyer_order_no"
                            placeholder="Buyer's Order No"
                            value={formValuesAddl.buyer_order_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.buyer_order_no}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Buyer Order Date</Form.Label>

                          <Form.Control
                            type="date"
                            name="buyer_order_date"
                            //disabled="true"
                            placeholder="Buyer Order Date"
                            value={formValuesAddl.buyer_order_date}
                            onChange={onChangeBuyerDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.buyer_order_date}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Dispatch Document No</Form.Label>

                          <Form.Control
                            type="text"
                            name="dispatch_doc_no"
                            placeholder="Dispatch No"
                            value={formValuesAddl.dispatch_doc_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.dispatch_doc_no}</p>
                      </Col>

                      
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Dispatch Through</Form.Label>

                          <Form.Control
                            type="text"
                            name="dispatch_through"
                            placeholder="Dispatch Through"
                            value={formValuesAddl.dispatch_through}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.dispatch_through}</p>
                      </Col>

                    </Row>


                    <Row>
                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Destination</Form.Label>

                          <Form.Control
                            type="text"
                            name="destination"
                            placeholder="Destination"
                            value={formValuesAddl.destination}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.destination}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Bill Of Ladly / Lr.RR No</Form.Label>
                          <Form.Control
                            type="text"
                            name="bol_no"
                            placeholder="Bill Of Ladly / Lr.RR No"
                            value={formValuesAddl.bol_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />

                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.bol_no}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Bill Of Ladly / Lr.RR Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="bol_date"
                            //disabled="true"
                            placeholder="Bill Of Ladly / Lr.RR Date"
                            value={formValuesAddl.bol_date}
                            onChange={onChangeRRDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />

                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.bol_date}</p>
                      </Col>

                      <Col xs={3} md={3} sm={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Vehicle No</Form.Label>

                          <Form.Control
                            type="text"
                            name="vehicle_no"
                            placeholder="Vehicle No"
                            value={formValuesAddl.vehicle_no}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.vehicle_no}</p>
                      </Col>
                      
                    </Row>

                    <Row>

                      <Col xs={4} md={4} sm={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Date & Time of issue</Form.Label>
                          <Row>
                          <Col xs={6} md={6} sm={6} lg={6}>
                          <Form.Control
                            type="date"
                            name="issue_date"
                            //disabled="true"
                            placeholder="Date of issue"
                            value={formValuesAddl.issue_date}
                            onChange={onChangeIssueDatePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                          </Col>

                          <Col xs={6} md={6} sm={6} lg={6}>
                          <Form.Control
                            type="time"
                            name="issue_time"
                            //disabled="true"
                            placeholder="Time of issue"
                            value={formValuesAddl.issue_time}
                            onChange={onChangeIssueTimePicker}
                          //readOnly={isReadOnly}
                          //disabled={state.action !== 'add' || isLoading }
                          //disabled={ isLoading }
                          />
                          </Col>
                          </Row>
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.issue_date_time}</p>
                      </Col>

                      <Col xs={4} md={4} sm={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Duration of Process</Form.Label>

                          <Form.Control
                            type="text"
                            name="process_duration"
                            placeholder="Duration of Process"
                            value={formValuesAddl.process_duration}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.process_duration}</p>
                      </Col>

                      <Col xs={4} md={4} sm={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="field">Nature of Processing</Form.Label>

                          <Form.Control
                            type="text"
                            name="process_nature"
                            placeholder="Nature Of Processing"
                            value={formValuesAddl.process_nature}
                            onChange={handleAddlValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: (state.action === 'view' || state.action === 'edit') && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrorsAddl.process_nature}</p>
                      </Col>


                    </Row>

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <hr align="center" />


              <div className="ui-form">


                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div className="d-flex justify-content-start">
                      <h4>
                        <Badge pill bg="secondary">
                          Item Details
                        </Badge>
                      </h4>
                      <p
                        className="error-msg"
                        style={{
                          position: "relative",
                          //top: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {formErrors.alertMsg}
                      </p>
                    </div>
                  </Col>

                  <Col xs={6} md={6} sm={6} lg={6}>
                    <div className="d-flex justify-content-end">

                      <Button
                        className="btn btn-warning"
                        style={{ alignItems: "self-end" }}
                        onClick={newItemHandle}
                      //disabled={isReadOnly ? true : false}
                      >
                        Add Item
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr align="center" />


                {items.length > 0 &&
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading" style={{width: '4%'}}>S.No</th>
                          <th className="table-row-heading" style={{width: '18%'}}>Item</th>
                          <th className="table-row-heading" style={{width: '9%'}}>HSN Code</th>
                          <th className="table-row-heading" style={{width: '9%'}}>Qty</th>
                          <th className="table-row-heading" style={{width: '9%'}}>Weight</th>
                          <th className="table-row-heading" style={{width: '9%'}}>Rate per Unit</th>
                          {/*<th className="table-row-heading" style={{width: '5%'}}>Yield %</th>*/}
                          <th className="table-row-heading" style={{width: '9%'}}>Net Amount</th>
                          { parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectShip.gst_code) ?
                          <>
                          <th className="table-row-heading" style={{width: '9%'}}>CGST</th>
                          <th className="table-row-heading" style={{width: '9%'}}>SGST</th>
                          </>
                          :
                          <th className="table-row-heading" style={{width: '9%'}}>IGST</th>
                          }
                          <th className="table-row-heading" style={{width: '9%'}}>Tot Amount</th>
                          <th className="table-row-heading" style={{width: '6%'}}>***</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((item, idx) => (
                          <tr>
                            <td style={{width: '4%'}}>{idx + 1}</td>
                            <td style={{ width: '18%' }}>
                              <Row>
                                <div style={{ width: '80%' }}>
                                  <Select
                                    id={`product-item-${idx}`}
                                    name={`product-item-${idx}`}
                                    //value={options.value}
                                    value={{
                                      value: item.item_id,
                                      label: item.item_name,
                                    }}
                                    options={productMaster}
                                    defaultValue={{
                                      value: item.item_id,
                                      label: item.item_name,
                                    }}
                                    //isDisabled={isReadOnly}
                                    onChange={handleProductSelect}
                                  />
                                  </div>
                                <div style={{width: '18%', paddingTop: "2px"}}>
                                  {/*
                                  <Button
                                    variant="primary"
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => handleInfoCompositeEdit(e, 'notes')}
                                    name={`edit-btn-order-name-${idx}`}
                                    id={`edit-btn-order-id-${idx}`}
                                    //disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}
                                  disabled={isReadOnly ? true : false}>
                                    
                                    {item.editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                                      <BsPencilSquare
                                        key={`edit-btn-order-key`}
                                        size={12}
                                        style={{ cursor: "pointer" }}
                                      />
                                  </Button> 
                                    */}
                                        <BsPencilSquare
                                          size={28}
                                          style={{ color: "blue", cursor: "pointer"}}
                                          onClick={(e) =>  handleInfoCompositeEdit(e, 'notes')}
                                          name={`composite-edit-icon-${idx}`}
                                          id={`composite-edit-icon-${idx}`}
                                        />
                                  </div>
                                  <p className="error-msg">{item.notes}</p>
                              </Row>

                            </td>

                            <td 
                              style={{
                                width: "8%",
                                padding: "5px",
                                textAlign: "right"
                              }}
                            >
                              {item.hsn_code}
                            </td>

                            <td style={{ width: '9%'}}>
                            <Form.Group as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                  <Form.Control
                                    type="text"
                                    name={`pkg_info-${idx}`}
                                    placeholder="Quantity"
                                    value={item.pkg_info}
                                    // max="100000"
                                    onChange={handleChange}
                                    //readOnly={isReadOnly}
                                    maxLength={MAX_QTY_LENGTH}
                                    style={{ textAlign: "right", border: !isReadOnly && "1px solid gray", }}
                                  />

                             </Col>   
           
                              </Form.Group>
                              {/*
                                <div style={{width: '15%'}}>
                                  <Button
                                    variant="primary"
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => handleInfoCompositeEdit(e, "pkg_info")}
                                    name={`edit-btn-order-name-${idx}`}
                                    id={`edit-btn-order-id-${idx}`}
                                    //disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}
                                  disabled={isReadOnly ? true : false}

                                  >
                                    
                                    {item.editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                                      <BsPencilSquare
                                        key={`edit-btn-order-key`}
                                        size={12}
                                        style={{ cursor: "pointer" }}
                                      />
                                  </Button> 
                                  </div>
                                  */}
                                  <p className="error-msg">{item.pkg_info_error}</p>


                            </td>


                            <td style={{ width: '9%'}}>

                              <Form.Group as={Row} className="mb-3" controlId="m3">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Form.Control
                                    type="text"
                                    name={`qty-${idx}`}
                                    placeholder="Weight"
                                    value={item.qty}
                                    // max="100000"
                                    onChange={handleChange}
                                    //readOnly={isReadOnly}
                                    maxLength={MAX_AMOUNT_LENGTH}
                                    style={{ textAlign: "right", border: !isReadOnly && "1px solid gray" }}
                                  />
                                </Col>
                              </Form.Group>
                              <p className="error-msg">{item.qty_error}</p>

                            </td>

                            <td style={{width: '9%'}}>

                              <Form.Group as={Row} className="mb-3" controlId="m3">
                                <Col xs={12} sm={12} md={12} lg={12} >
                                  <Form.Control
                                    type="text"
                                    name={`unit_price-${idx}`}
                                    placeholder="Unit Price"
                                    value={item.unit_price}
                                    // max="100000"
                                    onChange={handleChange}
                                    //readOnly={isReadOnly}
                                    maxLength={MAX_AMOUNT_LENGTH}
                                    style={{ textAlign: "right", border: !isReadOnly && "1px solid gray" }}
                                  />
                                </Col>
                              </Form.Group>
                              <p className="error-msg">{item.unit_price_err}</p>

                            </td>

                            {/*
                            <td style={{ width: '8%'}}>

                              <Form.Group as={Row} className="mb-3" controlId="m3">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Form.Control
                                    type="text"
                                    name={`jw_yield_per-${idx}`}
                                    placeholder="Yield %"
                                    value={item.jw_yield_per}
                                    // max="100000"
                                    onChange={handleChange}
                                    //readOnly={isReadOnly}
                                    maxLength={MAX_AMOUNT_LENGTH}
                                    style={{ textAlign: "right", border: !isReadOnly && "1px solid gray" }}
                                  />
                                </Col>
                              </Form.Group>
                              <p className="error-msg">{item.qty_error}</p>

                            </td>
                            */}

                            <td
                              style={{
                                padding: "5px",
                                textAlign: "right",
                                width: '9%'
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.net_amt).toFixed(2)))}
                            </td>


                            { parseInt(JSON.parse(localStorage.getItem("selected_business")).gstin.slice(0, 2)) === parseInt(stateIdSelectShip.gst_code) ?
                            <>
                            <td
                              style={{
                                width: "8%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.cgst_amt).toFixed(2)))}
                            </td>
                            <td
                              style={{
                                width: "8%",
                                padding: "5px",
                                textAlign: "right",
                                //overflow: "auto",
                                //display: "block",
                                //width: "auto !important",
                                //tableLayout: "auto !important",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.sgst_amt).toFixed(2)))}
                            </td>
                            </>
                            :
                            <>
                            <td
                              style={{
                                width: "8%",
                                padding: "5px",
                                textAlign: "right",
                                //overflow: "auto",
                                //display: "block",
                                //width: "auto !important",
                                //tableLayout: "auto !important",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.igst_amt).toFixed(2)))}
                            </td>
                            </>
                          }

                            <td
                              style={{
                                width: "9%",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_amt).toFixed(2)))}
                            </td>

                            {/*  
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              { item.product_type === 'SERVICE' ?
                              <>
                              <Form.Group  as={Row} className="mb-3" controlId="m3">
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Control
                                  type="text"
                                  name={`total_item_price-${idx}`}
                                  placeholder="Total Item Price"
                                  value={item.total_item_price}
                                  // max="100000"
                                  onChange={handleChange}
                                  readOnly={isReadOnly}
                                  maxLength={MAX_AMOUNT_LENGTH}
                                  style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                                />
                              </Col>
                            </Form.Group>
                            <p className="error-msg">{item.total_item_price_err}</p>
                            </>
                            :
                            <>
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_item_price).toFixed(2)))}
                              </>
                              }
                            </td>
                            
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.cgst).toFixed(2)))}
                            </td>
                            <td
                              style={{
                                width: "100px",
                                padding: "5px",
                                textAlign: "right",
                                //overflow: "auto",
                                //display: "block",
                                //width: "auto !important",
                                //tableLayout: "auto !important",
                              }}
                            >
                            
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.sgst).toFixed(2)))}
                            </td>
                            */}

                            <td style={{width: '6%'}}>
                            {/*
                            <BsGridFill
                                size={24}
                                style={{ color: "blue", cursor: "pointer" }}
                                onClick={(e) => handleDCEdit(e)}
                                name={`item-edit-icon-${idx}`}
                                id={`item-edit-icon-${idx}`}
                                //disabled="true"
                              />
                              */}
                              {/*
                            <Button
                                    variant="sucess"
                                    className="btn btn-success btn-sm"
                                    onClick={(e) => handleDCEdit(e)}
                                    name={`edit-btn-dc-name-${idx}`}
                                    id={`edit-btn-dc-id-${idx}`}
                                    //disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}
                                     disabled={isReadOnly ? true : false}>
                                      <BsGridFill
                                        key={`edit-btn-dc-key`}
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                      />
                                  </Button> 
                                */}
                                  &nbsp;&nbsp;
                              <BsFillXCircleFill
                                size={24}
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={(e) => handleItemDelete(e)}
                                name={`item-delete-icon-${idx}`}
                                id={`item-delete-icon-${idx}`}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="ui-divider"></div>

                    <div className="ui-form"></div>

                    <Row className="show-grid">

                      <Col xs={6} sm={6} md={6} lg={6}>

                        <Row>
                          <Col xs={12} md={12} sm={12}>
                            <div style={{ paddingBottom: "40px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Amount Chargeable (in Words) Indian Rupees :
                              </span>
                              <span> {sum_product_total_ref.current > 0.01 ? convertNumberToWords(Math.round(sum_product_total_ref.current)) : ""}</span>
                            </div>
                          </Col>
                        </Row>
                        <p></p>
                        {/*
                        <Row>
                          <Col xs={5} md={5} sm={5} lg={5}>
                            <Form.Group className="mb-3">
                              <Form.Label>Bank Account</Form.Label>
                              <Select
                                inline
                                id="bank_account"
                                name="bank_account"
                                value={{
                                  value: bankAccountIdSelect.value,
                                  label: bankAccountIdSelect.label,
                                }}
                                options={optBankAccount}
                                defaultValue={{
                                  value: bankAccountIdSelect.value,
                                  label: bankAccountIdSelect.label,
                                }}
                                onChange={handleBankAccountSelect}
                              //isDisabled={isReadOnly}
                              />
                            </Form.Group>

                          </Col>
                        </Row>
                        */}
                        <div className="ui-divider"></div>
                      </Col>



                      <Col col-12 col-sm-6 xs={6} sm={6} md={6} lg={6} style={{ alignContent: 'float-end', align: 'right', paddingTop: "35px" }} className="order-last order-sm-last justify-content-end float-end">
                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Sub Total :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cost_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>



                        {/*
                  <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">
                     Sub Total
                      </Form.Label>
                      <Col lg="6">
                       <Form.Control
                       type="text"
                       disabled="true"
                       placeholder="16000"
                       onChange={(e) => handleValueChange(e)}
                       value={sum_product_cost.toFixed(2)}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row>
                    */}

                        { sum_product_igst_ref.current < 0.01 ?
                        <>
                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Total CGST :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cgst_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>

                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Total SGST :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_sgst_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>

                        </>
                        :
                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Total IGST :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_igst_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>
                        }

                        {sum_product_cess_ref.current > 0.01 &&
                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Total CESS :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cess_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>
                        </Row>
                        }

                        {/*
                    <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="7">
                    GST on Reverse Charge
                      </Form.Label>
                     <Col sm="5">
                       <Form.Control
                       type="text"
                       disabled="true"
                       placeholder="16000"
                       value={(sum_product_cgst + sum_product_sgst + sum_product_igst).toFixed(2)}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row> 
                    */}
                        {/*
                   <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                      GST on Reverse Charge :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_cgst + sum_product_sgst + sum_product_igst).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                    */}

                        {/*
                    <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">
                     SGST @ 9%
                      </Form.Label>
                      <Col sm="6">
                       <Form.Control
                       type="text"
                       disabled="true"
                       //placeholder="1500"
                       value={sum_product_sgst.toFixed(2)}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row>
                    */}
                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Total Amount :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sum_product_total_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>
                        {/*
                    <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">
                      Total Amount
                      </Form.Label>
                      <Col sm="6">
                       <Form.Control
                       type="text"
                       disabled="true"
                       placeholder="160000"
                       value={Math.round((sum_product_total)).toFixed(2)}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row>
                    */}
                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Round Off :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>

                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format((Math.round(sum_product_total_ref.current) - parseFloat(sum_product_total_ref.current)).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>
                        {/*
                    <Row className="d-flex justify-content-end float-end">
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">
                     Round Off
                      </Form.Label>
                      <Col sm="6">
                       <Form.Control
                       type="text"
                       disabled="true"
                       placeholder="1.60"
                       value={sum_rounded_off}
                       style={{
                        border: !isReadOnly && "1px solid gray",textAlign: "right"
                      }}
                        />
                       </Col>
                     </Form.Group>
                    </Row>
                    */}


                        <Row>
                          <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>
                              Net Amount :
                            </Form.Label>
                          </Col>
                          <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{ alignContent: 'right', alignItems: 'right', textAlign: 'right' }}>

                            <Form.Label style={{ fontWeight: 'bold' }}>
                              {/*{Math.round(sum_product_total).toFixed(2)}*/}
                              {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(Math.round(sum_product_total_ref.current).toFixed(2)))}
                            </Form.Label>
                          </Col>

                        </Row>
                      </Col>
                    </Row>

                    <Row>


                      <Col xs={6} md={6} sm={6} lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Notes / Remarks</Form.Label>
                          <Form.Control
                            as="textarea" rows={3}
                            name="notes"
                            id="notes"
                            placeholder="Notes / Remarks"
                            value={formValues.notes}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/*
                      <Col xs={6} md={6} sm={6} lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>T & C / Declaration</Form.Label>
                          <Form.Control
                            as="textarea" rows={3}
                            name="declaration"
                            id="declaration"
                            placeholder="T & C / Declaration"
                            value={formValues.declaration}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                      </Col>
                      */}
                    </Row>

                  </>
                }
                 <div className="modal-xl">
                <Modal show={showDCDialog}
                //size = "1140px"
                size="lg"
                onHide={handleCloseInwardDialog}
                >
                  <Modal.Header  closeButton>
                    <Modal.Title>Inward DC Adjustment</Modal.Title>
                  </Modal.Header>
                    <Modal.Body>
                  <Row>
                     <h6>
                        <Badge pill bg="secondary">
                          Inward DC Challans
                        </Badge>
                      </h6>
                  </Row>
                  <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading"></th>
                          <th className="table-row-heading">Inward DC No.</th>
                          <th className="table-row-heading">Inward DC Date</th>
                          <th className="table-row-heading">Party DC No.</th>
                          <th className="table-row-heading">Party DC Date</th>
                          </tr>
                      </thead>
                      <tbody>
                        {dcList.map((dcInd, idx) => (
                          <tr>
                            <td>
                                  <Form.Check
                                  disabled={isReadOnly}
                                  inline
                                  name={`inward-dc-radio-${idx}`}
                                  type={"radio"}
                                  id={`inward-dc-radio-${idx}`}
                                  onChange={(e) => handleDialogRadio(e)}
                                  //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                                  checked={idx === selectedRadio ? true : false}
                                  />
                          {/*
                            <Form.Check
                            style={{paddingTop: "5px"}}
                            name={`dc_check-${idx}`}
                            id={`dc_check-${idx}`}
                            type="radio"
                            className="checkCopy"
                            //defaultChecked=
                            checked={dc.dcList.value === receivedInd.value ? true : false}
                            //checked={dc.dc_item_check}
                            onChange={handleDialogRadio}
                          ></Form.Check>
                          */}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcInd.indc_dc_no}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcInd.indc_dc_date}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcInd.cust_indc_no}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcInd.cust_indc_date}
                            </td>
                            </tr>
                        ))}
                      </tbody>
                    </Table>
                   {itemcheckState  &&
                   <>
                    <Row>
                     <h6>
                        <Badge pill bg="secondary">
                           DC Items
                        </Badge>
                      </h6>
                  </Row>
                  <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading"></th>
                          <th className="table-row-heading">Item</th>
                          <th className="table-row-heading">Unit</th>
                          <th className="table-row-heading">Total Qty</th>
                          <th className="table-row-heading">Balance Qty</th>
                          <th className="table-row-heading">Used Qty</th>
                          <th className="table-row-heading">Yield %</th>
                          </tr>
                      </thead>
                      <tbody>
                        {dcItemList.map((dcItem, idx) => (
                          <tr>
                            <td>
                            <Form.Check
                            style={{paddingTop: "5px"}}
                            name={`dc_item_check-${idx}`}
                            id={`dc_item_check-${idx}`}
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={dcItem.dc_item_check}
                            onChange={handleDCItemCheckBox}
                          ></Form.Check>
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcItem.indc_item_name}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                                width: "20%"
                              }}
                            >
                              {dcItem.indc_unit_name}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcItem.indc_qty}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {dcItem.indc_qty_unused}
                            </td>
                          
                            <td style={{width: "20%"}}>
                            <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Form.Control
                                type="text"
                                name={`qty_used-${idx}`}
                                placeholder="Used Qty"
                                value={dcItem.qty_used}
                                disabled={disableState ? false: true}
                                onChange={handleChangeForList}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                              />
                            </Col>
                          </Form.Group>
                          <p className="error-msg">{dcItem.indc_qty_used_err}</p>
                              
                            </td>

                            <td style={{width: "20%"}}>
                            <Form.Group  as={Row} className="mb-3" controlId="m3">
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Form.Control
                                type="text"
                                name={`jw_yield_per-${idx}`}
                                placeholder="Used Qty"
                                value={dcItem.jw_yield_per}
                                disabled={disableState ? false: true}
                                onChange={handleChangeForList}
                                style={{textAlign: "right",border: !isReadOnly && "1px solid gray"}}
                              />
                            </Col>
                          </Form.Group>
                          <p className="error-msg">{dcItem.indc_qty_used_err}</p>
                              
                            </td>
                          
                            </tr>
                        ))}
                      </tbody>
                    </Table>
                    </>
                  }
                  </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleDCCloseComposition}>
                      Clear
                  </Button>
                  <Button variant="primary" onClick={handleDCSaveComposition}>
                      Save
                  </Button>
                  </Modal.Footer>
                  </Modal>
                  </div>
                  


                {!isReadOnly && (
                  <Row>
                    <Col xs={12} md={12} sm={12}>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn btn-secondary"
                          id="btn-clear-receipt-id"
                          name="btn-clear-receipt-name"
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          className="btn btn-primary"
                          id="save-print"
                          name="save-print"
                          onClick={(e) => handleSubmit(e, 'save-print')}
                        >
                          
                          {submitPrintAction &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          }
                        
                          Save & Print</Button>
                        &nbsp;
                        &nbsp;&nbsp;
                        <Button 
                        className="btn btn-primary" 
                        id="save-only"
                        name="save-only"
                        onClick={(e) => handleSubmit(e, 'save-only')}>
                          
                          {submitAction &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          }
                          Save</Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Form>

          )
          :
          <div className="container-spinner">
            <div className="vertical-center-spinner">
              <Image src={spinner_logo} roundedCircle style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
              <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning" style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>

                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        }
      </Container>

      {/*
      <Modal show={showItemConfigDialog}>
        <Modal.Header>
        
          <Modal.Title>Additional Notes</Modal.Title>
          
         
        
        </Modal.Header>
        <Modal.Body>
          <Form>  
             
                <Col xs={12} md={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  
                  <Form.Control
                    //type="text"
                    as="textarea" rows={3}
                    name="addl_notes"
                    id="addl_notes"
                    placeholder=" Notes"
                    value={formValues.dialog_value}
                    maxLength={75}
                    onChange={(e) => handleOnItemChangeComposition(e, 'notes')}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
              
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleItemCloseComposition}>
            Close
          </Button>
          <Button variant="primary" onClick={handleItemSaveComposition}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
                  */}
      <Modal show={showInfoConfigDialog}>
        <Modal.Header>
          <Modal.Title>{formValues.called_from === 'notes' ? 'Additional Notes' : 'Packaging Info'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>  
                <Col xs={12} md={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  
                  <Form.Control
                    //type="text"
                    as="textarea" rows={3}
                    name="dialog_value"
                    id="dialog_value"
                    placeholder={formValues.called_from === 'notes' ? 'Additional Notes' : 'Packaging Info'}
                    value={formValues.dialog_value}
                    maxLength={75}
                    onChange={(e) => handleOnInfoChangeComposition(e, formValues.called_from)}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
          
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInfoCloseComposition}>
            Close
          </Button>
          <Button variant="primary" onClick={handleInfoSaveComposition}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
                  
              

      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
          {(alertPurpose === 'confirmation' || alertPurpose === 'item_delete_confirmation') &&
            <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
          }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddrDialog}>
        <Modal.Header>
          <Modal.Title>Previous Addresses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Example textarea</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddrDialog}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOkAddrDialog}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateDeliveryMfgT2;
