import React, {Fragment, useState, useEffect, useRef}  from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

//import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal, LS_MSG_TAG } from "../config/Config";
import axios from "axios";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList, formatDate } from "./utils";
import spinner_logo from '../assets/images/spinner_logo.png';
import { getCustomerMessages } from "./utils";



//function AddUpdateCustomer () {
const AddUpdateBankAccount = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  let initialItems = [];

  const intialValues = {
    name: "",
    acc_number: "",
    openbal_ason: formatDate(new Date()),
    open_bal: "",
    ifsc_code: "",
    bank_name: "",
    branch_name: "", 
    acc_holder_name: "",
    upi_id: ""
  };


  let accountTypeArr = [

    { value: 1, label: "Savings account" },
    { value: 2, label: "Current account" },
    { value: 3, label: "Salary account" },
    { value: 4, label: "Fixed deposit account" },
  ];
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [accountTypeIdSelect, setAccountTypeIdSelect] = useState({});
  const [optaccountType, setOptAccountType] = useState(accountTypeArr);
  const [checkState, setCheckState] =  useState(true);
  const [defaultCheckState, setDefaultCheckState] =  useState(true);
  const [refresh, setRefresh] =  useState(false);
  const [submitAddAction, setSubmitAddAction] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertPurpose, setAlertPurpose] = useState({});
  const [alertTitle, setAlertTitle] = useState({});
  const [alertBody, setAlertBody] = useState({});
  const currSelBusinessRef = useRef(0);
  const currBranchRef = useRef(0);
  const submitActionRef = useRef("save");
  

  var validateErr = false;

  

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "phone" || name === "contact_person_number" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "opening_balance") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_BANKACC_DISNAME_MANDATORY");
      validateErr = true;
      
    }
    /*
  
    if (!values.date) {
      errors.date = getCustomerMessages(LS_MSG_TAG, "MSG_DATE_MANDATORY_VALIDATE");;
      validateErr = true;
      //setValidateError(true);
    }
    */
  

    if (!values.bank_name) {
      errors.bank_name = getCustomerMessages(LS_MSG_TAG, "MSG_BANKACC_BANKNAME_MANDATORY");
      //errors.name = getCustomerMessages(LS_MSG_TAG,"MSG_BUSINESS_NAME_MANDATORY");
      validateErr = true;
      //setValidateError(true);
    }
    
    if (!values.branch_name) {
      errors.branch_name = getCustomerMessages(LS_MSG_TAG, "MSG_BANKACC_BRANCHNAME_MANDATORY");;
      validateErr = true;
      setValidateError(true);
    }

    if (!values.ifsc_code) {
      errors.ifsc_code = getCustomerMessages(LS_MSG_TAG, "MSG_BANKACC_IFSC_MANDATORY");;
      validateErr = true;
      setValidateError(true);
    }

    if (!values.acc_holder_name) {
      errors.acc_holder_name = getCustomerMessages(LS_MSG_TAG, "MSG_BANKACC_ACCHOLDERNAME_MANDATORY");;
      validateErr = true;
      setValidateError(true);
    }

    if (!values.acc_number) {
      errors.acc_number = getCustomerMessages(LS_MSG_TAG, "MSG_BANKACC_ACCNUMBER_MANDATORY");;
      validateErr = true;
      setValidateError(true);
    }
    
    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    //setIsSubmit(true);

    console.log("FORM VALUES at handleSubmit: ", formValues);
    validateErr = false;
    setFormErrors(validate(formValues));
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);
    if (validateErr) {
      return -1;
    }

    console.log("FORM VALUES at handleSubmit: ", formValues);
    var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/bms/addBankAccount";
      } else if (state.action === "view") {
        apiToCall = "/bms/updateBankAccount";
      } else {
        apiToCall = "/bms/addBankAccount";
      }

      var reqParam = {
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        acc_number: formValues["acc_number"] || "",
        ifsc_code: formValues["ifsc_code"] || "",
        bank_name: formValues["bank_name"] || "",
        branch_name: formValues["branch_name"] || "",
        acc_holder_name: formValues["acc_holder_name"],
        upi_id: formValues["upi_id"] || "",
        openbal: formValues["openbal"] || 0.00,
        openbal_ason: formValues["openbal_ason"] || formatDate(new Date()),
        is_default: defaultCheckState,
      };
    
    if ( state.action === "edit" || state.action === "view") {
      reqParam.id = formValues["id"];
      reqParam.is_active = checkState;
    } 

    
     try {
       var aIVal = localStorage.getItem("_aT");
       var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
 
       if ( actionVal === 'save-add') {
         setSubmitAddAction(true);
         submitActionRef.current = "save-add";
       }
       else {
         setSubmitAction(true);
         submitActionRef.current = "save";
       }
       var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
       //var res = await axios.post(client.domain + "/bms/addUpdateDCApi", reqDeliveryAddUpdate, { headers }, { withCredentials: false });
       console.log("Response from server : ", res.data.detail);
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server : ", res.data.detail.data);
         console.log("ACTION VALUE PASSED++++++++++++++++++++++++++-------------: ", actionVal);
         if ( actionVal === 'save-add' ) {
           setSubmitAddAction(false);
         }
         else {
           setSubmitAction(false);
         }
         setAlertTitle("Success");
         //setShowAlert(true);
         setSubmitAction(false);
         
         if(state.action === "add"){ 
           setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_BANKACC_SAVED"));
         } 
        else {
          setAlertBody(getCustomerMessages(LS_MSG_TAG,"MSG_BANKACC_UPDATED"));
         }
         //setShowAlert(true);
 
         if ( actionVal === 'save-add' ) {
          console.log("++++++++++++++++++++++++ SAVE AND PRINT ACTION BUTTON CICKED ...");
          //setShowAlert(true);
           //navigate(0);
           setShowAlert(true);
           //navigate(0);
         }
         else {
           setShowAlert(true);
         }  
       }
       else {
        setAlertTitle("FAILED!");
         setShowAlert(true);
         setSubmitAction(false);
         
         if(state.action === 'add'){    
           setAlertBody("Bank Account Save Failed!");
         }
         else{
           setAlertBody("Bank Account Update Failed!");
         }
        
         setShowAlert(true);
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       setAlertTitle("Warning");
       setAlertBody("Unable to process your request at this moment.Please try after sometime!");
       setShowAlert(true);
       setSubmitAction(false);
     }
  };
  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
     
    }
  };
  */
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  
  const callBankAccountDetById = async(bankIdPassed) => {

    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      //var apiToCall = "";

    var reqParams = {
      id: bankIdPassed
    };

    var res = await axios.post(client.domain + "/bms/getBankAccountDetails", reqParams, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      console.log("Response from server INSIDE ARRAY : ", res.data.detail.data[0]);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Success");
        //setShowDialog(true);
        setFormValues(res.data.detail.data[0]);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
      
  };

  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateBankAccount called ##########################");
      setToRender(true);
      setAccountTypeIdSelect(optaccountType[0]);
      if (state === null) {
        navigate("/listBankAccounts");
      } else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
         if (state.action === "add") {
          setIsReadOnly(true);
        }
        else if ( state.action === 'view' || state.action === 'edit') {
          // call api to get business info
          if ( state.passed_element.status === "Active") {
            setCheckState(true);
          }
          callBankAccountDetById(state.passed_element.id);
        }
        else {
          setFormValues(state.passed_element);
        }
         
         setToRender(true);
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      
      initialized.current = true;
    }
  }, []);

  /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aT");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/bms/addBankAccount";
      } else if (state.action === "view") {
        apiToCall = "/bms/updateBankAccount";
      } else {
        apiToCall = "/bms/addBankAccount";
      }

      var reqParam = {
        bid: currSelBusinessRef.current,
        name: formValues["name"],
        acc_number: formValues["acc_number"] || "",
        ifsc_code: formValues["ifsc_code"] || "",
        bank_name: formValues["bank_name"] || "",
        branch_name: formValues["branch_name"] || "",
        acc_holder_name: formValues["acc_holder_name"],
        upi_id: formValues["upi_id"] || "",
        openbal: formValues["openbal"] || 0.00,
        openbal_ason: formValues["openbal_ason"] || "",
        is_default: defaultCheckState,
      };

      if ( state.action === "edit" || state.action === "view") {
        reqParam.id = formValues["id"];
        reqParam.is_active = checkState;
      }

      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        if (state.action === "add"){
          setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_BANKACC_SAVED"));
          
        }
        else{
          setShowMsg(getCustomerMessages(LS_MSG_TAG,"MSG_BANKACC_UPDATED"))
        }
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  */
  const handlePostSaveOk = (e) => {
    console.log("user confirmed to customer added ...");
    setShowAlert(false);
    if( submitActionRef.current === 'save-add'){
      navigate(0);
      //navigate("/listCustomers");
      console.log("SaveOk action called for dialog confirm **************************");
    }
    else{
    console.log("Action called for dialog confirm **************************");
    navigate("/listBankAccounts");
    }
  };
  
  /*
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
     // callUpdateApi();
     
    }
  };
  */

  const handleAccountTypeSelect = async(e, data) => {
    console.log("handleAccountTypeSelect called ...", e);
    setAccountTypeIdSelect(e);
  };

  const handleDateChange = async (e) => {
    console.log("handleDateChange called ...", e);
    console.log("handleDateChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, openbal_ason: e.target.value });
  };

  const handleCheckBox = async (e) => {
    console.log("handle check called :", e);
    console.log("handle  CHECKED :", e.target.checked); 
    setCheckState(e.target.checked);
    if ( state.action === 'view' || state.action === 'edit'){
      setCheckState(e.target.checked);
    }
    setRefresh(true);
   
  };


  const handleDefaultCheckBox = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked); 
    setDefaultCheckState(e.target.checked);
    if ( state.action === 'view' || state.action === 'edit'){
      setDefaultCheckState(e.target.checked);
    }
    setRefresh(true);

  };



 
  console.log("formvalues FULL : ", formValues);

  const sendDataToParent  = (passedBackValue) => {
    console.log("++++++++++++++++++++ PASSED BACK VALUE : ", passedBackValue);
    if ( passedBackValue.hasOwnProperty("business_id") ) {
      currSelBusinessRef.current = passedBackValue["business_id"];
    }
    else if (passedBackValue.hasOwnProperty("branch_id")) {
      currBranchRef.current = passedBackValue["branch_id"];
    }
    else {
      // Future implementation
    }
    //setCurrSelBusinessId(passedBackValue);
    
  }
 

  return (
      <Container>
        <SidebarMain sendBacktoParent={sendDataToParent}/>
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}> 
             
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{menuSettings.permissions.find(element => (element.code === 'listBankAccounts' && element.access === true)) ? menuSettings.permissions.find(element => (element.code === 'listBankAccounts')).page_title_addupd : "Bank Account"}</span>
            {/*  
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
           
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
          */}
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}
                disabled={menuSettings.permissions.find(element => (element.code === 'listBankAccounts' && element.a_edit === true)) ? false : true}
                >
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
            

            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Account Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    //className="mobNum"
                    placeholder="Account Display Name"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Opening Balance</Form.Label>
                  <Form.Control
                    type="text"
                    id="openbal"
                    name="openbal"
                    placeholder="Opening Balance"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["openbal"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
              </Col> 
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">As of Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="openbal_ason"
                    name="openbal_ason"
                    placeholder="Date"
                    value={formValues.openbal_ason}
                    onChange={handleDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.openbal_ason}</p>
              </Col>
              </Row>

               <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="acc_number"
                    name="acc_number"
                    //className="mobNum"
                    placeholder="Account Number"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["acc_number"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.acc_number}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="ifsc_code"
                    name="ifsc_code"
                    //className="mobNum"
                    placeholder="IFSC Code"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["ifsc_code"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.ifsc_code}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Bank</Form.Label>
                  <Form.Control
                    type="text"
                    id="bank_name"
                    name="bank_name"
                    //className="mobNum"
                    placeholder="Bank Name"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["bank_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.bank_name}</p>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
            <Form.Group className="mb-3">
                  <Form.Label>Account Type</Form.Label>
                  <Select
                    inline
                    id="account_type"
                    name="account_type"
                    value={{
                      value: accountTypeIdSelect.value,
                      label: accountTypeIdSelect.label,
                    }}
                    options={optaccountType}
                    defaultValue={{
                      value: accountTypeIdSelect.value,
                      label: accountTypeIdSelect.label,
                    }}
                    onChange={handleAccountTypeSelect}
                    isDisabled={isReadOnly}
                  />
                  </Form.Group>
                  </Col>
                  */}
              
              </Row>
              <Row>
            

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Branch</Form.Label>
                  <Form.Control
                    type="text"
                    id="branch_name"
                    name="branch_name"
                    //className="mobNum"
                    placeholder="Branch"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["branch_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.branch_name}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Account Holder Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="acc_holder_name"
                    name="acc_holder_name"
                    //className="mobNum"
                    placeholder="Account Holder Name"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["acc_holder-name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.acc_holder_name}</p>
              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>UPI ID</Form.Label>
                  <Form.Control
                    type="text"
                    id="upi_id"
                    name="upi_id"
                    //className="mobNum"
                    placeholder="UPI ID"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                   // maxLength={10}
                    value={formValues["upi_id"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.upi_id}</p>
              </Col>
              </Row>
          
               {!isReadOnly && (
                <Row>        
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  {state.action !== "add" && 
                  <Form.Check
                            style={{paddingTop: "5px"}}
                            label="Active"
                            name="checkbox"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={checkState}
                            onChange={handleCheckBox}
                          ></Form.Check>
                   }      
                          &nbsp;&nbsp;
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    {state.action === "add" &&
                    <Button
                          className="btn btn-primary"
                          id='save-add'
                          name='save-add'
                          onClick={(e) => handleSubmit(e, 'save-add')}
                        >
                          
                          {submitAddAction &&
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          }
                        
                          Save & Add</Button>
                        }
                    &nbsp;&nbsp;
                    <Button
                      type="save"
                      id="save"
                      onClick={(e) => handleSubmit(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}>
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
          </Modal> 
          
          </div>
        </Form>
        
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={spinner_logo} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="danger" label="Spinning"   style={{ width: "70px", height: "70px", position: "fixed", top: "46%", left: "48%" }}>  
         
                <span className="visually-hidden">Loading...</span>
              </Spinner>
         </div>
         </div>
      }
      </Container>
  );
};

export default AddUpdateBankAccount;
